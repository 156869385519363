const INITIAL_STATE = {
  tests: [],
  error: null,
};

const getTests = (selectedIds, categories, check_status) => {
  return categories.map(category => {
    return {
      ...category,
      tests: category.tests.map(test_x => {
        const selected = selectedIds.filter(item_selected => {
          if (test_x.id === item_selected.id) {
            return item_selected;
          }
        });

        if (selected.length > 0) {
          return {
            ...test_x,
            ['modified_at']: check_status,
          };
        } else {
          return {
            ...test_x,
          };
        }
      }),
    };
  });
};

const prices = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_PRICES':
      return {
        ...state,
        tests: action.prices,
      };

    case 'ADD_PRICES_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'CHECK_TESTS':
      return {
        ...state,
        tests: getTests(action.tests, state.tests, true),
      };

    case 'CHECK_TEST':
      return {
        ...state,
        tests: getTests([{ id: action.id }], state.tests, true),
      };

    case 'UNCHECK_TEST':
      return {
        ...state,
        tests: getTests([{ id: action.id }], state.tests, false),
      };

    case 'CLEAR':
      return {
        ...state,
        tests: state.tests.map(category => {
          return {
            ...category,
            tests: category.tests.map(test_x => {
              return {
                ...test_x,
                modified_at: false,
              };
            }),
          };
        }),
      };
  }
  return state;
};

export default prices;
