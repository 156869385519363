import { connect } from 'react-redux';
import DoctorReport from '.';

const mapStateToProps = state => {
  return {
    lab: state.profile_lab.lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const DoctorReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoctorReport);

export default DoctorReportContainer;
