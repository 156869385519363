import { connect } from 'react-redux';
import AddDoctor from '../Components/AddDoctor';

import { GetLabInfo } from '../Actions/lab';
import { AddDoctorService } from '../Services/DoctorService';
import { AppToaster } from '../Components/Toaster';
import { Intent } from '@blueprintjs/core';

const mapStateToProps = state => {
  return {
    newDoctor: state.doctors.newDoctor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSave: doctor_x => {
      AddDoctorService(doctor_x)
        .then(doctor => {
          setTimeout(() => {
            dispatch(GetLabInfo());
          }, 500);
          AppToaster.show({
            message: 'Se agrego exitosamente',
            timeout: 3000,
            intent: Intent.SUCCESS,
          });
          // window.location.reload();
        })
        .catch(error => {
          AppToaster.show({
            message: 'Error al guardar',
            timeout: 3000,
            intent: Intent.DANGER,
          });
        });
    },
  };
};

const AddDoctorContainer = connect(mapStateToProps, mapDispatchToProps)(AddDoctor);

export default AddDoctorContainer;
