import React, { useState, useMemo, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const getPhysicians = physicians => {
  let options = physicians.map(doctor => {
    const { sex, full_name, last_names } = doctor.doctor.user;
    const { doctor_id } = doctor;
    const completeName = `${full_name} ${last_names}`;
    if (sex === 'male') {
      return {
        id: doctor_id,
        name: `Dr ${completeName}`,
      };
    } else {
      return {
        id: doctor_id,
        name: `Dra ${completeName}`,
      };
    }
  });

  return [{ id: null, name: 'A Quien Corresponda' }, ...options];
};

const SelectPhysician = ({ doctorID, doctors, updateDoctor }) => {
  const [selectedPhysician, setPhysician] = useState(null);

  const physicians = useMemo(() => getPhysicians(doctors), [doctors]);

  useEffect(() => {
    const doctor = physicians.find(option => option.id === doctorID);
    setPhysician(doctor);
  }, [doctorID]);

  return (
    <Autocomplete
      id="combo-box-doctor"
      options={physicians}
      value={doctorID ? selectedPhysician : null}
      getOptionLabel={option => option.name}
      onChange={(event, value) => {
        updateDoctor(value);
      }}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      fullWidth
      noOptionsText={'A Quien Corresponda'}
      renderInput={params => (
        <TextField {...params} label="Doctor" variant="outlined" />
      )}
    />
  );
};

export default SelectPhysician;
