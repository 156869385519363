import {
  GetReference,
  CreateSubtest,
  GetTestById,
  ServiceDeleteSubtest,
  SaveReference,
} from '../Services/ReferenceService';

import { AppToaster } from '../Components/Toaster';
import { Intent } from '@blueprintjs/core';

export const ADD_REFERENCE = 'ADD_REFERENCE';
export const ADD_REFERENCE_ERROR = 'ADD_REFERENCE_ERROR';
export const REFERENCE_LOADING = 'REFERENCE_LOADING';
export const CHANGE_RANGE_REFERENCE = 'CHANGE_RANGE_REFERENCE';
export const CHANGE_RANGES_REFERENCE = 'CHANGE_RANGES_REFERENCE';
export const CHANGE_RANGES_INSIDE_REFERENCE = 'CHANGE_RANGES_INSIDE_REFERENCE';
export const CHANGE_SUBTEST = 'CHANGE_SUBTEST';
export const ADD_REFERENCE_ITEM = 'ADD_REFERENCE_ITEM';
export const DELETE_REFERENCE_ITEM = 'DELETE_REFERENCE_ITEM';
export const DELETE_SUBTEST = 'DELETE_SUBTEST';
export const ADD_EMPTY_RANGE_ITEM = 'ADD_EMPTY_RANGE_ITEM';
export const ADD_REFERENCE_SUBTEST = 'ADD_REFERENCE_SUBTEST';
export const ADD_TEST = 'ADD_TEST';
export const CHANGE_TEST = 'CHANGE_TEST';

export const GetReferenceTest = test_id => {
  return dispatch => {
    dispatch(ReferenceLoading());

    GetReference(test_id)
      .then(res => {
        dispatch(AddReference(res.data[0]));
      })
      .catch(err => {
        dispatch(AddReferenceErr(err));
      });
  };
};

export const AddReference = reference => {
  return {
    type: ADD_REFERENCE,
    payload: { ...reference },
  };
};

export const AddReferenceErr = err => {
  return {
    type: ADD_REFERENCE_ERROR,
    payload: err,
  };
};

export const ReferenceLoading = () => {
  return {
    type: REFERENCE_LOADING,
  };
};

export const ChangeRangeReference = data => {
  return {
    type: CHANGE_RANGE_REFERENCE,
    payload: data,
  };
};

export const ChangeRangesReference = data => {
  return {
    type: CHANGE_RANGES_REFERENCE,
    payload: data,
  };
};

export const ChangeRangesInsideReference = data => {
  return {
    type: CHANGE_RANGES_INSIDE_REFERENCE,
    payload: data,
  };
};

export const ChangeSubtest = data => {
  return {
    type: CHANGE_SUBTEST,
    payload: data,
  };
};

export const AddReferenceItem = data => {
  return {
    type: ADD_REFERENCE_ITEM,
    payload: data,
  };
};

export const DeleteReferenceItem = data => {
  return {
    type: DELETE_REFERENCE_ITEM,
    payload: data,
  };
};

export const DeleteSubtestOne = index => {
  return {
    type: DELETE_SUBTEST,
    payload: { index },
  };
};

export const DeleteSubtest = (ref_id, subtest_id, index) => {
  return dispatch => {
    if (subtest_id) {
      ServiceDeleteSubtest(ref_id, subtest_id).then(res => {
        dispatch(DeleteSubtestOne(index));
      });
    } else {
      dispatch(DeleteSubtestOne(index));
    }
  };
};

export const AddEmptyRangeItem = data => {
  return {
    type: ADD_EMPTY_RANGE_ITEM,
    payload: data,
  };
};

export const AddReferenceSubtest = data => {
  return {
    type: ADD_REFERENCE_SUBTEST,
    payload: data,
  };
};

export const CreateReferenceSubtest = subtest => {
  return dispatch => {
    dispatch(AddReferenceSubtest(subtest));
  };
};

export const AddTest = data => {
  return {
    type: ADD_TEST,
    payload: data,
  };
};

export const GetTest = test_id => {
  return dispatch => {
    GetTestById(test_id)
      .then(res => {
        if (res.data && res.data[0]) {
          let data = {
            ...res.data[0],
            tests: res.data[0].tests.filter(t => t.id == test_id),
          };
          dispatch(AddTest(data));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const ChangeTest = data => {
  return {
    type: CHANGE_TEST,
    payload: data,
  };
};

export const SaveSubtest = reference_test => {
  return async dispatch => {
    let saved = false;
    try {
      let subtests = reference_test.subtests.map(subtest => {
        if (subtest.id) {
          SaveReference(reference_test.id, subtest.id, subtest);
        } else {
          CreateSubtest(subtest, reference_test.id);
        }
      });
      await Promise.all(subtests);
      saved = true;
    } catch (err) {
      saved = false;
      AppToaster.show({
        message: 'Error al guardar referencias',
        timeout: 3000,
        intent: Intent.DANGER,
      });
    }

    if (saved) {
      AppToaster.show({
        message: 'Se guardo exitosamente las referencias',
        timeout: 3000,
        intent: Intent.SUCCESS,
      });
    }
  };
};
