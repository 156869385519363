import { connect } from 'react-redux';
import { GetTestsResults, SearchPatient } from '../Actions/tests_results';
import ListPatientResultNew from '../Components/ListPatientResultNew';
import { ProcessingStatusRequestTest } from '../Services/RequestTestService';

const mapStateToProps = state => {
  return {
    tests_results: state.tests_results,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCompleteTests: () => {
      dispatch(GetTestsResults(1));
    },
    handleEditButton: request_id => {
      ProcessingStatusRequestTest(request_id).then(res => {
        dispatch(GetTestsResults());
        window.location.href = '/newProcessing/' + request_id;
      });
    },
    handleSeeResults: request_id => {
      window.location.href = '/newResults/' + request_id;
    },
    goNextPage: next_page => {
      dispatch(GetTestsResults(next_page));
    },
    searchPatient: search => {
      dispatch(SearchPatient(search));
    },
  };
};

const ListContainerNew = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListPatientResultNew);

export default ListContainerNew;
