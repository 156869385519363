import React, { useState } from 'react';
import {
  withStyles,
  Select as SelectUI,
  MenuItem as MenuItemUI,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
} from '@material-ui/core';

const ModalEditMethod = ({
  openModal,
  setOpenModal,
  method_payment,
  handleSave,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [method, setMethod] = useState(null);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleMethod = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setMethod(value);
  };

  const handleSaveButton = async () => {
    setDisabled(true);
    setOpenModal(false);
    await handleSave(method);
    setDisabled(false);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Editar Metodo De Pago</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormControl fullWidth variant="outlined">
            <InputLabel id="payment_method">Metodo de Pago</InputLabel>
            <SelectUI
              labelId="payment_method"
              id="payment-select"
              label="Payment Method"
              name="payment_method"
              onChange={handleMethod}
              defaultValue={method_payment}
            >
              <MenuItemUI key="cash" value="cash">
                Efectivo
              </MenuItemUI>
              <MenuItemUI key="qr" value="qr">
                QR
              </MenuItemUI>
              <MenuItemUI key="bank_transfer" value="bank_transfer">
                Transferencia Bancaria
              </MenuItemUI>
            </SelectUI>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={disabled}
          onClick={handleSaveButton}
          color="primary"
          autoFocus
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditMethod;
