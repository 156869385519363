import React, { useState } from "react";
import { Radio, RadioGroup } from "@blueprintjs/core";

const SexInput = ({ handleSexChange, sex }) => {
  const handleChange = (ev) => {
    handleSexChange(ev.target.value);
  };

  return (
    <RadioGroup
      inline={true}
      label="Sexo"
      onChange={handleChange}
      selectedValue={sex}
    >
      <Radio label="Mujer" value="female" />
      <Radio label="Varon" value="male" />
    </RadioGroup>
  );
};

export default SexInput;
