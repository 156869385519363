import React from 'react'

const Notification = ({lab}) => {
    if (lab && lab.id == "5beb1b02162e48000198604e") {
        return (
            <header className="notification_service_out">
                Estimado Cliente mediante este medio le informamos que los servicios de Sfenix seran interrumpidos desde este 19 Abril.
                Por favor regularise el pago de sus coutas mensuales para seguir usando el servicio.
            </header>
        )
    } else {
        return (null)
    }
}

export default Notification
