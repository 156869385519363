import React, { useEffect, useState, useContext } from 'react';
import { Intent } from '@blueprintjs/core';
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  CssBaseline,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, withRouter, useHistory } from 'react-router-dom';
import styles from './style';
import { AppToaster } from '../../Components/Toaster';
import SessionContext from '../../shared/contexts/session';
import { loginService } from '../../Services/UserService';
import { saveSessionStorage } from '../../shared/lib/auth';

const useStyles = makeStyles(styles);

const Login = ({ onLoginClick, user: UserResponse }) => {
  const classes = useStyles();
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const { setAuthenticated } = useContext(SessionContext);

  const handleUser = ev => {
    setUser(ev.target.value);
  };

  const handlePassword = ev => {
    setPassword(ev.target.value);
  };

  useEffect(() => {
    if (user.length > 0 && password.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [user, password]);

  const handleClick = async ev => {
    ev.preventDefault();
    setIsLoading(true);

    try {
      const res = await loginService(user, password);
      await saveSessionStorage(res.data.userId, res.data.id);
      setAuthenticated(true);
      history.push('/newHome');
    } catch (error) {
      AppToaster.show({
        message: 'Numero telefonico o Contraseña Invalida',
        timeout: 2500,
        intent: Intent.DANGER,
      });
      console.error(error);
    }

    setIsLoading(false);
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteWrapper} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  Do more with Laztin
                  {/* Haz mas con Sfenix */}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <Typography className={classes.title} variant="h2">
                    {/* Sign in */}
                    Login
                  </Typography>
                  {/* <Typography
              className={classes.subtitle}
              variant="body1">
              Iniciar Session con cuentas sociales
            </Typography> */}
                  <Typography className={classes.sugestion} variant="body1">
                    Inicia session con numero de telefono
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label="Numero de telefono"
                      name="phonenumber"
                      onChange={handleUser}
                      type="text"
                      value={user}
                      variant="outlined"
                    />
                    {/* {showEmailError && (
                <Typography
                  className={classes.fieldError}
                  variant="body2">
                  {errors.email[0]}
                </Typography>
              )} */}
                    <TextField
                      className={classes.textField}
                      label="Password"
                      name="password"
                      onChange={handlePassword}
                      type="password"
                      value={password}
                      variant="outlined"
                    />
                    {/* {showPasswordError && (
                <Typography
                  className={classes.fieldError}
                  variant="body2">
                  {errors.password[0]}
                </Typography>
              )} */}
                  </div>
                  {/* {submitError && (
              <Typography
                className={classes.submitError}
                variant="body2">
                {submitError}
              </Typography>
            )} */}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!isValid}
                      onClick={handleClick}
                      size="large"
                      variant="contained"
                    >
                      Iniciar Session
                    </Button>
                  )}
                  {/* <Typography
              className={classes.signUp}
              variant="body1">
              ¿ No tienes una cuenta ?{' '}
              <Link className={classes.signUpUrl} to="/sign-up">
                Crear cuenta
              </Link>
            </Typography> */}
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;
