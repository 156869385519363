import React from "react";

const Sex = ({ sex, years, subtest_id, refe_index, handleInputChange }) => {
  let sex_label = "";

  if (sex === "female") {
    sex_label = "Mujer";
  } else if (sex === "male") {
    sex_label = "Varon";
  } else if (sex === "unisex") {
    sex_label = "Varon y Mujer";
  }

  if (years) {
    sex_label += " (" + years[0] + "-" + years[1] + " años)";
  }

  return (
    <h6 className="cell medium-12 header_text boder_bottom">
      {sex_label} &nbsp;
      <a>
        &nbsp;
        <i
          data-type="delete_ref"
          data-subtest_id={subtest_id}
          data-refe_index={refe_index}
          onClick={handleInputChange}
          className="far fa-trash-alt"
        />
      </a>
    </h6>
  );
};

export default Sex;
