export default theme => ({
  patient_name: {
    fontSize: 17,
  },
  container: {
    padding: theme.spacing(3),
  },
  pendingList: {
    padding: theme.spacing(3),
  },
});
