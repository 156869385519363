import moment from 'moment';

const INITIAL_STATE = {
  request_test: { request_test: null, error: null },
  test_list: { tests_references: [] },
  tests_processing: { tests: [], error: null },
  categories: [],
  general_obs: '',
};

const request_tests = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_DOCTOR_REQUEST':
      return {
        ...state,
        request_test: {
          error: null,
          request_test: {
            ...state.request_test.request_test,
            doctor: action.doctor,
          },
        },
      };
    case 'UPDATE_DOCTOR_REQUEST':
      return {
        ...state,
        request_test: {
          error: null,
          request_test: {
            ...state.request_test.request_test,
            doctor: action.payload,
          },
        },
      };
    case 'ADD_CATEGORY':
      return {
        ...state,
        categories: action.categories,
      };
    case 'ADD_REQUEST_TEST':
      return {
        ...state,
        request_test: { request_test: action.request_test, error: null },
      };

    case 'ADD_REQUEST_TEST_ERROR':
      return {
        ...state,
        request_test: { request_test: null, error: action.error },
      };

    case 'ADD_TEST_REFERENCES':
      return {
        ...state,
        test_list: {
          tests_references: action.tests_references,
        },
      };

    case 'ADD_GENERAL_OBS':
      return {
        ...state,
        general_obs: action.general_obs,
      };

    case 'LOAD_TEST_REFERENCES':
      return {
        ...state,
        test_list: {
          tests_references: action.test_references,
        },
      };

    case 'ADD_TEST_REFERENCE':
      if (action.test_reference) {
        let tests_references = [...state.test_list.tests_references];
        //order test references by type of sample
        const type_samples = [
          'blood',
          'serum',
          'urine',
          'sediment',
          'cultivation',
          'other',
        ];

        tests_references = [...tests_references, action.test_reference];

        let results = [];

        for (let t of type_samples) {
          let tests_ids = state.categories
            .filter(cat => {
              if (t === cat.specimen_type) {
                return cat.tests;
              }
            })
            .map(cat => cat.tests);

          tests_ids = [].concat(...tests_ids);
          tests_ids = tests_ids.map(test => test.id);

          let res = tests_references.filter(test_ref => {
            if (test_ref) {
              if (tests_ids.includes(test_ref.test_id)) {
                return test_ref;
              }
            }
          });

          if (res.length > 0) {
            results.push(res);
          }
        }

        let empties = tests_references.filter(test_ref => {
          if (!test_ref) {
            return test_ref;
          }
        });

        results = [...results, ...empties];

        let res_test_refs = [].concat(...results);
        return {
          ...state,
          test_list: { tests_references: [...res_test_refs] },
        };
      } else {
        let tests_references = state.test_list.tests_references;
        return {
          ...state,
          test_list: {
            tests_references: [...tests_references, action.test_reference],
          },
        };
      }

    case 'CLEAN_TEST_REFERENCE':
      return {
        ...state,
        general_obs: '',
        test_list: { tests_references: [] },
      };

    case 'ADD_TEST_VALUE':
      let tests_list = state.test_list;
      let references = tests_list.tests_references;
      let res = references.map(test => {
        if (typeof test === 'undefined') {
          return test;
        } else {
          if (test.test_id !== action.test_id) {
            return test;
          }
          return {
            ...test,
            subtests: test.subtests.map(sub_test => {
              if (sub_test.id !== action.subtest_id) {
                return sub_test;
              }
              return {
                ...sub_test,
                value: action.value,
              };
            }),
          };
        }
      });

      return {
        ...state,
        test_list: { tests_references: res },
      };
    case 'DELETE_TEST_VALUE':
      let tests_list_t = state.test_list;
      let references_t = tests_list_t.tests_references;
      let res_t = references_t.map(test => {
        if (typeof test === 'undefined') {
          return test;
        } else {
          if (test.test_id !== action.test_id) {
            return test;
          }
          return {
            ...test,
            subtests: test.subtests.map(sub_test => {
              if (sub_test.id !== action.subtest_id) {
                return sub_test;
              }

              if (sub_test.values) {
                return {
                  ...sub_test,
                  values: sub_test.values.filter(value_x => value_x != action.value),
                };
              } else {
                return {
                  ...sub_test,
                  values: [],
                };
              }
            }),
          };
        }
      });

      return {
        ...state,
        test_list: { tests_references: res_t },
      };

    case 'ADD_TEST_VALUES':
      let tests_listx = state.test_list;
      let referencesx = tests_listx.tests_references;
      let resx = referencesx.map(test => {
        if (typeof test === 'undefined') {
          return test;
        } else {
          if (test.test_id !== action.test_id) {
            return test;
          }
          return {
            ...test,
            subtests: test.subtests.map(sub_test => {
              if (sub_test.id !== action.subtest_id) {
                return sub_test;
              }

              if (sub_test.values) {
                return {
                  ...sub_test,
                  values: [...sub_test.values, action.value],
                };
              } else {
                return {
                  ...sub_test,
                  values: [action.value],
                };
              }
            }),
          };
        }
      });

      return {
        ...state,
        test_list: { tests_references: resx },
      };
    case 'CLEAN_PROCESSING_TESTS':
      return {
        ...state,
        tests_processing: { tests: [], error: null },
      };

    case 'ADD_PROCESSING_TESTS':
      return {
        ...state,
        tests_processing: { tests: action.tests_processing, error: null },
      };

    case 'ADD_PROCESSING_TESTS_ERROR':
      return {
        ...state,
        tests_processing: { tests: [], error: action.error },
      };

    case 'ADD_PATIENT_PROCESSING':
      return {
        ...state,
        tests_processing: {
          error: null,
          tests: state.tests_processing.tests.map(test => {
            if (test.patient_id === action.patient.id) {
              return {
                ...test,
                patient: action.patient,
              };
            } else {
              return test;
            }
          }),
        },
      };

    case 'ADD_PATIENT_REQUEST':
      let age;

      if (action.patient.birthdate) {
        const birthdatex = moment(action.patient.birthdate);
        const now = moment();

        const diffYears = now.diff(birthdatex, 'years');
        const diffMonths = now.diff(birthdatex, 'months');
        const diffDays = now.diff(birthdatex, 'days');

        if (diffYears > 0) {
          age = diffYears;
        } else if (diffMonths > 0) {
          age = `month_${diffMonths}`;
        } else {
          age = `day_${diffDays}`;
        }
      }

      return {
        ...state,
        request_test: {
          error: null,
          request_test: {
            ...state.request_test.request_test,
            patient: {
              ...action.patient,
              years_old: action.patient.birthdate ? age : action.patient.years_old,
            },
          },
        },
      };
  }

  return state;
};

export default request_tests;
