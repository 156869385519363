import axios from 'axios';
import config from '../config.json';

const url = config.user_service;
const lab_id = localStorage.getItem('enterprise_token');

export const AddPatientService = patient => {
  axios.defaults.headers.common['X-Access-Token'] = localStorage.getItem('token');

  return axios.post(url + '/users', patient).then(res => {
    let patient_lab = {
      user_id: res.data.id,
      laboratory_id: lab_id,
    };

    axios
      .post(url + '/patient_laboratories', patient_lab)
      .then(res => console.log(res.data));

    return res.data;
  });
};

export const GetPatient = patient_id => {
  axios.defaults.headers.common['X-Access-Token'] = localStorage.getItem('token');

  return axios.get(url + '/users/' + patient_id);
};

export const GetDoctor = doctor_id => {
  let params = {
    params: {
      filter: {
        include: ['user'],
      },
    },
  };

  return axios.get(url + '/doctors/' + doctor_id, params);
};

export const UpdatePatient = (id_patient, patient) => {
  return axios.put(url + '/users/' + id_patient, patient);
};

export const updatePerson = (id_person, person) => {
  return axios.patch(url + '/people/' + id_person, person);
};

export const updatePhonePerson = (id_person, foreignKey, phone) => {
  return axios.put(url + '/people/' + id_person + '/phones/' + foreignKey, {
    number: phone,
  });
};

export const getPerson = person_id => {
  return axios.get(url + '/people/' + person_id);
};
