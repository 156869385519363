import axios from 'axios'
import config from '../config.json'

const url = config.user_service

export const AddDoctorService = (doctor) => {
    const lab_id = localStorage.getItem('enterprise_token')

    return axios.post(url + '/users', doctor).then(res =>  {

        let data_doc = {
            user_id: res.data.id,
            specialty: ['medico general']
        }

        axios.post(url + '/doctors', data_doc).then(res_x => {
            let doctor_lab = {
              doctor_id: res_x.data.id,
              laboratory_id: lab_id
            }

            axios.post(url + '/doctor_laboratories', doctor_lab).then(res_y => console.log(res_y.data))
        })

        return res.data
    })
}

export const getDoctors = () => {
    const lab_id = localStorage.getItem('enterprise_token')

    const params = {
        params:{
            filter: {
                include: {
                    doctor:["user"]
                }
            }
        }
    }

    return axios.get(url + '/laboratories/' + lab_id + '/doctors', params)
}

export const getDoctorById = (id) => {
    const params = {
        params:{
            filter: {
                include: 'user'
            }
        }
    }

    return axios.get(url+ '/doctors/'+id, params)
}
