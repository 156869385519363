import React, { useEffect } from 'react';
import { Grid, Typography, Paper, Divider } from '@material-ui/core';
import { Link, Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import FillRequest from '../fillRequest';
import styles from './style';

const useStyles = makeStyles(styles);
const Processing = React.memo(
  ({ loadProcessingTests, tests_processing, deleteRequest }) => {
    useEffect(() => {
      loadProcessingTests();
    }, []);

    const { path } = useRouteMatch();
    const classes = useStyles();

    let links = null;
    if (tests_processing.tests.length > 0) {
      links = tests_processing.tests.map(test => (
        <>
          <Grid
            item
            xs={12}
            key={'p' + test.id}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid
              item
              xs={10}
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Link className={classes.patient_name} to={`${path}/${test.id}`}>
                  {typeof test.patient !== 'undefined'
                    ? test.patient.full_name + ' ' + test.patient.last_names
                    : ' '}
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs>
                  {test.tests.length} {test.tests.length == 1 ? 'Prueba' : 'Pruebas'}
                </Grid>
                <Grid item xs>
                  {new Date(test.created_at).toLocaleDateString()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <a
                className="fas fa-times"
                data-request_id={test.id}
                onClick={deleteRequest}
              ></a>
            </Grid>
          </Grid>
          <Divider />
        </>
      ));
    }

    return (
      <Grid
        item
        xs={12}
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid
          item
          md={3}
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          className={classes.pendingList}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Pruebas Pendientes</Typography>
            </Grid>
            {links}
          </Grid>
        </Grid>
        <Grid
          item
          md={9}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Paper variant="outlined">
            <Switch>
              <Route path={`${path}/:request_test_id`}>
                <FillRequest />
              </Route>
              <Route exact path={path}>
                <h3>Por favor selecciona un paciente.</h3>
              </Route>
            </Switch>
          </Paper>
        </Grid>
      </Grid>
    );
  },
);

export default Processing;
