import React from 'react';
import {
  TextField,
  FormControl,
  Select as SelectUI,
  MenuItem as MenuItemUI,
  InputLabel,
  Grid,
} from '@material-ui/core';

import SexInput from '../sexInput';

const years_x = [...Array(119).keys()];
const months = [
  'month_1',
  'month_2',
  'month_3',
  'month_4',
  'month_5',
  'month_6',
  'month_7',
  'month_8',
  'month_9',
  'month_10',
  'month_11',
  'month_12',
];
const days = [
  'day_1',
  'day_2',
  'day_3',
  'day_4',
  'day_5',
  'day_6',
  'day_7',
  'day_8',
  'day_9',
  'day_10',
  'day_11',
  'day_12',
  'day_13',
  'day_14',
  'day_15',
  'day_16',
  'day_17',
  'day_18',
  'day_19',
  'day_20',
  'day_21',
  'day_22',
  'day_23',
  'day_24',
  'day_25',
  'day_26',
  'day_27',
  'day_28',
  'day_29',
  'day_30',
  'day_31',
];

const Patient = ({
  name,
  last_name,
  years_old,
  phone_number,
  sex,
  updateForm,
  handleSexChange,
  show_select,
  branch,
  handleBranch,
  lab,
}) => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={12} md={3}>
        <TextField
          id="outlined-names"
          label="Nombres"
          variant="outlined"
          type="text"
          name="name"
          value={name}
          onChange={updateForm}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          id="outlined-lastnames"
          label="Apellidos"
          variant="outlined"
          type="text"
          name="last_name"
          value={last_name}
          onChange={updateForm}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="years_old-outlined-label">Edad</InputLabel>
          <SelectUI
            labelId="years_old-outlined-label"
            id="years_old-select-outlined"
            name="years_old"
            value={years_old}
            onChange={updateForm}
            label="Edad"
            fullWidth
          >
            {/* <MenuItemUI key="years_old" value="">
              Elige una edad
            </MenuItemUI> */}
            {days.map(day => {
              let label_day = 'Dias';
              if (day === 'day_1') {
                label_day = 'Dia';
              }
              let day_show = day.split('_');
              return (
                <MenuItemUI key={day} value={day}>
                  {day_show[1]} {label_day}
                </MenuItemUI>
              );
            })}
            {months.map(month => {
              let label_month = 'Meses';
              if (month === 'month_1') {
                label_month = 'Mes';
              }

              let month_show = month.split('_');
              return (
                <MenuItemUI key={month} value={month}>
                  {month_show[1]} {label_month}
                </MenuItemUI>
              );
            })}
            <MenuItemUI value="1">1 Año</MenuItemUI>
            {years_x.map(index => {
              return (
                <MenuItemUI key={index} value={index + 2}>
                  {index + 2} Años
                </MenuItemUI>
              );
            })}
          </SelectUI>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <SexInput handleSexChange={handleSexChange} sex={sex} />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          id="outlined-phonenumber"
          label=" Numero de Celular"
          variant="outlined"
          type="text"
          name="phone_number"
          value={phone_number}
          onChange={updateForm}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        {show_select}
      </Grid>
      <Grid item xs={12} md={3}>
        {lab && lab.branchs && (
          <FormControl fullWidth variant="outlined">
            <InputLabel id="branch">Sucursal</InputLabel>
            <SelectUI
              labelId="branch"
              id="branch-select"
              value={branch}
              label="Sucursal"
              name="branch"
              onChange={handleBranch}
              defaultValue={branch}
            >
              {lab.branchs.map(branch => {
                return (
                  <MenuItemUI key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItemUI>
                );
              })}
            </SelectUI>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

export default Patient;
