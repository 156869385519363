import { combineReducers } from 'redux'
import patients from './patients'
import doctors from './doctors'
import request_tests from './request_test'
import prices from './prices'
import user from './user'
import reference from './reference'
import profile_lab from './lab'
import tests_results from './tests_results'

const Lab = combineReducers({
  patients,
  doctors,
  request_tests,
  prices,
  user,
  reference,
  profile_lab,
  tests_results
});

export default Lab
