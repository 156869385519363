import { connect } from 'react-redux';
import { AddPatientAction, AddPatientErrorAction } from '../Actions';
import AddPatient from './AddPatient';
import { AddPatientService } from '../Services/PatientService';

const mapStateToProps = (state) => {
  return {
    newPatient: state.patients.newPatient
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (patient_x) => {
      AddPatientService(patient_x).then( patient => {
          dispatch(AddPatientAction(patient));
      }).catch(error => {
          dispatch(AddPatientErrorAction(error))
      })
    }
  }
}

const AddPatientContainer = connect(
  null,
  mapDispatchToProps
)(AddPatient);

export default AddPatientContainer;
