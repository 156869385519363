import { connect } from 'react-redux';
import ReportCash from '.';

const mapStateToProps = state => {
  return {
    lab: state.profile_lab.lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const ReportCashContainer = connect(mapStateToProps, mapDispatchToProps)(ReportCash);

export default ReportCashContainer;
