import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomSnackBar } from '../../Components';

const useStyles = makeStyles(theme => ({
  snackbar: {
    marginTop: theme.spacing(2),
  },
}));

const Toast = ({ message, statusOpen, setOpen, type }) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={statusOpen}
      autoHideDuration={2500}
      onClose={handleClose}
    >
      <CustomSnackBar
        className={classes.snackbar}
        variant={type}
        message={message}
      />
    </Snackbar>
  );
};

export default Toast;
