import React from 'react';
import { Grid } from '@material-ui/core';
import RangeEdit from './RangeEdit';
import RangesEdit from './RangesEdit';
import LimitEdit from './LimitEdit';
import Generic from './Generic';
import Sex from './Sex';

const ReferenceEdit = ({ type, ...data }) => {
  let reference_edit = null;
  switch (type) {
    case 'generic':
      reference_edit = <Generic {...data} />;
      break;
    case 'limit':
      reference_edit = <LimitEdit {...data} />;
      break;
    case 'ranges_text':
    case 'ranges':
      reference_edit = <RangesEdit {...data} />;
      break;
    case 'range':
      reference_edit = <RangeEdit {...data} />;
      break;
    case 'text_pos_neg':
      reference_edit = <div></div>;
      break;
    case 'text':
      reference_edit = <div></div>;
      break;
    case 'text_line':
      reference_edit = <div></div>;
      break;
    case 'text_add':
      reference_edit = <div></div>;
      break;
    default:
      reference_edit = <div></div>;
  }

  return (
    <Grid item xs={12}>
      <Sex
        sex={data.sex}
        years={data.years}
        subtest_id={data.subtest_id}
        refe_index={data.refe_index}
        handleInputChange={data.handleInputChange}
      />
      {reference_edit}
    </Grid>
  );
};

export default ReferenceEdit;
