import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import config from '../../config.json';
import axios from 'axios';
import {
  MenuItem as MenuItemUI,
  withStyles,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { Portlet, PortletContent, CustomSnackBar } from '../../Components';
import Summary from './summary';
import './AddRequestTest.css';
import { SaveTest } from '../../Services/RequestTestService';

import Layout from '../../layout';
import Category from './category';
import Patient from './patient';

import styles from './styles';

const AddRequestTestLegacy = ({
  prices,
  total,
  tests_selected,
  onTestClick,
  test_list,
  doctors,
  lab,
  loadPrices,
  onClearClick,
  classes,
}) => {
  const [doctor, setDoctor] = useState(null);
  const [phone_number, setPhone_number] = useState('');
  const [name, setName] = useState('');
  const [last_name, setLast_name] = useState('');
  const [years_old, setYears_old] = useState(null);
  const [sex, setSex] = useState(null);
  const [branch, setBranch] = useState(1);

  const [statusSuccessMessage, setStatusSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [statusErrorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    loadPrices();
  }, []);

  const sendReport = async () => {
    const tests = test_list.map(test => {
      return { name: test.name, precio: test.price };
    });

    if (tests.length > 0) {
      try {
        const data = {
          name: name,
          last_name: last_name,
          phone_number: phone_number,
          years_old: years_old,
          sex: sex,
          doctor: doctor,
          test_list: test_list,
          total: total,
          branch_id: branch,
          navigator: navigator.userAgent,
          enterprise: lab.lab.name,
          enterprise_id: lab.lab.id,
        };

        await axios.post(config.lab_service + '/request_tests/logger', {
          ...data,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const cleanInputs = flag => {
    if (flag && lab.lab.id === '5abe499eda771218814def3c') {
      sendReport();
    }

    setPhone_number('');
    setName('');
    setLast_name('');
    setYears_old(null);
    setSex('');

    setDoctor(null);
    onClearClick();
  };

  const showSuccess = message => {
    setStatusSuccessMessage(true);
    setMessage(message);
  };

  const showError = message => {
    setErrorMessage(true);
    setMessage(message);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatusSuccessMessage(false);
    setErrorMessage(false);
  };

  const saveRequestTest = async (
    tests_selected,
    on_account,
    discount,
    payment_method,
  ) => {
    let go_next = true;

    if (sex === '') {
      go_next = false;

      showError('Por Favor selecciona el sexo');
    }

    if (tests_selected.length === 0) {
      go_next = false;
      showError('Por Favor selecciona al menos una prueba');
    }

    if (name.length <= 2) {
      go_next = false;
      showError('Por Favor escribe el nombre completo');
    }

    if (last_name.length <= 2) {
      go_next = false;
      showError('Por Favor escribe los apellidos');
    }

    if (go_next) {
      let doctor_id = '';

      if (doctor) {
        doctor_id = doctor.id;
      }

      try {
        const res = await SaveTest(
          phone_number,
          name,
          last_name,
          doctor_id,
          sex,
          years_old,
          discount,
          on_account,
          tests_selected,
          branch,
          payment_method,
        );
        showSuccess('Se creado exitosamente la solicitud');
      } catch (error) {
        showError('Error al crear solicitud de prueba');
      }

      // dispatch(ClearTest());

      cleanInputs(false);
    }
  };

  const filterItem = (query, item) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const onSelected = (item, ev) => {
    setDoctor(item);
  };

  const renderItem = (item, { handleClick, modifiers }) => {
    return (
      <MenuItemUI active={modifiers.active} key={item.id} onClick={handleClick}>
        {item.name}
      </MenuItemUI>
    );
  };

  const updateForm = ev => {
    ev.preventDefault();
    const { name, value } = ev.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'last_name':
        setLast_name(value);
        break;
      case 'years_old':
        setYears_old(value);
        break;
      case 'phone_number':
        setPhone_number(value);
        break;
      case 'branch':
        setBranch(value);
        break;
      default:
        break;
    }
  };

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setBranch(value);
  };

  const handleSexChange = value => {
    setSex(value);
  };

  let options = [];
  let show_select = null;

  let button_selected = null;
  if (doctor) {
    button_selected = (
      <Button text={doctor.name} rightIcon="double-caret-vertical" />
    );
  } else {
    button_selected = (
      <Button text="Selecciona un Medico" rightIcon="double-caret-vertical" />
    );
  }

  if (typeof doctors !== 'undefined') {
    options = doctors.map(doctor => {
      if (doctor.doctor.user.sex === 'male') {
        return {
          id: doctor.doctor_id,
          name:
            'Dr ' +
            doctor.doctor.user.full_name +
            ' ' +
            doctor.doctor.user.last_names,
        };
      } else {
        return {
          id: doctor.doctor_id,
          name:
            'Dra ' +
            doctor.doctor.user.full_name +
            ' ' +
            doctor.doctor.user.last_names,
        };
      }
    });

    show_select = (
      <Select
        className="button_height"
        items={options}
        itemPredicate={filterItem}
        itemRenderer={renderItem}
        onItemSelect={onSelected}
        noResults={'No results.'}
      >
        {button_selected}
      </Select>
    );
  }

  return (
    <Layout>
      <Portlet>
        <PortletContent>
          <div className="grid-x">
            <div className="medium-12">
              <div className="grid-x">
                <Patient
                  name={name}
                  last_name={last_name}
                  phone_number={phone_number}
                  sex={sex}
                  updateForm={updateForm}
                  years_old={years_old}
                  handleSexChange={handleSexChange}
                  show_select={show_select}
                  branch={branch}
                  handleBranch={handleBranch}
                  lab={lab.lab}
                />
              </div>

              <div className="medium-12 cell">
                <div className="columns_x">
                  {prices ? (
                    <Category prices={prices} onTestClick={onTestClick} />
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
              <Summary
                test_list={test_list}
                total={total}
                cleanInputs={cleanInputs}
                saveRequestTest={saveRequestTest}
                tests_selected={tests_selected}
              />
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={statusSuccessMessage}
            autoHideDuration={2500}
            onClose={handleClose}
          >
            <CustomSnackBar
              variant="success"
              className={classes.margin}
              message={message}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={statusErrorMessage}
            autoHideDuration={2500}
            onClose={handleClose}
          >
            <CustomSnackBar
              variant="error"
              className={classes.margin}
              message={message}
            />
          </Snackbar>
        </PortletContent>
      </Portlet>
    </Layout>
  );
};
export default withStyles(styles)(AddRequestTestLegacy);
