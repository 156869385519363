import React, { useState, useMemo, useEffect } from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';

const getPhysicians = physicians => {
  let options = physicians.map(doctor => {
    const { sex, full_name, last_names } = doctor.doctor.user;
    const { doctor_id } = doctor;
    const completeName = `${full_name} ${last_names}`;
    if (sex === 'male') {
      return {
        id: doctor_id,
        name: `Dr ${completeName}`,
      };
    } else {
      return {
        id: doctor_id,
        name: `Dra ${completeName}`,
      };
    }
  });

  return [{ id: null, name: 'A Quien Corresponda' }, ...options];
};

const getPhysician = physician => {
  let res = 'A Quien Corresponda';
  if (physician) {
    const { sex, full_name, last_names, id } = physician;
    const completeName = `${full_name} ${last_names}`;
    if (sex === 'male') {
      return `Dr. ${completeName}`;
    } else {
      return `Dra. ${completeName}`;
    }
  }
  return res;
};

const Physician = ({ doctor, doctors, request_id, handleUpdateDoctor }) => {
  const [selectedPhysician, setPhysician] = useState(null);
  const filterItem = (query, item) => {
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  const onSelected = (item, ev) => {
    handleUpdateDoctor(request_id, item.id);
    setPhysician(item.name);
  };

  const renderItem = (item, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.name}
      />
    );
  };

  const physicians = useMemo(() => getPhysicians(doctors), [doctors]);
  const physician = useMemo(() => getPhysician(doctor), [doctor]);

  return (
    <div className="cell medium-12 grid-x">
      <div className="cell medium-4">
        <strong>Doctor:</strong>

        <Select
          className="button_height"
          items={physicians}
          itemPredicate={filterItem}
          itemRenderer={renderItem}
          onItemSelect={onSelected}
          noResults={'A Quien Corresponda'}
        >
          <Button
            text={selectedPhysician ? selectedPhysician : physician}
            rightIcon="double-caret-vertical"
          />
        </Select>
      </div>
    </div>
  );
};

export default Physician;
