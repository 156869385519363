import React, { PureComponent } from 'react';
import TestType from './TestType';
import './Test.css';
import { Grid } from '@material-ui/core';

class Test extends PureComponent {
  render() {
    const {
      sex,
      years_old,
      subtests,
      test_id,
      id,
      onInputChange,
      handleInputSave,
    } = this.props;
    return (
      <Grid item xs={12} container>
        {subtests.map(test_type => (
          <TestType
            key={test_type.id}
            years_old={years_old}
            sex={sex}
            {...test_type}
            test_id={test_id}
            onInputChange={onInputChange}
            handleInputSave={handleInputSave}
          />
        ))}
      </Grid>
    );
  }
}

export default Test;
