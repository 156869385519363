import React from 'react'

const AddPatient = (props) => {

    let full_name, last_name, sex, years_old, phone_number, email, form
    const {onSave, newPatient} = props

    return (
            <form className="grid-x grid-margin-x" ref={node => {form = node}} >
                <div className="cell large-4">
                    <label> Nombres
                        <input type="text" ref={node => { full_name = node }} placeholder="Nombres" required />
                    </label>
                </div>

                <div className="cell large-4">
                    <label >Apellidos
                        <input type="text" ref={node => { last_name = node }} placeholder="Apellidos"  required/>
                    </label>
                </div>

                <fieldset className="cell large-4">
                    <legend>Sexo</legend>
                    <input type="radio" value="female" name="gender" required onChange={(ev) => {
                        sex = ev.target.value
                    }} /><label>Mujer</label>
                    <input type="radio" value="male" name="gender" onChange={(ev) => {
                        sex = ev.target.value
                    }} /><label>Varon</label>
                </fieldset>

                <div className="cell large-4">
                    <label>Edad
                      <select onChange={(ev) =>{
                          years_old = ev.target.value
                      }}>
                        {
                            Array(120).fill().map((_, i) => { let y = i +1
                                return <option key={y} value={y}>{ y + ' años'}</option>
                            })
                        }

                      </select>
                    </label>
                </div>

                <div className="cell large-4">
                    <label>Phone Number
                        <input type="tel" ref={node => { phone_number = node }} placeholder="Phone Number" required />
                    </label>
                </div>

                <div className="cell large-4">
                    <label>Email
                    <input type="email" ref={node => { email = node }} placeholder="ejemplo@gmail.com" />
                    </label>
                </div>

                <div className="cell large-4">
                    <button className="button primary expanded" onClick={(e) => {
                        e.preventDefault()

                        let patient = {
                            full_name: full_name.value,
                            last_name: last_name.value,
                            sex: sex,
                            years_old: years_old,
                            phonenumber: phone_number.value,
                            email: email.value
                        }

                        onSave(patient)

                        form.reset()
                    }}>
                      Agregar Paciente
                    </button>
                </div>
            </form>)
}

export default AddPatient
