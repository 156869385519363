import React, { PropTypes, PureComponent } from 'react';
import './CreateSubtest.css';
import { Grid, Button } from '@material-ui/core';
class CreateSubtest extends PureComponent {
  constructor() {
    super();
    this.history_x = null;
    this.state = {
      name: '',
      group: '',
    };
  }

  hangleInputs = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = ev => {
    ev.preventDefault();
    const { handleAddSubtest } = this.props;
    this.setState({
      name: '',
      group: '',
    });
    handleAddSubtest(ev);
  };

  render() {
    let { reference_id } = this.props;

    return (
      <Grid item container xs={12} className="bg_group" spacing={3}>
        <Grid item xs={12}>
          <h4>Crear Nueva Sub-Prueba</h4>
        </Grid>

        <Grid item container xs={4}>
          <Grid item xs={12}>
            Nombre de la Prueba
          </Grid>
          <Grid item xs={12}>
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              value={this.state.name}
              onChange={this.hangleInputs}
            />
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={12}>
            Grupo para Agrupar
          </Grid>
          <Grid item xs={12}>
            <input
              type="text"
              name="group"
              placeholder="Grupo"
              value={this.state.group}
              onChange={this.hangleInputs}
            />
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              data-name={this.state.name}
              data-group={this.state.group}
              data-reference_id={reference_id}
              onClick={this.handleClick}
              className="button_create_subtes"
            >
              Crear Nueva Sub-Prueba
            </Button>
          </Grid>
        </Grid>
        <br />
      </Grid>
    );
  }
}
export default CreateSubtest;
