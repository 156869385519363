import React, { useState, useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
  withStyles,
  CircularProgress,
  Typography,
  Snackbar,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';

// Shared layouts
import Layout from '../../layout';

import { CreateExpense, ListCashFlow, Branch, ModalEditMethod } from './Components';
import { Portlet, PortletContent, CustomSnackBar } from '../../Components';

// Shared services
import {
  getCashFlow,
  createExpense,
  closeCashRegister,
  openCashRegister,
  getOpenCashier,
  deleteCashFlow,
  updateMethodCashFlow,
} from '../../Services/FinanceService';

// Component styles
import styles from './styles';

const CashFlow = ({ classes, lab }) => {
  const [isLoading, setLoading] = useState(false);
  const [totalExpected, setTotalExpected] = useState(0);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [openAmount, setOpenAmount] = useState(null);
  const [registerOpenAmount, setRegisterOpenAmount] = useState(null);
  const [disabledOpen, setDisabledOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [dataMethod, setdataMethod] = useState({});

  const [statusSuccessMessage, setStatusSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [statusErrorMessage, setErrorMessage] = useState(false);
  const branch_stored = localStorage.getItem('branch');
  const [branch_id, setBranchId] = useState(Number(branch_stored) || 1);

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setRegisterOpenAmount(null);
    setBranchId(value);
    localStorage.setItem('branch', value);
  };

  const getTotal = cashflows => {
    const aux = cashflows.filter(item => item.deleted === false);
    let total = 0;
    aux.map(cashFlow => {
      if (cashFlow.type === 'in' && cashFlow.payment_method === 'cash') {
        total += cashFlow.total_amount;
      } else if (cashFlow.type === 'out' && cashFlow.payment_method === 'cash') {
        total -= cashFlow.total_amount;
      }

      return null;
    });

    return total;
  };

  const getTotalBankTransfer = cashflows => {
    const aux = cashflows.filter(item => item.deleted === false);

    let total = 0;
    aux.map(cashFlow => {
      if (
        cashFlow.type === 'in' &&
        (cashFlow.payment_method === 'qr' ||
          cashFlow.payment_method === 'bank_transfer')
      ) {
        total += cashFlow.total_amount;
      }

      return null;
    });

    return total;
  };

  const handleCloseCashier = async (totalAmount, notes) => {
    try {
      await closeCashRegister(totalAmount, branch_id, notes);
      setData([]);
      setOpenAmount(null);
      setRegisterOpenAmount(null);
      await fetchData(branch_id);
      showSuccess('Se cerro caja exitosamente');
    } catch (error) {
      showError('Se produjo un error al cerrar caja');
    }
  };

  const handleChange = event => {
    setOpenAmount(Number.parseFloat(event.target.value));
  };

  const handleOpen = async () => {
    setDisabledOpen(true);
    try {
      await openCashRegister(openAmount, branch_id);
      setDisabledOpen(false);
      showSuccess('Se aperturo la caja exitosamente');
      await fetchData(branch_id);
    } catch (error) {
      showError('Error al crear caja');
    }
  };

  const showSuccess = message => {
    setStatusSuccessMessage(true);
    setMessage(message);
  };

  const showError = message => {
    setErrorMessage(true);
    setMessage(message);
  };

  const handleSave = async (
    type,
    category,
    description,
    totalExpense,
    payment_method,
  ) => {
    try {
      await createExpense(
        type,
        category,
        description,
        totalExpense,
        branch_id,
        payment_method,
      );
      showSuccess('Se agrego exitosamente');
      fetchData(branch_id);
    } catch (err) {
      showError('No se pudo registrar');
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatusSuccessMessage(false);
    setErrorMessage(false);
  };

  const showTotals = (data, openAmount) => {
    let total = 0;
    total = getTotal(data) + openAmount;
    setTotalExpected(total);
  };

  const fetchData = async branch_id => {
    try {
      setLoading(true);
      const cashRegister = await getOpenCashier(branch_id);

      const res = await getCashFlow(branch_id);
      setLoading(false);
      setData(res.data);

      if (cashRegister.data && cashRegister.data[0]) {
        const item = cashRegister.data[0];
        setRegisterOpenAmount(item.open_amount);
        showTotals(res.data, item.open_amount);
      } else {
        showTotals(res.data, 0);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData(branch_id);
  }, []);

  useEffect(() => {
    fetchData(branch_id);
  }, [branch_id]);

  const handleDeleteCashflow = id => async () => {
    await deleteCashFlow(id);
    fetchData(branch_id);
  };

  const openModalEditMethod = (id, method) => {
    setdataMethod({ id, method });
  };

  useEffect(() => {
    if (dataMethod.method) {
      setOpenModal(true);
    }
  }, [dataMethod]);

  const renderCashFlow = () => {
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (registerOpenAmount === null) {
      return (
        <Portlet>
          <PortletContent>
            <Grid container spacing={3}>
              <Grid item xs sm={6}>
                <TextField
                  id="outlined-total"
                  label="Monto Inicial"
                  value={openAmount}
                  onChange={handleChange}
                  type="number"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  inputProps={{
                    style: {
                      '-moz-appearance': 'textfield',
                    },
                  }}
                />
              </Grid>
              <Grid item xs sm={3}>
                <Button
                  disabled={disabledOpen}
                  color="primary"
                  variant="contained"
                  onClick={handleOpen}
                >
                  Apertura Caja
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Typography variant="h6">No existe ninguna transaccion</Typography>
            </Grid>
          </PortletContent>
        </Portlet>
      );
    }

    return (
      <ListCashFlow
        cashflows={data}
        totalExpected={totalExpected}
        totalBankTransfer={getTotalBankTransfer(data)}
        openAmount={registerOpenAmount}
        handleDeleteCashflow={handleDeleteCashflow}
        handleCloseCashier={handleCloseCashier}
        openModal={openModalEditMethod}
      />
    );
  };

  const saveEditMethod = async method => {
    try {
      await updateMethodCashFlow(dataMethod.id, method);
      showSuccess('Se agrego exitosamente');
      fetchData(branch_id);
    } catch (err) {
      showError('No se pudo registrar');
    }
  };

  return (
    <Layout>
      <div className={classes.root}>
        <Portlet>
          <PortletContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Branch
                  lab={lab}
                  handleBranch={handleBranch}
                  branch_id={branch_id}
                />
              </Grid>
            </Grid>
          </PortletContent>
        </Portlet>

        <CreateExpense handleSave={handleSave} />
        <div className={classes.content}>{renderCashFlow()}</div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={statusSuccessMessage}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <CustomSnackBar
          variant="success"
          className={classes.margin}
          message={message}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={statusErrorMessage}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <CustomSnackBar
          variant="error"
          className={classes.margin}
          message={message}
        />
      </Snackbar>
      <ModalEditMethod
        openModal={openModal}
        setOpenModal={setOpenModal}
        method_payment={dataMethod?.method}
        handleSave={saveEditMethod}
      />
    </Layout>
  );
};

CashFlow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CashFlow);
