import React, { useState, useEffect } from 'react';
import './ProfileLab.css';
import { getImageURL } from '../shared/lib';

import FileUpload from './FileUpload';
import { Grid, TextField, Button } from '@material-ui/core';

const ProfileLab = ({
  profile_lab,
  uploadImage,
  uploadSignature,
  changeInput,
  saveLab,
  loadLabInfo,
}) => {
  const [name, setName] = useState(profile_lab.lab?.name || null);
  const [phonenumber, setPhonenumber] = useState(
    profile_lab.lab?.phonenumber || null,
  );
  const [address, setAddress] = useState(profile_lab.lab?.address || null);
  const [picture, setPicture] = useState(profile_lab.lab?.picture || null);
  const [signature, setSignature] = useState(profile_lab.lab?.signature || null);

  useEffect(() => {
    loadLabInfo();
  }, []);

  if (!profile_lab.lab) {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        Loading data ...
      </Grid>
    );
  }

  const handleName = ev => {
    const value = ev.target.value;
    setName(value);
    changeInput('name', value);
  };

  const handlePhonenumber = ev => {
    const value = Number.parseFloat(ev.target.value);
    setPhonenumber(value);
    changeInput('phonenumber', value);
  };

  const handleAddress = ev => {
    const value = ev.target.value;
    setAddress(value);
    changeInput('address', value);
  };

  const handlePicture = ev => {
    const value = ev.target.value;
    setPicture(value);
    changeInput('picture', value);
  };

  const handleSignature = ev => {
    const value = ev.target.value;
    setSignature(value);
    changeInput('signature', value);
  };

  const handleClick = ev => {
    ev.preventDefault();
    saveLab(profile_lab.lab);
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={6}>
        <TextField
          id="outlined-names"
          label=" Nombre del Laboratorio"
          variant="outlined"
          type="text"
          name="name"
          value={name}
          onChange={handleName}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="outlined-names"
          label="Numero Telefonico"
          variant="outlined"
          type="text"
          name="name"
          value={phonenumber}
          onChange={handlePhonenumber}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="outlined-names"
          label="Direccion"
          variant="outlined"
          type="text"
          name="name"
          value={address}
          onChange={handleAddress}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={6}>
          <img
            className="logo_update"
            src={getImageURL() + '/containers/logos/download/' + picture}
            alt="Logo lab"
          />
          <input type="hidden" name="picture" onChange={handlePicture} />
        </Grid>
        <Grid item xs={6}>
          <FileUpload uploadImage={uploadImage} />
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={6}>
          <img
            className="logo_update"
            src={getImageURL() + '/containers/signature/download/' + signature}
            alt="Logo lab"
          />
          <input type="hidden" name="signature" onChange={handleSignature} />
        </Grid>
        <Grid item xs={6}>
          <FileUpload uploadImage={uploadSignature} />
        </Grid>
      </Grid>

      <Grid item xs={6} className="cell medium-6 margin_lab_top">
        <Button
          onClick={handleClick}
          variant="contained"
          color="primary"
          startIcon={<i className="far fa-save fa-lg"></i>}
          fullWidth
        >
          Guardar
        </Button>
      </Grid>
    </Grid>
  );
  //   }
};

export default ProfileLab;
