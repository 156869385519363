import React, { useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Typography, TextField, Button } from '@material-ui/core';
import { Portlet, PortletContent } from '../../../../Components';

import styles from './style';
import CashFlowTable from '../CashFlowTable';

const ListCashFlow = ({
  classes,
  className,
  cashflows,
  totalExpected,
  totalBankTransfer,
  handleCloseCashier,
  openAmount,
  handleDeleteCashflow,
  openModal,
}) => {
  const [totalAmount, setTotalAmount] = useState(null);
  const [notes, setNotes] = useState('');
  const [disabledClose, setDisabledClose] = useState(false);

  const rootClassName = classNames(classes.root, className);

  const handleSave = async () => {
    setDisabledClose(true);
    await handleCloseCashier(totalAmount, notes);
    setDisabledClose(true);

    setTotalAmount(null);
  };

  const handleChange = name => event => {
    switch (name) {
      case 'notes':
        setNotes(event.target.value);
        break;
      case 'totalAmount':
        setTotalAmount(Number.parseFloat(event.target.value));
        break;
      default:
        console.error('unhandle change', name);
        break;
    }
  };
  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <Grid container spacing={3}>
          <Grid item xs sm={6}>
            <Typography variant="h2">
              Total Esperado:
              {totalExpected} Bs
            </Typography>
          </Grid>
          <Grid item xs sm={6}>
            <Typography variant="h2">
              Total transferencia bancaria:
              {totalBankTransfer} Bs
            </Typography>
          </Grid>
          <Grid item xs sm={3}>
            <TextField
              id="outlined-total"
              label="Total Efectivo en Fisico"
              value={totalAmount}
              onChange={handleChange('totalAmount')}
              type="number"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              inputProps={{
                style: {
                  '-moz-appearance': 'textfield',
                },
              }}
            />
          </Grid>
          <Grid item xs sm={3}>
            <TextField
              id="outlined-notes"
              label="Notas"
              multiline
              rows="4"
              value={notes}
              onChange={handleChange('notes')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs sm={3}>
            <Button
              disabled={disabledClose}
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              Cerrar Caja
            </Button>
          </Grid>
        </Grid>

        <PerfectScrollbar>
          <CashFlowTable
            cashflows={cashflows}
            openAmount={openAmount}
            showActions={true}
            handleDeleteCashflow={handleDeleteCashflow}
            openModal={openModal}
          />
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};

ListCashFlow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  cashflows: PropTypes.array.isRequired,
  totalExpected: PropTypes.number.isRequired,
  openAmount: PropTypes.number,
};

ListCashFlow.defaultProps = {
  cashflows: [],
  totalExpected: 0,
};

export default withStyles(styles)(ListCashFlow);
