import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  UpdateRequestTestAction,
  UpdatePatientAction,
  saveGeneratePdfButtonLegacy,
} from '../../../Actions/request_test';
import {
  AddRequestTestAction,
  AddRequestTestErrorAction,
  AddTestReferenceAction,
  AddCategoryAction,
  CleanTestReferenceAction,
  AddTestValueAction,
  AddTestValuesAction,
  AddPatientRequestAction,
  AddDoctorRequestAction,
  AddTestReferencesAction,
  AddGeneralObsAction,
  DeleteTestValueAction,
} from '../../../Actions';
import {
  GetRequestTestService,
  GetTestReference,
  GetCategories,
  GetTestsValues,
} from '../../../Services/RequestTestService';
import { GetPatient, GetDoctor } from '../../../Services/PatientService';

import Test from '../../../Components/TestReferences/Test';
import PatientData from '../PatientData';
import Physician from '../../../shared/components/Physician';
import ObservationInput from '../../../Components/ObservationInput';
import styles from './style';
import { CustomSnackBar } from '../../../Components';
import { SaveTestResult } from '../../../Services/RequestTestService';

import config from '../../../config';

const useStyles = makeStyles(styles);

let timeoutToCancel = null;

const selectData = state => {
  return {
    request_test: state.request_tests.request_test,
    tests_references: state.request_tests.test_list.tests_references,
    categories: state.request_tests.categories,
    general_obs: state.request_tests.general_obs,
    lab: state.profile_lab.lab,
    doctors: state.doctors.doctors,
  };
};

const FillRequestLegacy = () => {
  const { request_test_id } = useParams();
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [statusSuccessMessage, setStatusSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [statusErrorMessage, setErrorMessage] = useState(false);
  const [internal_code, setInternalCode] = useState('');
  // const [request_test_id, setRequest_test_id] = useState(idx);
  const classes = useStyles();

  const data = useSelector(selectData);
  const { request_test, tests_references, categories, general_obs, lab, doctors } =
    data;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const updatePatient = useCallback(
    (id_patient, patient) => {
      dispatch(UpdatePatientAction(id_patient, patient));
    },
    [dispatch],
  );

  const getRequestTest = async id => {
    try {
      const res_categories = await GetCategories();
      dispatch(AddCategoryAction(res_categories.data));

      const res1 = await GetRequestTestService(id);
      dispatch(AddRequestTestAction(res1.data));
      dispatch(CleanTestReferenceAction());

      const res2 = await GetPatient(res1.data.patient_id);
      dispatch(AddPatientRequestAction(res2.data));

      if (res1.data.doctor_id !== 'A Quien corresponda') {
        const res3 = await GetDoctor(res1.data.doctor_id);
        dispatch(AddDoctorRequestAction(res3.data.user));
      }

      const res = await GetTestsValues(id);
      let data = res.data.data;
      if (data && data[0]) {
        //Here add results values
        dispatch(AddTestReferencesAction(data[0].test_list_values));
        dispatch(AddGeneralObsAction(data[0].general_obs));
        setInternalCode(data[0].internal_code);
      } else {
        res1.data.tests.map(t => {
          GetTestReference(t).then(res => {
            dispatch(AddTestReferenceAction(res.data[0]));
          });
          return 0;
        });
      }
    } catch (error) {
      dispatch(AddRequestTestErrorAction(error));
      console.log(error);
    }
    setLoading(false);
  };

  const setTestData = useCallback(
    (test_id, subtest_id, value) => {
      dispatch(AddTestValueAction(test_id, subtest_id, value));
    },
    [dispatch],
  );

  const handleInputSave = useCallback(
    (test_type, id, subtest_id, value) => {
      if (test_type == 'textadd_delete') {
        dispatch(DeleteTestValueAction(id, subtest_id, value));
      } else {
        dispatch(AddTestValuesAction(id, subtest_id, value));
      }
    },
    [dispatch],
  );

  const showSuccess = message => {
    setStatusSuccessMessage(true);
    setMessage(message);
  };

  const showError = message => {
    setErrorMessage(true);
    setMessage(message);
  };

  const handleObs = useCallback(
    obs => {
      dispatch(AddGeneralObsAction(obs));
    },
    [dispatch],
  );

  const handleSaveButton = ev => {
    ev.preventDefault();

    if (timeoutToCancel) {
      clearTimeout(timeoutToCancel);
    }
    timeoutToCancel = setTimeout(async () => {
      setDisableSaveButton(true);
      try {
        await SaveTestResult(
          request_test_id,
          tests_references,
          general_obs,
          internal_code,
        );
        showSuccess('Se guardo exitosamente los resultados');
      } catch (error) {
        showError('Ocurrio un error al guardar los resultados');
      }
      setDisableSaveButton(false);
    }, 400);
  };

  const handleUpdateDoctor = useCallback(
    (id, value) => {
      dispatch(UpdateRequestTestAction(id, 'doctor_id', value));
    },
    [dispatch],
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatusSuccessMessage(false);
    setErrorMessage(false);
  };

  const handleRequestPDF = async (
    request_test,
    tests_references,
    categories,
    general_obs,
    lab,
    type,
    internal_code,
  ) => {
    showSuccess('Se esta generando el pdf por favor espere unos segundos ...');

    //try {
    //await
    dispatch(
      saveGeneratePdfButtonLegacy(
        request_test,
        tests_references,
        categories,
        general_obs,
        lab,
        type,
        internal_code,
      ),
    );
    // showSuccess('PDF generado exitosamente');
    //} catch (error) {
    // console.log(error);
    // showError('Error al generar el PDF');
    // }

    setDisableSaveButton(false);
  };

  const handlePdfLogo = ev => {
    ev.preventDefault();
    handleRequestPDF(
      request_test,
      tests_references,
      categories,
      general_obs,
      lab,
      'both',
      internal_code,
    );
  };

  const handlePdf = ev => {
    ev.preventDefault();
    handleRequestPDF(
      request_test,
      tests_references,
      categories,
      general_obs,
      lab,
      'pdf',
      internal_code,
    );
  };

  const handleInputChange = useCallback(
    e => {
      console.log('handleInputChange', e);
    },
    [dispatch],
  );

  const handleInternalCodeChange = ev => {
    setInternalCode(ev.target.value);
  };

  useEffect(() => {
    setInternalCode('');
    if (request_test_id !== null) {
      if (timeoutToCancel) {
        clearTimeout(timeoutToCancel);
      }

      timeoutToCancel = setTimeout(async () => {
        getRequestTest(request_test_id);
      }, 1000);
    }
  }, [request_test_id]);

  let patient_data = null;
  let sex = null;
  let years_old = null;
  let request_id = null;

  if (request_test.request_test) {
    if (typeof request_test.request_test.patient !== 'undefined') {
      let patient_x = request_test.request_test.patient;

      patient_data = (
        <PatientData
          show_pencil_edit={true}
          updatePatient={updatePatient}
          {...patient_x}
        />
      );
    }

    sex = request_test.request_test.sex;
    years_old = request_test.request_test.years_old;
  }
  if (request_test.request_test != null) {
    request_id = request_test.request_test.id;
  }

  return loading ? (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid
        item
        container
        xs={12}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          {patient_data}
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Physician
            handleUpdateDoctor={handleUpdateDoctor}
            doctors={doctors}
            doctor={
              request_test?.request_test?.doctor
                ? request_test.request_test.doctor
                : null
            }
            request_id={request_id}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            type="text"
            placeholder="Codigo Interno"
            name="internal_code"
            variant="outlined"
            label="Codigo Interno"
            value={internal_code}
            onChange={handleInternalCodeChange}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} className={classes.buttons}>
          <Typography variant="h4">Pruebas del Paciente</Typography>
        </Grid>

        {tests_references.map((tests_reference, index) => {
          if (tests_reference) {
            return (
              <Grid item xs={12} key={tests_reference.test_id} container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.title} variant="h4">
                    {tests_reference.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} container>
                  <Test
                    {...tests_reference}
                    sex={sex}
                    years_old={years_old}
                    onInputChange={handleInputChange}
                    setTestData={setTestData}
                    handleInputSave={handleInputSave}
                  />
                </Grid>
              </Grid>
            );
          } else {
            return (
              <div className="cell medium-12 boder_bottom" Key={index + 'x'}>
                <div> falta datos de referencia para una prueba</div>
              </div>
            );
          }
        })}
      </Grid>
      <Grid container item xs={12} className={classes.buttons}>
        <ObservationInput obs={general_obs} handleObs={handleObs} />
      </Grid>

      <Grid
        container
        direction="row"
        spacing={3}
        justify="center"
        alignItems="center"
        item
        xs={12}
      >
        <Grid item xs={4} className={classes.buttons}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<i className="far fa-save fa-lg"></i>}
            onClick={handleSaveButton}
            disabled={disableSaveButton}
          >
            Guardar
          </Button>
        </Grid>
        <Grid item xs={4} className={classes.buttons}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<i className="far fa-file-pdf fa-lg"></i>}
            onClick={handlePdf}
            disabled={disableSaveButton}
          >
            Generar PDF
          </Button>
        </Grid>
        <Grid item xs={4} className={classes.buttons}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<i className="far fa-file-pdf fa-lg"></i>}
            onClick={handlePdfLogo}
            disabled={disableSaveButton}
          >
            Generar PDF Logo
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={statusSuccessMessage}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <CustomSnackBar
          variant="success"
          className={classes.margin}
          message={message}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={statusErrorMessage}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <CustomSnackBar
          variant="error"
          className={classes.margin}
          message={message}
        />
      </Snackbar>
    </Grid>
  );
};
export default FillRequestLegacy;
