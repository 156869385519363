import prod from '../../prod.json';
import dev from '../../dev.json';

export const getImageURL = () => {
  console.log('env', process.env);
  switch (process.env) {
    case 'dev':
      return dev.storage_service;
    case 'prod':
      return prod.storage_service;
    default:
      return prod.storage_service;
  }
};
