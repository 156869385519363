import React from 'react';

const LimitEdit = ({ limit, refe_id, subtest_id, refe_index, handleInputChange}) => (
    <div className="cell medium-12 grid-x grid-padding-x">
            <span className="cell medium-2 text-right">
                Hasta:
            </span>
            <input type="number" className="cell medium-10" placeholder="Hasta" data-type="limit" name="limit" id={'limit_'+refe_id} data-subtest_id={subtest_id} data-refe_index={refe_index}  value={limit} onChange={handleInputChange}  />
    </div>
)

export default LimitEdit
