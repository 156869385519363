import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress, withStyles, Grid } from '@material-ui/core';
import { PatientsTable, Search, ModalPaymentBalance } from './components';
import {
  getPaymentInfo,
  payBalance,
  updatePaymentInfo,
  searchByFullName,
} from '../../Services/FinanceService';
import Layout from '../../layout';

import { Branch } from '../CashFlow/Components';

import styles from './styles';

const ReportFinance = ({ classes, lab }) => {
  const [isLoading, setLoading] = useState(false);
  const [paymentInfos, setPayments] = useState([]);
  const [error, setError] = useState(null);
  const [openModalPayBalance, setOpenModalPayBalance] = useState(false);
  const [payment_id, setPayment_id] = useState(null);
  const [patient_full_name, setPatient_full_name] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [branch_id, setBranchId] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);

      const payments = await getPaymentInfo(branch_id);
      setLoading(false);
      setPayments(payments.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [branch_id]);

  const payBalanceById = async (paymentMethod, amount) => {
    const balance_payment_received_by = 'carla';
    const balance_payment_receiver_id = '5bbbf9e9e3afaf0001b5ce64';
    try {
      setLoading(true);

      await payBalance(
        payment_id,
        balance_payment_received_by,
        balance_payment_receiver_id,
        patient_full_name,
        paymentMethod,
        amount,
      );

      const payments = await getPaymentInfo();
      setLoading(false);
      setPayments(payments.data);
    } catch (error) {}
  };

  const openModalPay = (payment_id, patient_full_name) => {
    setOpenModalPayBalance(true);
    setPayment_id(payment_id);
    setPatient_full_name(patient_full_name);
  };

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setBranchId(value);
    localStorage.setItem('branch', value);
  };

  const giveFacturaById = async payment_id => {
    try {
      setLoading(true);

      await updatePaymentInfo(payment_id, 'status_factura', true);

      const payments = await getPaymentInfo();
      setLoading(false);
      setPayments(payments.data);
    } catch (error) {}
  };

  let timeoutToCancel = null;

  /*const handleSearchByName = async ev => {
    const value = ev.target.value;

    if (value !== '') {
      if (timeoutToCancel) {
        clearTimeout(timeoutToCancel);
      }

      timeoutToCancel = setTimeout(async () => {
        setLoading(true);
        const searchData = await searchByFullName(value);
        if (searchData.data.payment_infos.length > 0) {
          setPayments(searchData.data.payment_infos);
        } else {
          setPayments([]);
        }
        setLoading(false);
      }, 400);
    } else {
      setLoading(true);
      const payments = await getPaymentInfo();
      setLoading(false);
      setPayments(payments.data);
    }
  };*/

  const renderPaymentsInfo = () => {
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (paymentInfos.length === 0) {
      return <Typography variant="h6">No Existen Pagos Pendientes</Typography>;
    }

    return (
      <Layout>
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Branch lab={lab} handleBranch={handleBranch} branch_id={branch_id} />
          </Grid>
        </Grid>
        <PatientsTable
          payments={paymentInfos}
          giveFacturaById={giveFacturaById}
          openModalPay={openModalPay}
        />
        <ModalPaymentBalance
          openModal={openModalPayBalance}
          setOpenModal={setOpenModalPayBalance}
          patient_full_name={patient_full_name}
          handleSave={payBalanceById}
        />
      </Layout>
    );
  };

  return <div>{renderPaymentsInfo()}</div>;
};

export default withStyles(styles)(ReportFinance);
