import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import TypeLimit from './TypeLimit';
import TypeRanges from './TypeRanges';
import TypeRange from './TypeRange';
import TypeTextPosNeg from './TypeTextPosNeg';
import TypeText from './TypeText';
import TypeTextAdd from './TypeTextAdd';
import TypeGeneric from './TypeGeneric';

class TestType extends PureComponent {
  render() {
    const { type, ...data } = this.props;
    let test = null;
    switch (type) {
      case 'limit':
        test = <TypeLimit {...data} />;
        break;
      case 'ranges':
        test = <TypeRanges {...data} />;
        break;
      case 'ranges_text':
        test = <TypeRanges {...data} />;
        break;
      case 'range':
        test = <TypeRange {...data} />;
        break;
      case 'text_pos_neg':
        test = <TypeTextPosNeg {...data} />;
        break;
      case 'text':
        test = <TypeText {...data} />;
        break;
      case 'text_line':
        test = <TypeText {...data} />;
        break;
      case 'text_add':
        test = <TypeTextAdd {...data} />;
        break;
      case 'generic':
        test = <TypeGeneric {...data} />;
        break;
      default:
        test = <div></div>;
    }

    return (
      <Grid item xs={12}>
        {test}
      </Grid>
    );
  }
}

export default TestType;
