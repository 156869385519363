import { loginService, getMyLabToken } from "../Services/UserService";
import { LoginSuccessAction, LoginTestErrorAction } from "../Actions";
import { GetLabInfo } from "../Actions/lab";

const doFunc = async (dispatch, username, password) => {
  try {
    const res = await loginService(username, password);

    console.log(res);

    const res_x = await getMyLabToken(res.data.userId);
    if (res_x.data.length > 0) {
      localStorage.setItem("enterprise_token", res_x.data[0].laboratory_id);
      localStorage.setItem("token", res.data.id);
      localStorage.setItem("user_id", window.btoa(res.data.userId));
      dispatch(GetLabInfo());
    }

    dispatch(LoginSuccessAction());
    window.location.href = "/processing";
  } catch (error) {
    console.log(error);
    dispatch(LoginTestErrorAction(error));
  }
};

export const LoginAction = (username, password) => {
  return (dispatch) => {
    doFunc(dispatch, username, password);
  };
};
