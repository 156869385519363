export default theme => ({
  root: {},
  tableRow: {
    height: '64px',
  },
  margin: {
    margin: theme.spacing(2),
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableRowDeleted: {
    backgroundColor: theme.palette.danger.deleted,
    textDecoration: 'line-through',
  },
  Line: {
    textDecoration: 'line-through',
  },
});
