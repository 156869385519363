import { AppToaster } from '../Components/Toaster';
import { Intent } from '@blueprintjs/core';

import { GetLab, SaveLab } from '../Services/LabService';
import { getDoctors } from '../Services/DoctorService';
import { AddDoctorsAction } from './index';

export const ADD_LAB_INFO = 'ADD_LAB_INFO';
export const CHANGE_LAB = 'CHANGE_LAB';
export const CHANGE_LAB_SIGNATURE = 'CHANGE_LAB_SIGNATURE';

export const GetLabInfo = () => {
  return dispatch => {
    GetLab().then(res => {
      dispatch(AddLabInfo(res.data));
    });

    getDoctors().then(res => {
      dispatch(AddDoctorsAction(res.data));
    });
  };
};

export const AddLabInfo = data => {
  return {
    type: ADD_LAB_INFO,
    payload: data,
  };
};

export const ChangeLab = data => {
  return {
    type: CHANGE_LAB,
    payload: data,
  };
};

export const ChangeLabSignature = data => {
  return {
    type: CHANGE_LAB_SIGNATURE,
    payload: data,
  };
};

export const SaveLabAction = lab => {
  return dispatch => {
    SaveLab(lab)
      .then(res => {
        dispatch(GetLabInfo());
        AppToaster.show({
          message: 'Se guardo exitosamente',
          timeout: 3000,
          intent: Intent.SUCCESS,
        });
      })
      .catch(err => {
        AppToaster.show({
          message: 'Error al guardar',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      });
  };
};
