import React, { PureComponent } from 'react';
import { Radio, RadioGroup } from '@blueprintjs/core';
import './PatientData.css';
import { Grid, Typography, TextField } from '@material-ui/core';

class PatientData extends PureComponent {
  constructor(props) {
    super(props);
    const { full_name, last_names, phonenumber, sex, years_old, id } = this.props;

    this.state = {
      edit_patient: false,
      full_name,
      last_names,
      phonenumber,
      sex,
      years_old,
      id,
    };
  }

  handleEditPatient = () => {
    this.setState({ edit_patient: !this.state.edit_patient });
  };

  updatePatient = ev => {
    ev.preventDefault();
    const { name, value } = ev.target;
    this.setState({ [name]: value });
  };

  handleSexChange = ev => {
    this.setState({ sex: ev.currentTarget.value });
  };

  handleSavePatient = ev => {
    ev.preventDefault();

    const { updatePatient } = this.props;
    const data = {
      full_name: this.state.full_name,
      last_names: this.state.last_names,
      phonenumber: this.state.phonenumber,
      sex: this.state.sex,
      years_old: this.state.years_old,
    };
    updatePatient(this.state.id, data);

    this.handleEditPatient();
  };

  render() {
    const { full_name, last_names, phonenumber, sex, years_old, show_pencil_edit } =
      this.props;
    let edit_html = null;
    let show_pencil = null;

    if (this.state.edit_patient) {
      const years_x = [...Array(119).keys()];
      const months = [
        'month_1',
        'month_2',
        'month_3',
        'month_4',
        'month_5',
        'month_6',
        'month_7',
        'month_8',
        'month_9',
        'month_10',
        'month_11',
        'month_12',
      ];
      const days = [
        'day_1',
        'day_2',
        'day_3',
        'day_4',
        'day_5',
        'day_6',
        'day_7',
        'day_8',
        'day_9',
        'day_10',
        'day_11',
        'day_12',
        'day_13',
        'day_14',
        'day_15',
        'day_16',
        'day_17',
        'day_18',
        'day_19',
        'day_20',
        'day_21',
        'day_22',
        'day_23',
        'day_24',
        'day_25',
        'day_26',
        'day_27',
        'day_28',
        'day_29',
        'day_30',
        'day_31',
      ];

      show_pencil = null;
      edit_html = (
        <Grid item xs={12} container>
          <Grid item xs={3}>
            <TextField
              type="text"
              label="Nombres"
              name="full_name"
              variant="outlined"
              fullWidth
              value={this.state.full_name}
              onChange={this.updatePatient}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              type="text"
              label="Apellidos"
              name="last_names"
              variant="outlined"
              fullWidth
              value={this.state.last_names}
              onChange={this.updatePatient}
            />
          </Grid>
          <Grid item xs={3}>
            <RadioGroup
              inline={true}
              label="Sexo"
              onChange={this.handleSexChange}
              selectedValue={this.state.sex}
            >
              <Radio label="Mujer" value="female" />
              <Radio label="Varon" value="male" />
            </RadioGroup>
          </Grid>

          <Grid item xs={3}>
            <label>Edad:</label>

            <select
              name="years_old"
              value={this.state.years_old}
              onChange={this.updatePatient}
            >
              <option value="">Selecciona la edad</option>
              {days.map(day => {
                let label_day = 'Dias';
                if (day === 'day_1') {
                  label_day = 'Dia';
                }
                let day_show = day.split('_');
                return (
                  <option key={day} value={day}>
                    {day_show[1]} {label_day}
                  </option>
                );
              })}
              {months.map(month => {
                let label_month = 'Meses';
                if (month === 'month_1') {
                  label_month = 'Mes';
                }

                let month_show = month.split('_');
                return (
                  <option key={month} value={month}>
                    {month_show[1]} {label_month}
                  </option>
                );
              })}
              <option value="1">1 Año</option>
              {years_x.map(index => {
                if (index === 18) {
                  return (
                    <option key={index} value={index + 2} selected>
                      {index + 2} Años
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={index + 2}>
                      {index + 2} Años
                    </option>
                  );
                }
              })}
            </select>
          </Grid>

          <Grid item xs={3}>
            <TextField
              type="text"
              placeholder="Numero de Celular"
              name="phonenumber"
              variant="outlined"
              fullWidth
              value={this.state.phonenumber}
              onChange={this.updatePatient}
            />
          </Grid>

          <Grid item xs={3}>
            <a
              onClick={this.handleEditPatient}
              className="cell medium-12 button secondary button_top"
            >
              Cancelar
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              onClick={this.handleSavePatient}
              className="cell medium-12 button button_top"
            >
              Actualizar Paciente
            </a>
          </Grid>
        </Grid>
      );
    } else {
      if (show_pencil_edit) {
        show_pencil = <a onClick={this.handleEditPatient} className="fas fa-pen" />;
      }

      let number_old = null;
      let label_old = '';

      if (typeof years_old !== 'number') {
        let label = years_old.split('_');

        if (label[0] === 'day') {
          number_old = label[1];
          label_old = 'Dias';
          if (number_old === '1') {
            label_old = 'Dia';
          }
        } else if (label[0] === 'month') {
          number_old = label[1];
          label_old = 'Meses';
          if (number_old === '1') {
            label_old = 'Mes';
          }
        } else {
          number_old = label[0];
          label_old = 'Años';
          if (number_old === 1) {
            label_old = 'Año';
          }
        }
      } else {
        number_old = years_old;
        label_old = 'Años';

        if (number_old === 1) {
          label_old = 'Año';
        }
      }

      edit_html = (
        <Grid
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          xs={12}
        >
          <Grid item xs={3}>
            <strong>Paciente:</strong>
            {full_name + ' ' + last_names}
          </Grid>
          <Grid item xs={3}>
            <strong>Telefono:</strong>
            {phonenumber}{' '}
          </Grid>
          <Grid item xs={3}>
            <strong>Sexo:</strong>
            {sex === 'female' ? 'Mujer' : 'Varon'}
          </Grid>
          <Grid item xs={3}>
            <strong>Edad:</strong>
            {number_old} {label_old}
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} container spacing={3}>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4">Datos del Paciente {show_pencil}</Typography>
          </Grid>
        </Grid>
        {edit_html}
      </Grid>
    );
  }
}

export default PatientData;
