import { connect } from 'react-redux';
import RegisterBills from '.';

const mapStateToProps = state => {
  return {
    lab: state.profile_lab.lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const RegisterBillsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterBills);

export default RegisterBillsContainer;
