import axios from 'axios';
import config from '../config.json';

const url = config.lab_service;

export const GetReference = test_id => {
  let params = {
    params: {
      filter: {
        where: {
          test_id: test_id,
        },
      },
    },
  };

  return axios.get(url + '/lab_test_references', params);
};

export const SaveReference = (ref_id, subtest_id, subtest) => {
  return axios.put(
    url + '/lab_test_references/' + ref_id + '/sub_tests/' + subtest_id,
    subtest,
  );
};

export const ServiceDeleteSubtest = (ref_id, subtest_id) => {
  return axios.delete(
    url + '/lab_test_references/' + ref_id + '/sub_tests/' + subtest_id,
  );
};

export const CreateSubtest = (subtest, reference_id) => {
  return axios.post(
    url + '/lab_test_references/' + reference_id + '/sub_tests',
    subtest,
  );
};

export const GetTestById = test_id => {
  let params = {
    params: {
      filter: {
        where: {
          'tests.id': {
            eq: test_id,
          },
        },
      },
    },
  };

  return axios.get(url + '/categories', params);
};

export const SavePrice = (category_id, test_id, test) => {
  return axios.put(url + '/categories/' + category_id + '/test/' + test_id, test);
};

export const DeleteTest = (category_id, test_id, test) => {
  return axios.put(url + '/categories/' + category_id + '/test/' + test_id, {
    ...test,
    deleted: true,
  });
};
