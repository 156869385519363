import { connect } from 'react-redux';
import {
  AddPricesAction,
  AddPricesErrorAction,
  ToggleTest,
  ClearTest,
} from '../../Actions';
//import AddRequestTest from './AddRequestTest';
import AddRequestTestLegacy from './index';

import { GetPrices } from '../../Services/RequestTestService';
import { GetLabInfo } from '../../Actions/lab';

const concat = (x, y) => x.concat(y);
const add = (x, y) => x + y;

const flatten = list =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

const getTotal = categories => {
  let x_tests = categories.map(category =>
    category.tests.filter(test => test.modified_at === true),
  );
  let prices = x_tests.map(test => test.map(t => t.price)).reduce(concat, []);

  return prices.reduce(add, 0);
};

const getTests = categories => {
  let x_tests = categories.map(category =>
    category.tests.filter(test => test.modified_at === true),
  );

  let y_tests = flatten(x_tests);
  return y_tests.map(t => t.id);
};

const getTestLists = categories => {
  let x_tests = categories.map(category =>
    category.tests.filter(test => test.modified_at === true),
  );

  let res = flatten(x_tests);
  let res_x = res.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return res_x;
};

const mapStateToProps = state => {
  return {
    prices: state.prices.tests,
    doctors: state.doctors.doctors,
    total: getTotal(state.prices.tests),
    tests_selected: getTests(state.prices.tests),
    test_list: getTestLists(state.prices.tests),
    lab: state.profile_lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadPrices: () => {
      GetPrices()
        .then(res => {
          dispatch(AddPricesAction(res.data));
        })
        .catch(err => {
          dispatch(AddPricesErrorAction(err));
        });

      dispatch(GetLabInfo());
    },
    onTestClick: id => {
      dispatch(ToggleTest(id));
    },
    onClearClick: () => {
      dispatch(ClearTest());
    },
  };
};

const AddResquestTestContainerLegacy = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRequestTestLegacy);

export default AddResquestTestContainerLegacy;
