import React from 'react';
import {
  Select as SelectUI,
  MenuItem as MenuItemUI,
  FormControl,
  InputLabel,
} from '@material-ui/core';

const Branch = ({ lab, handleBranch, branch_id }) => {
  if (lab && lab.branchs) {
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel id="branch">Sucursal</InputLabel>
        <SelectUI
          labelId="branch"
          id="branch-select"
          value={branch_id}
          label="Sucursal"
          name="branch"
          onChange={handleBranch}
          defaultValue={branch_id}
        >
          {lab.branchs.map(branch => {
            return (
              <MenuItemUI key={branch.id} value={branch.id}>
                {branch.name}
              </MenuItemUI>
            );
          })}
        </SelectUI>
      </FormControl>
    );
  }

  return null;
};

export default Branch;
