import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';

class TypeText extends PureComponent {
  render() {
    const { ...data } = this.props;
    return (
      <Grid container xs={12} className="boder_bottom_text">
        <Grid item xs={4}>
          {data.name}
        </Grid>
        <Grid item xs={3}>
          {data.value}
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>
    );
  }
}

export default TypeText;
