import React from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Badge,
  Typography,
  IconButton,
  Link,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';

import classNames from 'classnames';

import styles from './style';

const CashFlowTable = ({
  classes,
  className,
  cashflows,
  openAmount,
  showActions,
  handleDeleteCashflow,
  openModal,
}) => {
  const rootClassName = classNames(classes.root, className);

  const EditPaymentMethod = ev => {
    const { id, method } = ev.target.dataset;
    openModal(id, method);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Tipo</TableCell>
          <TableCell align="left">Categoria</TableCell>
          <TableCell align="left">Descripcion</TableCell>
          <TableCell align="left">Monto</TableCell>
          <TableCell align="left">Metodo de Pago</TableCell>
          {showActions ? <TableCell align="left">Acciones</TableCell> : ''}
          <TableCell align="left">Fecha y Hora</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {openAmount ? (
          <TableRow key={'openAmount'}>
            <TableCell className={classes.tableCell}>Entrada</TableCell>
            <TableCell className={classes.tableCell}>Monto Inicial</TableCell>
            <TableCell className={classes.tableCell}> #AperturaCaja</TableCell>
            <TableCell className={classes.tableCell}>
              <Badge
                className={classes.margin}
                badgeContent={openAmount + ' Bs'}
                max={100000000}
                color="primary"
              />
            </TableCell>
            <TableCell className={classes.tableCell}>Efectivo</TableCell>
            <TableCell className={classes.tableCell} />
          </TableRow>
        ) : null}
        {cashflows.map(cashFlow => (
          <TableRow
            className={
              cashFlow.deleted && cashFlow.deleted === true
                ? classes.tableRowDeleted
                : ''
            }
            key={cashFlow.id}
          >
            <TableCell className={classes.tableCell}>
              {cashFlow.type === 'in' ? 'Entrada' : 'Salida'}
            </TableCell>
            <TableCell className={classes.tableCell}>{cashFlow.category}</TableCell>

            <TableCell className={classes.tableCell}>
              {cashFlow.deleted && cashFlow.deleted == true ? (
                <Typography className={classes.Line} variant="body1">
                  {cashFlow.description}{' '}
                </Typography>
              ) : (
                cashFlow.description
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {cashFlow.type === 'in' ? (
                <Badge
                  className={classes.margin}
                  badgeContent={cashFlow.total_amount + ' Bs'}
                  max={100000000}
                  color="primary"
                />
              ) : (
                <Badge
                  className={classes.margin}
                  badgeContent={cashFlow.total_amount + ' Bs'}
                  max={100000000}
                  color="error"
                />
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {cashFlow.payment_method == 'cash'
                ? 'Efectivo'
                : cashFlow.payment_method}

              <Link
                component="button"
                variant="body2"
                data-id={cashFlow.id}
                data-method={cashFlow.payment_method}
                onClick={EditPaymentMethod}
              >
                &nbsp; Editar metodo de pago
              </Link>
            </TableCell>

            {showActions && cashFlow.type === 'out' ? (
              <TableCell className={classes.tableCell}>
                {!cashFlow.deleted ? (
                  <IconButton
                    onClick={handleDeleteCashflow(cashFlow.id)}
                    aria-label="delete"
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </TableCell>
            ) : (
              ''
            )}

            <TableCell className={classes.tableCell}>
              {moment(cashFlow.created_at).calendar(null, {
                sameElse: 'MMMM Do YYYY, h:mm:ss a',
              })}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(CashFlowTable);
