export default theme => ({
  title: {
    color: theme.palette.text.title,
  },
  headerTitle: {
    color: theme.palette.text.title,
    marginTop: '1.2rem',
    marginBottom: '1.2rem',
  },
});
