import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Select,
  MenuItem,
  Grid,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core';
import Layout from '../../layout';

import { downloadExcel } from '../../Services/RequestTestService';

import { Portlet, PortletContent } from '../../Components';
import { Branch } from '../CashFlow/Components';
import styles from './styles';

const ReportCash = ({ lab }) => {
  const santo = 'gML0yjJRA1ANHfHOAdeD';
  const [user_santo, setUserSanto] = useState(null);
  const branch_stored = localStorage.getItem('branch');

  const [branch_id, setBranchId] = useState(branch_stored || null);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const endYear = new Date().getFullYear();

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setBranchId(value);
    localStorage.setItem('branch', value);
  };

  const handleDownload = async () => {
    downloadExcel(branch_id, month, year);
  };

  const handleChange = event => {
    setUserSanto(event.target.value);
  };

  return (
    <Layout>
      <Portlet>
        <PortletContent>
          {user_santo === santo ? (
            <Grid container spacing={3}>
              <Grid item md={12}>
                <h1>Reporte de Comiciones de Medicos</h1>
              </Grid>

              <Grid item md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="branch">Mes</InputLabel>
                  <Select
                    value={month}
                    onChange={event => setMonth(event.target.value)}
                    fullWidth
                  >
                    <MenuItem value={0}>Enero</MenuItem>
                    <MenuItem value={1}>Febrero</MenuItem>
                    <MenuItem value={2}>Marzo</MenuItem>
                    <MenuItem value={3}>Abril</MenuItem>
                    <MenuItem value={4}>Mayo</MenuItem>
                    <MenuItem value={5}>Junio</MenuItem>
                    <MenuItem value={6}>Julio</MenuItem>
                    <MenuItem value={7}>Agosto</MenuItem>
                    <MenuItem value={8}>Septiembre</MenuItem>
                    <MenuItem value={9}>Octobre</MenuItem>
                    <MenuItem value={10}>Noviembre</MenuItem>
                    <MenuItem value={11}>Deciembre</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="branch">Año</InputLabel>
                  <Select
                    value={year}
                    onChange={event => setYear(event.target.value)}
                    fullWidth
                  >
                    {Array.from(
                      { length: endYear - 2024 + 1 },
                      (_, i) => i + 2024,
                    ).map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <Branch
                  lab={lab}
                  handleBranch={handleBranch}
                  branch_id={branch_id}
                />
              </Grid>
              <Grid item md={3}>
                <Button
                  disabled={branch_id ? false : true}
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                >
                  Descargar Reporte
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item md={76}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Enter password"
                  type="password"
                  value={user_santo}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )}
        </PortletContent>
      </Portlet>
    </Layout>
  );
};

export default withStyles(styles)(ReportCash);
