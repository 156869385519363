import React, { useState, Fragment } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Material helpers
import {
  withStyles,
  Select as SelectUI,
  MenuItem as MenuItemUI,
} from '@material-ui/core';

import {
  Check as CheckIcon,
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from '@material-ui/icons';
// Material components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Link,
} from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from '../../../../Components';

// Component styles
import styles from './styles';

const PatientsTable = ({
  classes,
  className,
  payments,
  openModalPay,
  giveFacturaById,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState([0]);

  const rootClassName = classNames(classes.root, className);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const payBalance = ev => {
    const { id, patient_full_name } = ev.target.dataset;
    // payBalanceById(id, patient_full_name);
    openModalPay(id, patient_full_name);
  };

  const giveFactura = ev => {
    const { id } = ev.target.dataset;
    giveFacturaById(id);
  };

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Fecha Examen</TableCell>
                <TableCell align="left">Paciente</TableCell>
                <TableCell align="left">Total</TableCell>
                <TableCell align="left">Descuento</TableCell>
                <TableCell align="left">A cuenta</TableCell>
                <TableCell align="left">Saldo</TableCell>
                <TableCell align="left">Estado Pago</TableCell>
                <TableCell align="left">Metodo de Pago</TableCell>
                <TableCell align="left">Estado Factura</TableCell>
                <TableCell align="left">Sucursal</TableCell>
                <TableCell align="left">ID Prueba</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.slice(0, rowsPerPage).map(payment => (
                <TableRow
                  className={
                    payment.deleted ? classes.redBackground : classes.tableRow
                  }
                  hover
                  key={payment.id}
                >
                  <TableCell className={classes.tableCell}>
                    {payment.deleted ? (
                      <Typography className={classes.Line} variant="body1">
                        {moment(payment.created_at).format('DD/MM/YYYY')}
                      </Typography>
                    ) : (
                      moment(payment.created_at).format('DD/MM/YYYY')
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.tableCellInner}>
                      {payment.deleted ? (
                        <Typography className={classes.nameTextLine} variant="body1">
                          {payment.patient_full_name}
                        </Typography>
                      ) : (
                        <Typography className={classes.nameText} variant="body1">
                          {payment.patient_full_name}
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {payment.deleted ? (
                      <Typography className={classes.nameTextLine} variant="body1">
                        {payment.total_amount}
                      </Typography>
                    ) : (
                      payment.total_amount
                    )}
                  </TableCell>
                  <TableCell>
                    {payment.deleted ? (
                      <Typography className={classes.nameTextLine} variant="body1">
                        {payment.discount}
                      </Typography>
                    ) : (
                      payment.discount
                    )}
                  </TableCell>
                  <TableCell>
                    {payment.deleted ? (
                      <Typography className={classes.nameTextLine} variant="body1">
                        {payment.on_account}
                      </Typography>
                    ) : (
                      payment.on_account
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {payment.deleted ? (
                      <Typography className={classes.nameTextLine} variant="body1">
                        {payment.balance}
                      </Typography>
                    ) : !payment.status_payment ? (
                      <>
                        <Typography style={{ color: 'red' }} variant="body1">
                          {payment.balance}
                        </Typography>

                        <Link
                          component="button"
                          variant="body2"
                          data-patient_full_name={payment.patient_full_name}
                          data-id={payment.id}
                          onClick={payBalance}
                        >
                          Pagar Saldo
                        </Link>
                      </>
                    ) : (
                      'Pago completado'
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {payment.status_payment ? (
                      <>
                        <CheckIcon className={classes.checked} />
                        {payment.deleted ? (
                          <Typography className={classes.Line} variant="body1">
                            Entregar Resultado
                          </Typography>
                        ) : (
                          'Entregar Resultado'
                        )}
                      </>
                    ) : (
                      <CloseIcon className={classes.unChecked} />
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {payment.payment_method === 'cash' ? (
                      <>Efectivo</>
                    ) : payment.payment_method === 'bank_transfer' ? (
                      'Transferencia Bancaria'
                    ) : (
                      payment.payment_method
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {payment.status_factura ? (
                      <>
                        <CheckIcon className={classes.checked} />
                        facturado
                      </>
                    ) : (
                      <>
                        Sin facturar{' '}
                        <Link
                          component="button"
                          variant="body2"
                          data-id={payment.id}
                          onClick={giveFactura}
                        >
                          (actualizar estado factura)
                        </Link>
                      </>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {payment.branch_id}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {payment.request_test_id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          component="div"
          count={payments.length}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </PortletContent>
    </Portlet>
  );
};

PatientsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
  payBalanceById: PropTypes.func,
  giveFacturaById: PropTypes.func,
};

PatientsTable.defaultProps = {
  payments: [],
};

export default withStyles(styles)(PatientsTable);
// export default memo(withStyles(styles)(PatientsTable));
