import { connect } from 'react-redux';
import { GetTestsResultsLegacy, SearchPatient } from '../Actions/tests_results';
import ListPatientResultLegacy from '../Components/ListPatientResultLegacy';
import { ProcessingStatusRequestTest } from '../Services/RequestTestService';

const mapStateToProps = state => {
  return {
    tests_results: state.tests_results,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCompleteTests: () => {
      dispatch(GetTestsResultsLegacy(1));
    },
    handleEditButton: request_id => {
      ProcessingStatusRequestTest(request_id).then(res => {
        dispatch(GetTestsResultsLegacy());
        window.location.href = '/processingLegacy/' + request_id;
      });
    },
    handleSeeResults: request_id => {
      window.location.href = '/results_legacy/' + request_id;
    },
    goNextPage: next_page => {
      dispatch(GetTestsResultsLegacy(next_page));
    },
    searchPatient: search => {
      dispatch(SearchPatient(search));
    },
  };
};

const ListContainerLegacy = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListPatientResultLegacy);

export default ListContainerLegacy;
