import React from 'react'

const AddResult = () => {
    let file, doctor
    return (
    <div className="grid-x">
        <div className="cell large-12">
            <input className="expanded" type="text" data-toggle="example-dropdown-bottom-center" placeholder="Buscar Paciente por nombres, apellidos, telefono" />
            <div className="dropdown-pane" data-position="bottom" data-alignment="center" id="example-dropdown-bottom-center" data-dropdown data-auto-focus="true">
                resulst go here
            </div>
        </div>
            <div className="cell large-3">
                <h4>Paciente</h4>
                <h5>
                    Nombres
                </h5>
                <div className="cell">
                    Marcelo
                </div>
                <h5>
                    Apellidos
                </h5>
                <div className="cell">
                    Camacho
                </div>
                <h5>
                    Sexo
                </h5>
                <div className="cell">
                    Masculino
                </div>
                <h5>
                    Edad
                </h5>
                <div className="cell">
                    { '20 años' }
                </div>
                <h5>
                    Telefono Celular
                </h5>
                <div className="cell">
                    75488770
                </div>
                <h5>
                    Email
                </h5>
                <div className="cell">
                    rolib2@gmail.com
                </div>
            </div>
            <div className="cell large-9">
                <div className="grid-x">
                    <div className="cell">
                        <h4>Agregar Resultados</h4>
                        <div className="grid-x grid-margin-x">
                            <div className="cell large-4">
                                  <select  onChange={(ev) => { doctor = ev.target.value }}>
                                    <option value="123">Selecciona un medico</option>
                                    <option value="123">Marco Canedo</option>
                                    <option value="1234">Harold</option>
                                  </select>
                            </div>

                            <div className="cell large-8 grid-x">
                                <input type="file"
                                    onChange={(ev) => {
                                        file = ev.target.files[0]
                                    }} className="cell large-8" />

                                <button className="button cell large-4" onClick={() => {
                                        console.log(file);
                                        console.log(doctor);
                                    }}>Subir Pdf</button>
                            </div>
                        </div>
                    </div>
                    <div className="cell">
                        <h4>Resultados</h4>
                        <div className="cell">
                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i> { '  ' }
                            entregado el { '23/10/2017' } al { 'Dr. Canedo' }
                        </div>
                        <div className="cell">
                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i> { '  ' }
                            entregado el { '23/10/2017' } al { 'Dr. Canedo' }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddResult
