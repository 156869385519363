import React, { useState, useEffect } from 'react';
import './Reference.css';
import {
  ReferenceEdit,
  SubtestNameEdit,
  CreateReferenceItem,
  CreateSubtest,
} from './ReferencesEdit';
import { Grid, Button } from '@material-ui/core';
import TestPriceEdit from './TestPriceEdit';
import { Link, useParams } from 'react-router-dom';

const Reference = ({
  getReferenceTest,
  getTest,
  saveReference,
  reference,
  handleInputChange,
  handleAddSubtest,
  handleChangePrice,
  savePrice,
  deleteTest,
}) => {
  let { test_id } = useParams();
  const [show_edit_price, setShowEditPrice] = useState(false);

  useEffect(() => {
    getReferenceTest(test_id);
    getTest(test_id);
  }, [test_id]);

  const handleEditPrice = e => {
    e.preventDefault();
    setShowEditPrice(!show_edit_price);
  };

  const handleSaveReference = ev => {
    ev.preventDefault();
    saveReference(reference.reference_test);
  };

  const handleDeleteTest = ev => {
    ev.preventDefault();
    deleteTest(test.id, test.tests[0].id, test.tests[0]);
  };

  let { reference_test, test, error, loading } = reference;
  let data_reference = null;

  const type_references = [
    {
      value: 'generic',
      label: 'Generico',
    },
    {
      value: 'limit',
      label: 'Limite',
    },
    {
      value: 'range',
      label: 'Rango',
    },
    {
      value: 'ranges',
      label: 'Rangos Con Orden',
    },
    {
      value: 'ranges_text',
      label: 'Rangos sin Orden',
    },
    {
      value: 'text_pos_neg',
      label: 'Positivo o Negativo',
    },
    {
      value: 'text',
      label: 'Texto',
    },
    {
      value: 'text_line',
      label: 'Linea de Texto',
    },
    {
      value: 'text_add',
      label: 'Agregar Varias Opciones',
    },
  ];

  if (reference_test) {
    if (reference_test.subtests) {
      data_reference = (
        <Grid container item xs={12}>
          {/* // <div className="cell medium-12"> */}
          {reference_test.subtests.map((subtest, index) => {
            let show_unity = null;
            const with_unities = ['limit', 'range', 'ranges', 'ranges_text'];

            if (with_unities.includes(subtest.type)) {
              show_unity = (
                <Grid item contaier xs={12}>
                  {/* <div className="cell medium-12 grid-x grid-padding-x"> */}
                  <Grid item xs={4}>
                    <span className="text-right">Unidad:</span>
                  </Grid>
                  <Grid item xs={8}>
                    <input
                      type="text"
                      name="unity"
                      data-type="unity"
                      data-subtest_id={subtest.id}
                      value={subtest.unity}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              );
            }

            return (
              <Grid item container xs={12} className="margin_bottom">
                <Grid item xs={12} container className=" boder_bottom">
                  <Grid item xs={1}>
                    Grupo: &nbsp;
                  </Grid>
                  {/* <div className="cell medium-1 text-right"></div> */}
                  {/* <div className="cell medium-4"> */}
                  <Grid item xs={4}>
                    <SubtestNameEdit
                      Key={'group_' + subtest.id}
                      subtest_name={subtest.group}
                      name="group"
                      subtest_id={subtest.id}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={1} className="text-right">
                    Prueba: &nbsp;{' '}
                  </Grid>
                  <Grid item xs={4}>
                    <SubtestNameEdit
                      Key={'name_' + subtest.id}
                      subtest_name={subtest.name}
                      name="name"
                      subtest_id={subtest.id}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={1} className="text-right">
                    <a
                      data-type="delete_subtest"
                      data-subtest_id={subtest.id}
                      data-index={index}
                      data-reference_id={reference_test.id}
                      onClick={handleInputChange}
                    >
                      Eliminar &nbsp;
                    </a>
                  </Grid>
                </Grid>
                <Grid item container xs={12} className="padding_top">
                  <Grid item xs={6}>
                    <select
                      className="cell medium-12"
                      data-type="subtest"
                      name="type"
                      data-subtest_id={subtest.id}
                      onChange={handleInputChange}
                    >
                      <option defaultValue="none">
                        Selecciona uno tipo de referencia
                      </option>
                      {type_references.map(type_ref => {
                        let res = null;
                        if (type_ref.value === subtest.type) {
                          res = (
                            <option value={type_ref.value} selected="true">
                              {type_ref.label}
                            </option>
                          );
                        } else {
                          res = (
                            <option value={type_ref.value}>{type_ref.label}</option>
                          );
                        }
                        return res;
                      })}
                    </select>
                  </Grid>
                  <Grid item xs={6}>
                    {show_unity}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {subtest.references && subtest.references.length > 0
                    ? subtest.references.map((x_reference, index) => {
                        return (
                          <ReferenceEdit
                            subtest_id={subtest.id}
                            {...x_reference}
                            type={subtest.type}
                            refe_id={reference_test.id}
                            refe_index={index}
                            handleInputChange={handleInputChange}
                          />
                        );
                      })
                    : ''}
                  <CreateReferenceItem
                    key={subtest.id}
                    handleInputChange={handleInputChange}
                    subtest_id={subtest.id}
                    with_unities={with_unities}
                    subtest_type={subtest.type}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    } else {
      data_reference = <Grid xs={12}> </Grid>;
    }
  }

  let create_subtest_html = null;

  if (reference_test && reference_test.id) {
    create_subtest_html = (
      <CreateSubtest
        handleAddSubtest={handleAddSubtest}
        reference_id={reference_test.id}
      />
    );
  }

  let edit_price_html = null;
  if (show_edit_price) {
    edit_price_html = (
      <TestPriceEdit
        handleEditPrice={handleEditPrice}
        test={test}
        handleChangePrice={handleChangePrice}
        savePrice={savePrice}
        getTest={getTest}
      />
    );
  }

  let header_test = null;
  if (reference_test) {
    header_test = reference_test.name;

    if (test && test.tests[0]) {
      header_test += ': ' + test.tests[0].price + ' Bs';
    }
  }

  return (
    <Grid container direction="row" spacing={5}>
      <Grid
        item
        container
        xs={12}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <h5 className="header_text ">
            <strong>Prueba {header_test}</strong>
            &nbsp;
            {!show_edit_price ? (
              <a onClick={handleEditPrice}>
                <i className="fas fa-pen" />
              </a>
            ) : (
              ''
            )}
          </h5>
          {edit_price_html}
        </div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteTest}
          className="button float-right"
        >
          <i className="fas fa-trash-alt" />
          &nbsp; Eliminar Prueba
        </Button>
      </Grid>
      {data_reference}
      <Grid item container xs={12} spacing={3}>
        {create_subtest_html}
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveReference}
            className="button float-left"
          >
            <i className="far fa-save fa-lg" />
            &nbsp; Guardar Referencias
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Link color="primary" className="button secondary" to="/home">
            Cancelar
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Reference;
