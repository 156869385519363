import React, { Component } from 'react'

class FileUpload extends Component {

    constructor(props) {
     super(props);
       this.state = {
         uploadStatus: false
       }
     this.handleUploadImage = this.handleUploadImage.bind(this);
    }


    handleUploadImage(ev) {
        ev.preventDefault()

        const data = new FormData();
        data.append('file', this.uploadInput.files[0]);

        const { uploadImage } = this.props

        uploadImage(data)
    }

    render() {
        return(
          <div className="container">
              <div className="form-group">
                <input className="form-control"  ref={(ref) => { this.uploadInput = ref; }} type="file" onChange={this.handleUploadImage} />
              </div>
          </div>
        )
    }
}

export default FileUpload
