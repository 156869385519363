import React, { useState, useEffect } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Portlet, PortletContent } from '../../../../Components';

import { CashReport } from '../';

import styles from './style';

const ListCashFlow = ({ classes, className, data }) => {
  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent>
        <PerfectScrollbar>
          {data.length > 0 &&
            data.map((item, index) => (
              <>
                <CashReport {...item} />
              </>
            ))}
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};

ListCashFlow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  cashflows: PropTypes.array.isRequired,
  totalExpected: PropTypes.number.isRequired,
  openAmount: PropTypes.number,
};

ListCashFlow.defaultProps = {
  cashflows: [],
  totalExpected: 0,
};

export default withStyles(styles)(ListCashFlow);
