import {
  ADD_REFERENCE,
  ADD_REFERENCE_ERROR,
  REFERENCE_LOADING,
  CHANGE_RANGE_REFERENCE,
  CHANGE_RANGES_REFERENCE,
  CHANGE_RANGES_INSIDE_REFERENCE,
  CHANGE_SUBTEST,
  ADD_REFERENCE_ITEM,
  DELETE_REFERENCE_ITEM,
  DELETE_SUBTEST,
  ADD_EMPTY_RANGE_ITEM,
  ADD_TEST,
  ADD_REFERENCE_SUBTEST,
  CHANGE_TEST,
} from "../Actions/reference";

const INITIAL_STATE = {
  reference_test: null,
  test: null,
  loading: null,
  error: null,
};
const reference = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_TEST:
      return {
        ...state,
        test: {
          ...state.test,
          tests: [
            {
              ...state.test.tests[0],
              [action.payload.name]: action.payload.value,
            },
          ],
        },
      };
    case ADD_TEST:
      return {
        ...state,
        test: action.payload,
      };
    case ADD_REFERENCE:
      return {
        ...state,
        reference_test: action.payload,
        loading: false,
      };
    case ADD_REFERENCE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    case REFERENCE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_RANGE_REFERENCE:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };
    case CHANGE_RANGES_REFERENCE:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };

    case CHANGE_RANGES_INSIDE_REFERENCE:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };
    case CHANGE_SUBTEST:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };
    case ADD_REFERENCE_ITEM:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };
    case DELETE_REFERENCE_ITEM:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };
    case DELETE_SUBTEST:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.filter(
            (s, index) => index != action.payload.index
          ),
        },
      };
    case ADD_EMPTY_RANGE_ITEM:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: state.reference_test.subtests.map((s) =>
            subtest(s, action)
          ),
        },
      };
    case ADD_REFERENCE_SUBTEST:
      return {
        ...state,
        reference_test: {
          ...state.reference_test,
          subtests: [...state.reference_test.subtests, action.payload],
        },
      };
  }

  return state;
};

const subtest = (state, action) => {
  switch (action.type) {
    case CHANGE_RANGE_REFERENCE:
      if (state.id != action.payload.subtest_id) {
        return state;
      }
      return {
        ...state,
        references: state.references.map((reference, i) => {
          if (i != action.payload.refe_index) {
            return reference;
          }
          return {
            ...reference,
            [action.payload.name]: action.payload.value,
          };
        }),
      };
    case CHANGE_RANGES_REFERENCE:
      if (state.id != action.payload.subtest_id) {
        return state;
      }
      return {
        ...state,
        references: state.references.map((reference, i) => {
          if (i != action.payload.refe_index) {
            return reference;
          }
          return {
            ...reference,
            [action.payload.name]: {
              ...reference[action.payload.name],
              [action.payload.key]: action.payload.value,
            },
          };
        }),
      };
    case CHANGE_RANGES_INSIDE_REFERENCE:
      if (state.id != action.payload.subtest_id) {
        return state;
      }

      return {
        ...state,
        references: state.references.map((reference, i) => {
          if (i != action.payload.refe_index) {
            return reference;
          }

          return {
            ...reference,
            ranges: reference.ranges.map((range, index) => {
              if (index != action.payload.range_index) {
                return range;
              }

              let value_x = null;
              if (action.payload.key == "text") {
                value_x = action.payload.value;
              } else if (action.payload.key == "color") {
                value_x = action.payload.value;
              } else {
                if (range.values) {
                  value_x = range.values.map((val, x_index) => {
                    if (Number.parseInt(action.payload.name) != x_index) {
                      return val;
                    }
                    return action.payload.value;
                  });
                } else {
                  value_x = [null, null];
                  // value_x.splice(Number.parseInt(action.payload.name), 0, action.payload.value)
                  value_x[Number.parseInt(action.payload.name)] =
                    action.payload.value;
                  console.log(value_x);
                }
              }

              return {
                ...range,
                [action.payload.key]: value_x,
              };
            }),
          };
        }),
      };

    case CHANGE_SUBTEST:
      if (state.id != action.payload.subtest_id) {
        return state;
      }

      if (action.payload.name == "type") {
        return {
          ...state,
          [action.payload.name]: action.payload.value,
          references: [],
        };
      }

      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case ADD_REFERENCE_ITEM:
      if (state.id != action.payload.subtest_id) {
        return state;
      }

      return {
        ...state,
        references: [
          ...state.references,
          {
            ...action.payload.data_ref,
          },
        ],
      };

    case DELETE_REFERENCE_ITEM:
      if (state.id != action.payload.subtest_id) {
        return state;
      }
      return {
        ...state,
        references: state.references.filter((reference, i) => {
          if (i != action.payload.refe_index) {
            return reference;
          }
        }),
      };
    case ADD_EMPTY_RANGE_ITEM:
      if (state.id != action.payload.subtest_id) {
        return state;
      }

      return {
        ...state,
        references: state.references.map((reference, i) => {
          if (i != action.payload.refe_index) {
            return reference;
          }

          if (reference.ranges) {
            return {
              ...reference,
              ranges: [...reference.ranges, {}],
            };
          } else {
            return {
              ...reference,
              ranges: [{}],
            };
          }
        }),
      };

    default:
      return state;
  }
};

export default reference;
