export default theme => ({
  root: {},
  tableRow: {
    height: '40px',
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px',
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
    cursor: 'pointer',
  },
  nameTextLine: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'line-through',
  },
  redBackground: {
    backgroundColor: theme.palette.danger.deleted,
    textDecoration: 'line-through',
  },
  Line: {
    textDecoration: 'line-through',
  },
  unChecked: {
    color: '#f44336',
  },
  checked: {
    color: '#2196F3',
  },
  warningFactura: {
    color: '#FFEB3B',
  },
});
