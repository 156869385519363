export default theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    color: theme.palette.text.title,
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
});
