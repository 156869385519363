import React, { useState, useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {
  withStyles,
  CircularProgress,
  Typography,
  Snackbar,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';

// Shared layouts
import Layout from '../../layout';

import { Branch, ModalEditMethod } from '../CashFlow/Components';
import { Portlet, PortletContent, CustomSnackBar } from '../../Components';

// Shared services
import {
  getOutgoings,
  createOutgoing,
  deleteOutgoingById,
  updateMethodOutgoing,
} from '../../Services/FinanceService';

// Component styles
import styles from './styles';
import { BillsTable, CreateBill } from './Components';

const RegisterBills = ({ classes, lab }) => {
  const [isLoading, setLoading] = useState(false);
  const [totalExpected, setTotalExpected] = useState(0);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [registerOpenAmount, setRegisterOpenAmount] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [dataMethod, setdataMethod] = useState({});

  const [statusSuccessMessage, setStatusSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [statusErrorMessage, setErrorMessage] = useState(false);
  const branch_stored = localStorage.getItem('branch');
  const [branch_id, setBranchId] = useState(Number(branch_stored) || 1);

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setRegisterOpenAmount(null);
    setBranchId(value);
    localStorage.setItem('branch', value);
  };

  const showSuccess = message => {
    setStatusSuccessMessage(true);
    setMessage(message);
  };

  const showError = message => {
    setErrorMessage(true);
    setMessage(message);
  };

  const handleSave = async (category, totalExpense, description, payment_method) => {
    try {
      await createOutgoing(
        category,
        description,
        totalExpense,
        branch_id,
        payment_method,
      );
      showSuccess('Se agrego exitosamente');
      fetchData(branch_id);
    } catch (err) {
      showError('No se pudo registrar');
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatusSuccessMessage(false);
    setErrorMessage(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getOutgoings();
      setLoading(false);
      setData(res.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteOutgoing = id => async () => {
    await deleteOutgoingById(id);
    fetchData();
  };

  const openModalEditMethod = (id, method) => {
    setdataMethod({ id, method });
  };

  useEffect(() => {
    if (dataMethod.method) {
      setOpenModal(true);
    }
  }, [dataMethod]);

  const renderCashFlow = () => {
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    return (
      <BillsTable
        outgoings={data}
        showActions={true}
        handleDeleteOutgoing={handleDeleteOutgoing}
        openModal={openModalEditMethod}
      />
    );
  };

  const saveEditMethod = async method => {
    try {
      await updateMethodOutgoing(dataMethod.id, method);
      showSuccess('Se agrego exitosamente');
      fetchData(branch_id);
    } catch (err) {
      showError('No se pudo registrar');
    }
  };

  return (
    <Layout>
      <div className={classes.root}>
        <Portlet>
          <PortletContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Branch
                  lab={lab}
                  handleBranch={handleBranch}
                  branch_id={branch_id}
                />
              </Grid>
            </Grid>
          </PortletContent>
        </Portlet>

        <CreateBill handleSave={handleSave} />
        <div className={classes.content}>{renderCashFlow()}</div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={statusSuccessMessage}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <CustomSnackBar
          variant="success"
          className={classes.margin}
          message={message}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={statusErrorMessage}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <CustomSnackBar
          variant="error"
          className={classes.margin}
          message={message}
        />
      </Snackbar>
      <ModalEditMethod
        openModal={openModal}
        setOpenModal={setOpenModal}
        method_payment={dataMethod?.method}
        handleSave={saveEditMethod}
      />
    </Layout>
  );
};

RegisterBills.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegisterBills);
