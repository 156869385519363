import { AppToaster } from '../Components/Toaster';
import { Intent } from '@blueprintjs/core';
import { UpdatePatient, getPerson, GetPatient } from '../Services/PatientService';

import config from '../config.json';

import {
  UpdateRequest,
  LoadProcessingTestsService,
} from '../Services/RequestTestService';

import { getDoctorById } from '../Services/DoctorService';
import {
  AddProcessingTestsAction,
  AddProcessingTestsErrorAction,
  AddPatientTestProcessingAction,
  AddPatientRequestAction,
  CleanProcessingTests,
} from './index';

import {
  GeneratePdf,
  GeneratePdfLogo,
  CompleteStatusRequestTest,
  SaveTestValues,
  CompleteStatusRequestTestLegacy,
} from '../Services/RequestTestService';

const report_url = config.report_service;

export const UpdateRequestTestAction = (id_request, key, value) => {
  return dispatch => {
    UpdateRequest(id_request, key, value)
      .then(res => {
        if (value) {
          getDoctorById(value).then(res_x => {
            console.log(res_x.data);
            dispatch(UpdateDoctor(res_x.data.user));
          });
        } else {
          dispatch(UpdateDoctor(null));
        }
        AppToaster.show({
          message: 'Se actualizo exitosamente',
          timeout: 3000,
          intent: Intent.SUCCESS,
        });
      })
      .catch(err => {
        AppToaster.show({
          message: 'Error al actualizar datos',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      });
  };
};

export const UpdateDoctor = doctor => {
  return {
    type: 'UPDATE_DOCTOR_REQUEST',
    payload: doctor,
  };
};

export const DeleteRequestAction = request_id => {
  return dispatch => {
    UpdateRequest(request_id, 'status', 'deleted')
      .then(res => {
        dispatch(LoadProcessingRequest());
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const LoadProcessingRequestLegacy = () => {
  return dispatch => {
    LoadProcessingTestsService()
      .then(res => {
        dispatch(AddProcessingTestsAction(res.data));

        res.data.map(request_test => {
          GetPatient(request_test.patient_id).then(res =>
            dispatch(AddPatientTestProcessingAction(res.data)),
          );
        });
      })
      .catch(err => {
        dispatch(AddProcessingTestsErrorAction(err));
      });
  };
};

export const LoadProcessingRequest = () => {
  return dispatch => {
    LoadProcessingTestsService()
      .then(res => {
        dispatch(AddProcessingTestsAction(res.data));

        res.data.map(request_test => {
          getPerson(request_test.patient_id).then(res =>
            dispatch(AddPatientTestProcessingAction(res.data)),
          );
        });
      })
      .catch(err => {
        dispatch(AddProcessingTestsErrorAction(err));
      });
  };
};

export const UpdatePatientAction = (id_patient, patient) => {
  return dispatch => {
    UpdatePatient(id_patient, patient)
      .then(res => {
        dispatch(AddPatientRequestAction(res.data));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const saveGeneratePdfButton = (
  request_test,
  test_value_references,
  categories,
  general_obs,
  lab,
  print,
  internal_code,
) => {
  return async dispatch => {
    try {
      const res = await SaveTestValues(
        request_test.request_test.id,
        test_value_references,
        general_obs,
        internal_code,
      );

      let data = {
        request_test: request_test.request_test,
        test_values_by_category: res.data.data[0].test_values_by_categories,
        created_at: res.data.data[0].created_at,
        categories,
        general_obs,
        logo: lab.picture,
        signature: lab.signature,
        lab: lab,
        short_url: '',
        internal_code: internal_code,
        v2: true,
      };

      if (request_test.request_test && request_test.request_test.shortid) {
        data['short_url'] =
          'https://www.laztin.com/verify/' + request_test.request_test.shortid;
      }

      if (print === 'pdf_logo') {
        const res = await GeneratePdfLogo(data);
        window.open(report_url + '/' + res.data);
      } else if (print === 'pdf') {
        const res = await GeneratePdf(data);
        window.open(report_url + '/' + res.data);
      } else if (print === 'both') {
        const res_logo = await GeneratePdfLogo(data);
        window.open(report_url + '/' + res_logo.data);
        const res_without_logo = await GeneratePdf(data);
        window.open(report_url + '/' + res_without_logo.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
};

export const saveGeneratePdfButtonLegacy = (
  request_test,
  test_value_references,
  categories,
  general_obs,
  lab,
  print,
  internal_code,
) => {
  return async dispatch => {
    try {
      const res = await CompleteStatusRequestTestLegacy(
        request_test.request_test.id,
        test_value_references,
        general_obs,
        internal_code,
      );

      let data = {
        request_test: request_test.request_test,
        test_values_by_category: res.data.data[0].test_values_by_categories,
        created_at: res.data.data[0].created_at,
        categories,
        general_obs,
        logo: lab.picture,
        signature: lab.signature,
        lab: lab,
        short_url: '',
        internal_code: internal_code,
      };

      if (request_test.request_test && request_test.request_test.shortid) {
        data['short_url'] =
          'https://www.laztin.com/verify/' + request_test.request_test.shortid;
      }

      if (print === 'pdf_logo') {
        const res = await GeneratePdfLogo(data);
        window.open(report_url + '/' + res.data);
      } else if (print === 'pdf') {
        const res = await GeneratePdf(data);
        window.open(report_url + '/' + res.data);
      } else if (print === 'both') {
        const res_logo = await GeneratePdfLogo(data);
        window.open(report_url + '/' + res_logo.data);
        const res_without_logo = await GeneratePdf(data);
        window.open(report_url + '/' + res_without_logo.data);
      }

      dispatch(CleanProcessingTests());

      const res_x = await LoadProcessingTestsService();
      dispatch(AddProcessingTestsAction(res_x.data));

      res_x.data.map(request_test => {
        getPerson(request_test.patient_id).then(res =>
          dispatch(AddPatientTestProcessingAction(res.data)),
        );
      });
    } catch (error) {
      console.log('error', error);
    }
  };
};
