import { connect } from 'react-redux';
import ProfileLab from '../Components/ProfileLab';
import { UploadImage, UploadImageSignature } from '../Services/LabService';
import {
  GetLabInfo,
  ChangeLab,
  SaveLabAction,
  ChangeLabSignature,
} from '../Actions/lab';

const mapStateToProps = state => {
  return {
    profile_lab: state.profile_lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadLabInfo: () => {
      dispatch(GetLabInfo());
    },

    uploadImage: data => {
      UploadImage(data)
        .then(res => {
          let name = 'picture';
          let value = res.data['result']['files']['file'][0]['name'];
          dispatch(ChangeLab({ name, value }));
        })
        .catch(err => {
          console.log(err);
        });
    },

    uploadSignature: data => {
      UploadImageSignature(data)
        .then(res => {
          let name = 'signature';
          let value = res.data['result']['files']['file'][0]['name'];
          dispatch(ChangeLabSignature({ name, value }));
        })
        .catch(err => {
          console.log(err);
        });
    },

    changeInput: (name, value) => {
      dispatch(ChangeLab({ name, value }));
    },

    saveLab: lab => {
      dispatch(SaveLabAction(lab));
    },
  };
};

const ProfileLabContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileLab);

export default ProfileLabContainer;
