import React, { useState } from 'react';
import {
  withStyles,
  Select as SelectUI,
  MenuItem as MenuItemUI,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core';

const ModalPaymentBalance = ({
  openModal,
  setOpenModal,
  patient_full_name,
  handleSave,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [method, setMethod] = useState('cash');
  const [amount, setAmount] = useState(0);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleMethod = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setMethod(value);
  };

  const handleSaveButton = async () => {
    setDisabled(true);
    setOpenModal(false);

    if (amount > 0) {
      await handleSave(method, parseFloat(amount));
    }
    setDisabled(false);
  };

  const handleAmount = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setAmount(value);
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Actualizar Saldo de {patient_full_name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormControl fullWidth variant="outlined">
            <InputLabel id="payment_method">Metodo de Pago</InputLabel>
            <SelectUI
              labelId="payment_method"
              id="payment-select"
              label="Payment Method"
              name="payment_method"
              onChange={handleMethod}
              defaultValue={method}
            >
              <MenuItemUI key="cash" value="cash">
                Efectivo
              </MenuItemUI>
              <MenuItemUI key="qr" value="qr">
                QR
              </MenuItemUI>
              <MenuItemUI key="bank_transfer" value="bank_transfer">
                Transferencia Bancaria
              </MenuItemUI>
            </SelectUI>
            <TextField
              id="amount"
              label="Monto"
              name="amount"
              type="number"
              style={{ marginTop: 10 }}
              value={amount}
              onChange={handleAmount}
              variant="outlined"
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={disabled}
          onClick={handleSaveButton}
          color="primary"
          autoFocus
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPaymentBalance;
