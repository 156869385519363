import { ADD_LAB_INFO, CHANGE_LAB, CHANGE_LAB_SIGNATURE } from '../Actions/lab';

const INITIAL_STATE = {
  lab: null,
};

const profile_lab = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_LAB_INFO:
      return {
        ...state,
        lab: action.payload,
      };
    case CHANGE_LAB:
      return {
        ...state,
        lab: {
          ...state.lab,
          [action.payload.name]: action.payload.value,
        },
      };
    case CHANGE_LAB_SIGNATURE:
      return {
        ...state,
        lab: {
          ...state.lab,
          [action.payload.name]: action.payload.value,
        },
      };
  }
  return state;
};

export default profile_lab;
