import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";

const ObservationInput = ({ obs, handleObs }) => {
  const handleInput = (ev) => {
    const observation = ev.target.value;
    handleObs(observation);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          value={obs}
          onChange={handleInput}
          name="general_obs"
          id="general_obsx"
          label="Observaciones Generales"
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default ObservationInput;
