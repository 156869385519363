import React, { useState, useMemo, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Chip } from '@material-ui/core';

const SearchTest = ({
  selectedTests,
  setSelectedTests,
  categories,
  updateTestsSelected,
  removeTesById,
}) => {
  const flatTests = categories.flatMap(test =>
    test.tests.map(t => ({
      ...t,
      category: test.name,
    })),
  );

  const handleDelete = option => () => {
    const newSelectedTests = [...selectedTests];
    const index = newSelectedTests.indexOf(option);

    if (index > -1) {
      newSelectedTests.splice(index, 1);
      setSelectedTests(newSelectedTests);
      removeTesById(option.id);
    }
  };

  return (
    <Autocomplete
      multiple
      options={flatTests}
      value={selectedTests}
      getOptionLabel={option => `${option.name} - ${option.price} Bs`}
      groupBy={option => option.category}
      onChange={(event, newValue) => {
        const res = [];
        newValue.every(item => {
          res.push(item);
          return true;
        });
        updateTestsSelected(newValue);

        setSelectedTests(res);
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            onDelete={handleDelete(option)}
          />
        ))
      }
      renderInput={params => (
        <TextField {...params} label="Buscar Prueba" variant="outlined" />
      )}
    />
  );
};

export default SearchTest;
