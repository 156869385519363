import { connect } from 'react-redux'
import AddTest from '../Components/AddTest'
import { SaveTest, CreateReference } from '../Services/TestService'
import { AppToaster } from "../Components/Toaster"
import { Intent } from "@blueprintjs/core"

const mapStateToProps = (state) => {
  return {
    new_test: state.new_test
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveTest: (e) => {
        let {category_id, name, price} = e.target.dataset
        let data = {
            name,
            price: Number.parseFloat(price)
        }

        SaveTest(category_id, data).then(res => {
            CreateReference(res.data.id, res.data.name).then(res_x => {
                AppToaster.show({message: 'Se agrego exitosamente la prueba', timeout:3000, intent: Intent.SUCCESS })
            }).catch(err => {
                console.log(err)
                AppToaster.show({message: 'Error al guardar prueba', timeout:3000, intent: Intent.DANGER })
            })

        }).catch(err => {
            console.log(err)
            AppToaster.show({message: 'Error al guardar prueba', timeout:3000, intent: Intent.DANGER })
        })
    }
  }
}

const AddTestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTest)

export default AddTestContainer;
