import React, { PropTypes } from 'react';
import TypeLimit from './TypeLimit';
import TypeRanges from './TypeRanges';
import TypeRange from './TypeRange';
import TypeTextPosNeg from './TypeTextPosNeg';
import TypeText from './TypeText';
import TypeTextAdd from './TypeTextAdd';
import TypeGeneric from './TypeGeneric';
import { Grid } from '@material-ui/core';

const TestType = React.memo(({ type, ...data }) => {
  let test = null;
  switch (type) {
    case 'generic':
      test = <TypeGeneric {...data} />;
      break;
    case 'limit':
      test = <TypeLimit {...data} />;
      break;
    case 'ranges':
      test = <TypeRanges {...data} />;
      break;
    case 'ranges_text':
      test = <TypeRanges {...data} />;
      break;
    case 'range':
      test = <TypeRange {...data} />;
      break;
    case 'text_pos_neg':
      test = <TypeTextPosNeg {...data} />;
      break;
    case 'text':
      test = <TypeText {...data} />;
      break;
    case 'text_line':
      test = <TypeText {...data} />;
      break;
    case 'text_add':
      test = <TypeTextAdd {...data} />;
      break;
    default:
      test = <div></div>;
  }

  return (
    <Grid container item xs={12}>
      {test}
    </Grid>
  );
});

export default TestType;
