import React, { PropTypes } from 'react';
import { Grid } from '@material-ui/core';
import TestType from './TestType';

const Test = React.memo(
  ({
    sex,
    years_old,
    subtests,
    test_id,
    id,
    onInputChange,
    setTestData,
    handleInputSave,
  }) => (
    <Grid item xs={12} container spacing={3}>
      {subtests.map(test_type => (
        <TestType
          key={test_type.id}
          years_old={years_old}
          sex={sex}
          {...test_type}
          test_id={test_id}
          onInputChange={onInputChange}
          setTestData={setTestData}
          handleInputSave={handleInputSave}
        />
      ))}
    </Grid>
  ),
);

export default Test;
