import { connect } from 'react-redux';
import ReportFinance from '.';

const mapStateToProps = state => {
  return {
    lab: state.profile_lab.lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const ReportFinanceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportFinance);

export default ReportFinanceContainer;
