import React, { PropTypes, Component } from 'react';
import { Radio, RadioGroup } from "@blueprintjs/core"
import './CreateReferenceItem.css'

class CreateReferenceItem extends Component  {

    constructor(props) {
        super(props)
        this.state = {
          show_inputs: false,
          sex: null,
          years_old_init: '',
          years_old_end: ''
        }
    }

    handleSexChange = (ev) => {
        this.setState({
            sex: ev.currentTarget.value
        })
    }

    handleInputInit = (e) => {

        if (e.target.value) {
            this.setState({
                years_old_init: Number.parseInt(e.target.value)
            })
        }

    }

    handleInputEnd = (e) => {
        if (e.target.value) {
            this.setState({
                years_old_end: Number.parseInt(e.target.value)
            })
        }

    }

    setEmpty = () => {
        this.setState({
            sex: null,
            years_old_init: '',
            years_old_end: ''
        })
    }


    render () {
        let show_inputs = null
        let {handleInputChange, subtest_id, with_unities, subtest_type} = this.props
        with_unities.push('generic')

        if (with_unities.includes(subtest_type)) {
            show_inputs = <div className="cell medium-12 grid-x grid-padding-x bg_create_reference">
                <div className="cell medium-6">
                    <RadioGroup
                        inline={true}
                        label="Sexo"
                        onChange={this.handleSexChange}
                        selectedValue={this.state.sex}
                    >
                        <Radio name="sex" label="Mujer" value="female" />
                        <Radio name="sex" label="Varon" value="male" />
                        <Radio name="sex" label="Varon y Mujer" value="unisex" />

                    </RadioGroup>
                </div>
                <div className="cell medium-6">
                    <div className="cell medium-12">
                        <h6 className="header_text">
                            Rangos de Edad
                        </h6>
                    </div>
                    <div className="cell medium-12 grid-x ">
                        <span className="cell medium-3 text-center">
                            Edad Inicial
                        </span>
                        <input type="number" className="cell medium-3" placeholder="Inical" onChange={this.handleInputInit} value={this.state.years_old_init} />
                        <span className="cell medium-3 text-center">
                            Edad Final
                        </span>
                        <input type="number" className="cell medium-3" placeholder="Final" onChange={this.handleInputEnd} value={this.state.years_old_end} />
                    </div>
                </div>
                <div className="cell medium-12 grid-x grid-margin-x">
                    <a  className="cell medium-12 button float-left" data-type="add_ref"
                        data-subtest_id={subtest_id}
                        data-sex={this.state.sex}
                        data-years_init={this.state.years_old_init}
                        data-years_end={this.state.years_old_end}
                        onClick={(e) => {
                            handleInputChange(e)
                            this.setEmpty()
                        }}>
                        Crear Nueva Referencia
                    </a>

                </div>
            </div>
        } else {
            show_inputs = null
        }

        return (
            <div className="cell medium-12 grid-x grid-padding-x">
                <div className="cell medium-12 grid-x grid-padding-x">
                    { show_inputs }
                </div>
            </div>
        )
    }
}

export default CreateReferenceItem
