import React, { useState } from 'react';
import { TextField, Grid, Typography, Button } from '@material-ui/core';
import './TypeTextAdd.css';

const TypeTextAdd = React.memo(({ ...data }) => {
  const [value, setValue] = useState(data.value);
  const handleInput = ev => {
    ev.preventDefault();
    const newValue = ev.target.value;
    setValue(newValue);
  };

  const handleClickAdd = () => {
    data.handleInputSave('text_add', data.test_id, data.id, value);
    setValue('');
  };

  let vals = '';
  if (data.values) {
    vals = data.values.map(x => {
      return (
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          key={x}
          item
          xs={12}
        >
          <Grid item>
            <Typography>{x}</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={ev => {
                data.handleInputSave('textadd_delete', data.test_id, data.id, x);
              }}
              variant="contained"
              color="primary"
            >
              <i className="far fa-trash-alt"></i>
            </Button>
          </Grid>
        </Grid>
      );
    });
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
      spacing={3}
      item
      xs={12}
    >
      <Grid item xs={4}>
        <Typography align="right">{data.name}</Typography>
      </Grid>
      <Grid item xs={8} container spacing={3}>
        {vals}
        <Grid item xs={8}>
          <TextField
            id={data.test_id + '_text_add'}
            type="text"
            value={value}
            onChange={handleInput}
            variant="outlined"
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={4}>
          <Button onClick={handleClickAdd} variant="contained" color="primary">
            Agregar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default TypeTextAdd;
