import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Grid, Divider } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Test from '../test';

const Category = ({ prices, onTestClick }) => {
  return (
    <>
      {prices.map(category => (
        <div key={category.id} className="item">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className="title_category"
          >
            {category.name}

            <Link to={`/add_test/${category.id}`}>
              <IconButton variant="contained" color="primary">
                <AddBoxIcon fontSize="small" />
              </IconButton>
            </Link>
          </Grid>

          {category.tests.map(test => {
            return <Test key={test.id} {...test} onClick={onTestClick} />;
          })}
        </div>
      ))}
    </>
  );
};

export default memo(Category);
