//react version 16 material ui to show a list of patients with name, phone and dni
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: '10px',
    overflowY: 'auto',
    maxHeight: '370px',
  },
  patient_list_item: {
    padding: '0px',
    borderTop: '1px solid #000',
  },
}));

const ListPatient = ({ patients, setPatient, setEditPatient }) => {
  const classes = useStyles();

  const handlePatient = patient => {
    setPatient(patient);
  };

  const handleEditPatient = patient => {
    setEditPatient(patient);
  };

  const getSecondaryText = patient => {
    const active_phone = patient._phones.find(phone => phone.is_active);
    return (
      <>
        <Typography display="inline" color="secondary">
          Teléfono: {active_phone && active_phone.number ? active_phone.number : ''}
        </Typography>
        {'  '}
        Documento de Identidad: {patient.dni}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6">Escoger Paciente</Typography>
      <List component="nav" aria-label="list patients">
        {patients.length > 0
          ? patients.map(patient => (
              <ListItem
                className={classes.patient_list_item}
                button
                key={patient._id}
                onClick={() => handlePatient(patient)}
              >
                <ListItemText
                  primary={`${patient.names} ${patient.last_names}`}
                  secondary={getSecondaryText(patient)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handleEditPatient(patient)}
                    edge="end"
                    aria-label="edit user"
                  >
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : 'No se encontraron pacientes'}
      </List>
    </div>
  );
};

export default ListPatient;
