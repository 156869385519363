import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Select,
  MenuItem,
  Grid,
  Button,
  FormControl,
  InputLabel,
} from '@material-ui/core';
// Shared layouts
import Layout from '../../layout';
import { Portlet, PortletContent } from '../../Components';
import { Branch } from '../CashFlow/Components';

import { ListReport } from './Components';
import {
  getCashierReport,
  downloadCashierReport,
} from '../../Services/FinanceService';
import styles from './styles';

const ReportCash = ({ lab }) => {
  const [data, setData] = useState([]);
  const branch_stored = localStorage.getItem('branch');

  const [branch_id, setBranchId] = useState(branch_stored || null);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const endYear = new Date().getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      setData([]);
      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 1);
      lastDayOfMonth.setMilliseconds(-1);

      const date_init = firstDayOfMonth.toISOString().split('T')[0];
      const date_end = lastDayOfMonth.toISOString();
      const result = await getCashierReport(date_init, date_end, branch_id);
      setData(result.data);
    };
    fetchData();
  }, [month, year, branch_id]);

  const handleBranch = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setBranchId(value);
    localStorage.setItem('branch', value);
  };

  const handleDownload = async () => {
    downloadCashierReport(month + 1, year, branch_id);
  };

  return (
    <Layout>
      <Portlet>
        <PortletContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <h1>Reporte de caja</h1>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="branch">Mes</InputLabel>
                <Select
                  value={month}
                  onChange={event => setMonth(event.target.value)}
                  fullWidth
                >
                  <MenuItem value={0}>January</MenuItem>
                  <MenuItem value={1}>February</MenuItem>
                  <MenuItem value={2}>March</MenuItem>
                  <MenuItem value={3}>April</MenuItem>
                  <MenuItem value={4}>May</MenuItem>
                  <MenuItem value={5}>June</MenuItem>
                  <MenuItem value={6}>July</MenuItem>
                  <MenuItem value={7}>August</MenuItem>
                  <MenuItem value={8}>September</MenuItem>
                  <MenuItem value={9}>October</MenuItem>
                  <MenuItem value={10}>November</MenuItem>
                  <MenuItem value={11}>December</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="branch">Año</InputLabel>
                <Select
                  value={year}
                  onChange={event => setYear(event.target.value)}
                  fullWidth
                >
                  {Array.from(
                    { length: endYear - 2023 + 1 },
                    (_, i) => i + 2023,
                  ).map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <Branch lab={lab} handleBranch={handleBranch} branch_id={branch_id} />
            </Grid>
            <Grid item md={3}>
              <Button
                disabled={branch_id ? false : true}
                variant="contained"
                color="primary"
                onClick={handleDownload}
              >
                Descargar Reporte
              </Button>
            </Grid>
          </Grid>
        </PortletContent>
      </Portlet>

      <ListReport data={data} />
    </Layout>
  );
};

export default withStyles(styles)(ReportCash);
