import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
} from '@material-ui/core';
import { updatePerson } from '../../Services/PatientService';
import { addPhone } from '../../Services/UserService';
import { Toast } from '../components';
const PatientEdit = ({
  names,
  last_names,
  sex,
  birthdate,
  dni,
  _phones,
  id,
  cancel,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      names: names,
      last_names: last_names,
      phone: _phones.filter(phone => phone.number && phone.is_active)[0].number,
      dni: dni,
      sex: sex,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
    },
  });

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCancel = () => {
    cancel();
  };

  const onSubmit = async data => {
    const aux_phone = data.phone;

    delete data.phone;

    try {
      await updatePerson(id, data);
      await addPhone(id, aux_phone);

      setOpenSuccess(true);
      window.location.reload();
    } catch (error) {
      setOpenError(true);
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label="Nombres"
          {...register('names', { required: true })}
          variant="outlined"
          size="small"
          fullWidth
        />

        {errors.names && <span>Es requerido los nombres del paciente</span>}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label="Apellidos"
          {...register('last_names', { required: true })}
          variant="outlined"
          size="small"
          fullWidth
        />
        {errors.last_names && <span>Es requerido los apellidos del paciente</span>}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label="Phone"
          {...register('phone', { required: true })}
          variant="outlined"
          size="small"
          fullWidth
        />
        {errors.phone && (
          <span>Es requerido el numero de documento de identidad</span>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          id="input_dni"
          label="Documento de Identidad"
          variant="outlined"
          size="small"
          fullWidth
          {...register('dni', { required: false })}
        />
        {errors.dni && <span>Es requerido el telefono del paciente</span>}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Controller
          name="sex"
          control={control}
          defaultValue={sex}
          rules={{ required: true }}
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel value="female" control={<Radio />} label="Mujer" />
              <FormControlLabel value="male" control={<Radio />} label="Varon" />
            </RadioGroup>
          )}
        />
        {errors.gender && <span>Es requerido el Genero del paciente</span>}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          id="input-with-icon-textfield"
          {...register('birthdate', { required: true })}
          label="Fecha de Nacimiento"
          name="birthdate"
          type="date"
          variant="outlined"
          size="small"
          fullWidth
        />
        {errors.birthdate && (
          <span>Es requerido la fecha nacimiento del paciente</span>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Guardar
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleCancel}
        >
          Cancelar
        </Button>
      </Grid>
      <Toast
        statusOpen={openSuccess}
        setOpen={setOpenSuccess}
        type="success"
        message="Se actualizo exitosamente."
      />
      <Toast
        statusOpen={openError}
        setOpen={setOpenError}
        type="error"
        message="Hubo un error al aztualizar datos del Paciente"
      />
    </Grid>
  );
};

export default PatientEdit;
