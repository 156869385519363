import React, { memo, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select as SelectUI,
  MenuItem as MenuItemUI,
  InputLabel,
} from '@material-ui/core';
import './styles.css';

const Summary = ({
  total,
  test_list,
  cleanInputs,
  saveRequestTest,
  tests_selected,
}) => {
  const [discount, setDiscount] = useState('');
  const [on_account, setOn_account] = useState('');
  const [amount_received, setAmount_received] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [payment_method, setPaymentMethod] = useState('cash');

  const getBalance = () => {
    let res = getTotal();
    if (on_account && on_account > 0) {
      res = res - on_account;
    }

    return res;
  };

  const getReturnCash = () => {
    let res = 0;

    if (
      on_account &&
      on_account > 0 &&
      amount_received &&
      Number.parseFloat(amount_received) > Number.parseFloat(on_account)
    ) {
      res = amount_received - on_account;
    } else {
      if (amount_received > 0 && amount_received > getTotal()) {
        res = amount_received - getTotal();
      }
    }
    return res;
  };

  const getTotal = () => {
    let res = 0;
    if (discount && discount > 0) {
      res = total - discount;
    } else {
      res = total;
    }

    return res;
  };

  const onInputChange = ev => {
    ev.preventDefault();
    const { name, value } = ev.target;

    switch (name) {
      case 'discount':
        setDiscount(value);
        break;
      case 'on_account':
        setOn_account(value);
        break;
      case 'total_amount':
        setAmount_received(value);
        break;

      default:
        break;
    }
  };

  const handleClean = ev => {
    ev.preventDefault();
    setDiscount('');
    setAmount_received('');
    setOn_account('');
    cleanInputs(true);
  };

  const handleSave = async ev => {
    setDisableSave(true);
    ev.preventDefault();

    await saveRequestTest(tests_selected, on_account, discount, payment_method);
    setDiscount('');
    setAmount_received('');
    setOn_account('');
    setDisableSave(false);
  };

  const handlePaymentMethod = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setPaymentMethod(value);
  };

  let show = (
    <Grid item xs={12}>
      Selecciona uno o mas pruebas
    </Grid>
  );

  const getColumnsTestsGrid = test_list => {
    const column1 = test_list.slice(0, 10);
    const column2 = test_list.slice(10, 20);
    const column3 = test_list.slice(20);

    return (
      <Grid container item xs={12} md={8} spacing={1}>
        {[column1, column2, column3].map((column, columnIndex) => (
          <Grid item xs={4} key={columnIndex}>
            {column.map((test, i) => (
              <Grid item xs={12} key={i}>
                {i + columnIndex * 10 + 1 + '.- ' + test.name}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  };

  if (test_list.length > 0) {
    show = (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <strong>{test_list.length} Pruebas</strong>
        </Grid>
        {getColumnsTestsGrid(test_list)}
        <Grid container item xs={12} md={4} spacing={4}>
          <Typography variant="h4">
            <strong>Calculadora para cambio</strong>
          </Typography>
          <Grid item xs={12}>
            <Typography variant="h4">
              Cambio: {getReturnCash()}
              Bs
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="Monto Recibido"
              label="Monto Recibido"
              name="total_amount"
              type="number"
              value={amount_received}
              onChange={onInputChange}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      className=" footer"
      spacing={3}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
      >
        <Typography variant="h3">
          <strong>Total {getTotal()} Bs</strong>
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        xs={12}
      >
        {show}
      </Grid>

      <Grid container item xs={12} md={7} spacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Precio Total:{total} Bs</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4">
              Saldo: {on_account ? `${getBalance()} Bs` : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
        <Grid container item xs={12} md={4}>
          <Grid item xs={12}>
            <TextField
              id="Descuento"
              label="Descuento"
              name="discount"
              value={discount}
              type="number"
              onChange={onInputChange}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4}>
          <Grid item xs={12}>
            <TextField
              id="A Cuenta"
              label="A Cuenta"
              name="on_account"
              type="number"
              value={on_account}
              onChange={onInputChange}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="payment_method">Metodo de Pago</InputLabel>
            <SelectUI
              labelId="payment_method"
              id="payment-select"
              value={payment_method}
              label="Payment Method"
              name="payment_method"
              onChange={handlePaymentMethod}
              defaultValue={payment_method}
            >
              <MenuItemUI key="cash" value="cash">
                Efectivo
              </MenuItemUI>
              <MenuItemUI key="qr" value="qr">
                QR
              </MenuItemUI>
              <MenuItemUI key="bank_transfer" value="bank_transfer">
                Transferencia Bancaria
              </MenuItemUI>
            </SelectUI>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        item
        xs={12}
        md={5}
        spacing={3}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={handleSave}
            color="primary"
            disabled={disableSave}
            fullWidth
          >
            Guardar Orden
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" onClick={handleClean}>
            Limpiar Campos
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(Summary);
