import React, { Component, PropTypes } from 'react';

class SubtestNameEdit extends Component {

    constructor(props) {
        super(props)
        this.state = {
          showInput: false
        }
    }

    handleClickShowInput = (e) => {
        console.log('click')
        e.preventDefault()

       this.setState({
         showInput: true,
       })
     }

     handleClickHideInput = (e) => {
        console.log('click')
        e.preventDefault()

        this.setState({
          showInput: false,
        })
      }

    render () {

        let {showInput} = this.state
        const { subtest_name, handleInputChange, subtest_id, name} = this.props


        let html = null

        if (showInput) {
            html = <div className="cell medium-12 grid-x grid-padding-x">
                <h6 className="header_text   cell medium-6">
                    { subtest_name.charAt(0).toUpperCase() + subtest_name.slice(1)} &nbsp;
                </h6>

                <input type="text"  className="cell medium-5"
                       data-type="subtest" name={name}
                       data-subtest_id={subtest_id}
                       value={subtest_name}
                       onChange={handleInputChange} />

                <div className="cell medium-1">
                    <a href="" onClick={this.handleClickHideInput}>
                        <i className="fas fa-times"></i>
                    </a>
                </div>
            </div>

        } else {
            html = <h6 className="header_text   cell medium-6">
                { subtest_name.charAt(0).toUpperCase() + subtest_name.slice(1)} &nbsp;
                <a href=""  onClick={this.handleClickShowInput}>
                    <i  className="fas fa-pen"></i>
                </a>
            </h6>
        }

        return (<div className="cell medium-12 grid-x">
                { html }
                </div>)
    }
}

export default SubtestNameEdit
