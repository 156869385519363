import {
  ADD_TESTS_RESULTS,
  ADD_TESTS_RESULTS_ERROR,
  ADD_PATIENT_RESULT,
  ADD_ZERO_RESULTS
} from "../Actions/tests_results";

const INITIAL_STATE = {
  results: [],
  meta: {},
  error: null
};
const tests_results = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TESTS_RESULTS:
      return {
        ...state,
        results: action.payload.data,
        meta: action.payload.meta
      };
    case ADD_TESTS_RESULTS_ERROR:
      return {
        ...state,
        error: action.error
      };
    case ADD_ZERO_RESULTS:
      return {
        ...state,
        results: [],
        meta: {}
      };
    case ADD_PATIENT_RESULT:
      let res_x = state.results.map(test_result => {
        if (test_result.request_test.patient_id === action.patient.id) {
          return {
            ...test_result,
            patient: action.patient
          };
        } else {
          return test_result;
        }
      });

      return {
        ...state,
        results: res_x
      };
    default:
      return INITIAL_STATE;
  }
};

export default tests_results;
