import axios from 'axios'
import config from '../config.json'
const lab_url = config.lab_service

export const SearchTestsResults = () => {
    const lab_id = localStorage.getItem('enterprise_token')

    let criteria =  {
        params: {
            filter: {
                where: {
                    lab_id: lab_id
                },
                order: 'created_at DESC',
            }
        }
    }

    return axios.get(lab_url + '/test_results', criteria)
}

export const SearchByName = (full_name) => {
    const lab_id = localStorage.getItem('enterprise_token')
    let data = {
        params: {
            full_name,
            lab_id: lab_id
        }
    };

    return axios.get(lab_url + '/test_results/searchByPatient', data);

}
