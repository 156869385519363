const INITIAL_STATE = {
    authenticated: false, error: ''
}
const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "AUTHENTICATED_USER":
            return {
                ...state,
                authenticated: true,
                error: ''
            }
        case "AUTHENTICATION_ERROR":
            return {
                ...state,
                authenticated: false,
                error: action.payload
            }
        case "UNAUTHENTICATED":
            return {
                ...state,
                authenticated: false,
                error: ''
            }
    }

    return state
}

export default user
