import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { getCashflowByReportId } from '../../../../Services/FinanceService';
import { CashFlowTable } from '../../../CashFlow/Components';

const CashReport = ({
  open_amount,
  expected_cash_amount,
  bank_amount,
  expected_amount,
  variance_amount,
  close_amount,
  close_at,
  notes,
  branch_id,
  id,
  cash_flows_ids,
}) => {
  const [cashflows, setCashflows] = useState([]);
  const fetchData = async () => {
    try {
      if (cash_flows_ids.length > 0) {
        const res = await getCashflowByReportId(id);
        setCashflows(res.data.cashflows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1" gutterBottom>
          Fecha: {moment(close_at).format('YYYY-MM-DD HH:mm')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1" gutterBottom>
          Sucursal: {branch_id}{' '}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        Caja Monto Inicial: {open_amount}
      </Grid>
      <Grid item xs={12} md={12}>
        Transferencia Bancaria Total:{bank_amount}
      </Grid>
      <Grid item xs={12} md={12}>
        Monto Efectivo Esperado:{expected_cash_amount}
      </Grid>
      <Grid item xs={12} md={12}>
        Monto en Efectivo en Caja:{close_amount}
      </Grid>
      <Grid item xs={12} md={12}>
        Monto Variacion:{variance_amount}
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="body1" style={{ width: '50%' }}>
          Notas: {notes}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        Monto Total + transferencias bancarias:{expected_amount}
      </Grid>
      {cashflows.length > 0 && (
        <CashFlowTable
          openAmount={open_amount}
          cashflows={cashflows}
          showActions={false}
        />
      )}
    </Grid>
  );
};

export default CashReport;
