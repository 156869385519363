import axios from 'axios';
import config from '../config.json';
import { getImageURL } from '../shared/lib';

const url_storage = getImageURL();
const url = config.user_service;

export const GetLab = () => {
  axios.defaults.headers.common['X-Access-Token'] = localStorage.getItem('token');
  const lab_id = localStorage.getItem('enterprise_token');

  return axios.get(url + '/laboratories/' + lab_id);
};

export const UploadImage = data => {
  return axios.post(url_storage + '/containers/logos/upload', data);
};

export const UploadImageSignature = data => {
  return axios.post(url_storage + '/containers/signature/upload', data);
};

export const SaveLab = lab => {
  const lab_id = localStorage.getItem('enterprise_token');
  delete lab.id;
  return axios.patch(url + '/laboratories/' + lab_id, lab);
};
