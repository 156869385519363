import axios from 'axios'
import config from '../config.json'

const url = config.lab_service

export const SaveTest = (category_id, data) => {
    return axios.post(url + '/categories/' + category_id + '/test', data)
}

export const CreateReference = (test_id, name) => {
    const data = {
        test_id,
        name
    }

    return axios.post(url + '/lab_test_references', data)
}
