import { connect } from 'react-redux'
import { LoginSuccessAction, UnauthenticatedAction } from '../Actions'
import { GetLabInfo } from '../Actions/lab'

import { ValidateToken } from '../Services/UserService'

import App from '../Components/App'

const mapStateToProps = (state) => {
    return {
        profile_lab: state.profile_lab,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadLabInfo: () => {
            dispatch(GetLabInfo())
        },
    }
}

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(App)

export default AppContainer
