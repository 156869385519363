import axios from 'axios';
import config from '../config.json';

const url_finance = config.finance_service;
const url_lab = config.lab_service;

export const getCashIn = () => {
  // const lab_id = localStorage.getItem('enterprise_token');

  //return axios.get();
  return 1;
};

export const getPaymentInfo = async branch_id => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      lab_id: lab_id,
    },
  };

  if (branch_id) {
    criteria.params.branch_id = branch_id;
  }

  return axios.get(url_lab + '/payment_infos/paymentInfoList', criteria);
};

export const getCashierReport = async (date_init, date_end, branch_id) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      filter: {
        where: {
          enterprise_id: lab_id,
          is_close: true,
          close_at: {
            between: [date_init, date_end],
          },
        },
        order: ['close_at DESC'],
      },
    },
  };

  if (branch_id) {
    criteria.params.filter.where.branch_id = branch_id;
  }

  return axios.get(url_finance + '/close_cash_report', criteria);
};

export const downloadCashierReport = async (month, year, branch_id) => {
  const lab_id = localStorage.getItem('enterprise_token');
  const params = {
    params: {
      month,
      year,
      branch_id,
      doc_type: 'pdf',
      enterprise_id: lab_id,
    },
    responseType: 'blob',
  };

  const response = await axios.get(
    url_finance + '/close_cash_report/download',
    params,
  );
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/pdf' }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  link.click();
};

export const payBalance = async (
  paymentId,
  balancePaymentReceivedBy,
  balancePaymentReceiverId,
  patientFullName,
  paymentMethod,
  amount,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const data = {
    lab_id,
    payment_id: paymentId,
    balance_payment_received_by: balancePaymentReceivedBy,
    balance_payment_receiver_id: balancePaymentReceiverId,
    patient_full_name: patientFullName,
    payment_method: paymentMethod,
    amount,
  };

  return axios.post(url_lab + '/payment_infos/payBalance', data);
};

export const updatePaymentInfo = async (paymentId, key, value) => {
  const data = {
    [key]: value,
  };
  return axios.patch(url_lab + '/payment_infos/' + paymentId, data);
};

export const searchByFullName = async fullName => {
  const lab_id = localStorage.getItem('enterprise_token');

  const data = {
    params: {
      lab_id,
      full_name: fullName,
    },
  };
  return axios.get(url_lab + '/payment_infos/searchByName', data);
};

export const createExpense = async (
  type,
  category,
  totalAmount,
  description,
  branch_id,
  payment_method,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const data = {
    type: type,
    total_amount: totalAmount,
    description,
    enterprise_id: lab_id,
    category,
    user_id: getUserId(),
    branch_id,
  };
  data.payment_method = payment_method;

  return axios.post(url_finance + '/cash_flow', data);
};

export const updateMethodCashFlow = async (id, method) => {
  const data = {
    payment_method: method,
  };
  return updateCashFlowById(id, data);
};

//delete a cash flow by id

export const deleteCashFlow = async id => {
  const data = {
    deleted: true,
  };
  return updateCashFlowById(id, data);
};

export const updateCashFlowById = async (id, data) => {
  return axios.patch(url_finance + '/cash_flow/' + id, data);
};

export const getCashFlow = async branch_id => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      filter: {
        where: {
          enterprise_id: lab_id,
          status_close: false,
          branch_id,
        },
        order: ['created_at DESC'],
      },
    },
  };
  return axios.get(url_finance + '/cash_flow', criteria);
};

export const getCashFlowByIds = async ids => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      filter: {
        where: {
          enterprise_id: lab_id,
          _id: {
            inq: ids,
          },
        },
        order: ['created_at DESC'],
        limit: 20,
      },
    },
  };
  return axios.get(url_finance + '/cash_flow', criteria);
};

export const getCashflowByReportId = async id => {
  return axios.get(url_finance + `/close_cash_report/${id}/cashflows`);
};

export const closeCashRegister = async (totalAmount, branch_id, notes) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const data = {
    total_amount: totalAmount,
    enterprise_id: lab_id,
    branch_id,
    notes,
    user_id: getUserId(),
    name: getName(),
  };
  return axios.post(url_finance + '/close_cash_report/close', data);
};

export const openCashRegister = async (openAmount, branch_id) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const data = {
    open_by_user_id: getUserId(),
    enterprise_id: lab_id,
    open_amount: openAmount,
    branch_id,
    open_by: getName(),
  };

  return axios.post(url_finance + '/close_cash_report', data);
};

export const getOpenCashier = async branch_id => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      filter: {
        where: {
          enterprise_id: lab_id,
          is_close: false,
          branch_id,
        },
      },
    },
  };

  return axios.get(url_finance + '/close_cash_report', criteria);
};

const getUserId = () => {
  // return localStorage.getItem('userId');
  return '5abe499eda771218814def3c';
};

const getName = () => {
  return 'carla';
};

export const getOutgoings = async () => {
  const lab_id = localStorage.getItem('enterprise_token');

  const criteria = {
    params: {
      filter: JSON.stringify({
        where: {
          enterprise_id: lab_id,
        },
        order: 'created_at DESC',
        limit: 100,
      }),
    },
  };

  return axios.get(url_finance + '/outgoings', criteria);
};

export const createOutgoing = async (
  category,
  description,
  totalExpense,
  branch_id,
  payment_method,
) => {
  const lab_id = localStorage.getItem('enterprise_token');

  const data = {
    category,
    total_amount: totalExpense,
    description,
    enterprise_id: lab_id,
    user_id: getUserId(),
    branch_id,
    payment_method,
  };

  return axios.post(url_finance + '/outgoings', data);
};

export const deleteOutgoingById = async id => {
  return axios.delete(url_finance + '/outgoings/' + id);
};

export const updateMethodOutgoing = async (id, method) => {
  const data = {
    payment_method: method,
  };
  return axios.patch(url_finance + '/outgoings/' + id, data);
};
