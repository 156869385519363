import { connect } from 'react-redux';
import Reference from '../Components/Reference';
import {
  GetReferenceTest,
  ChangeRangesReference,
  ChangeRangesInsideReference,
  ChangeRangeReference,
  ChangeSubtest,
  AddReferenceItem,
  DeleteReferenceItem,
  DeleteSubtest,
  AddEmptyRangeItem,
  CreateReferenceSubtest,
  GetTest,
  ChangeTest,
  SaveSubtest,
} from '../Actions/reference';
import { SavePrice, DeleteTest } from '../Services/ReferenceService';

import { AppToaster } from '../Components/Toaster';
import { Intent } from '@blueprintjs/core';

const mapStateToProps = state => {
  return {
    reference: state.reference,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReferenceTest: test_id => {
      dispatch(GetReferenceTest(test_id));
    },
    getTest: test_id => {
      dispatch(GetTest(test_id));
    },
    handleInputChange: e => {
      e.preventDefault();
      let { value, name } = e.currentTarget;
      let { subtest_id, refe_index, type } = e.currentTarget.dataset;

      switch (type) {
        case 'generic':
          dispatch(ChangeRangeReference({ value, name, subtest_id, refe_index }));
          break;
        case 'limit':
          value = Number.parseFloat(value);
          dispatch(ChangeRangeReference({ value, name, subtest_id, refe_index }));
          break;

        case 'range':
          value = Number.parseFloat(value);
          dispatch(ChangeRangeReference({ value, name, subtest_id, refe_index }));
          break;

        case 'ranges_text':
        case 'ranges':
          let { key } = e.currentTarget.dataset;

          if (key === 'value' || key === 'values') {
            value = Number.parseFloat(value);
          }

          if (name === 'init' || name === 'end') {
            dispatch(
              ChangeRangesReference({
                value,
                name,
                subtest_id,
                refe_index,
                key,
              }),
            );
          } else {
            let { range_index } = e.currentTarget.dataset;
            dispatch(
              ChangeRangesInsideReference({
                value,
                name,
                subtest_id,
                refe_index,
                range_index,
                key,
              }),
            );
          }

          break;
        case 'unity':
          dispatch(ChangeSubtest({ value, name, subtest_id }));
          break;
        case 'subtest':
          dispatch(ChangeSubtest({ value, name, subtest_id }));
          break;
        case 'add_ref':
          let { sex, years_init, years_end } = e.currentTarget.dataset;
          let data_x = {};

          if (sex) {
            data_x['sex'] = sex;
          }

          if (years_init && years_end) {
            data_x['years'] = [
              Number.parseInt(years_init),
              Number.parseInt(years_end),
            ];
          }

          dispatch(AddReferenceItem({ subtest_id, data_ref: data_x }));
          break;
        case 'delete_ref':
          dispatch(DeleteReferenceItem({ subtest_id, refe_index }));
          break;
        case 'delete_subtest':
          let { index, reference_id } = e.currentTarget.dataset;
          dispatch(DeleteSubtest(reference_id, subtest_id, index));
          break;
        case 'add_item_ranges':
          dispatch(AddEmptyRangeItem({ subtest_id, refe_index }));
          break;
      }
    },
    handleAddSubtest: e => {
      let { name, group, reference_id } = e.currentTarget.dataset;

      if (name) {
        let subtest = {
          name: name,
          references: [],
        };

        if (group) {
          subtest['group'] = group;
        }

        console.log('subtest', subtest);
        dispatch(CreateReferenceSubtest(subtest));
      }
    },

    handleChangePrice: e => {
      let { name, value } = e.currentTarget;

      if (name === 'price' && value !== '') {
        value = Number.parseFloat(value);
      }

      dispatch(ChangeTest({ name, value }));
    },
    savePrice: (category_id, test_id, test) => {
      if (typeof test.price === 'number') {
        SavePrice(category_id, test_id, test)
          .then(res => {
            AppToaster.show({
              message: 'Se modifico exitosamente',
              timeout: 3000,
              intent: Intent.SUCCESS,
            });
          })
          .catch(err => {
            AppToaster.show({
              message: 'Error al modificar',
              timeout: 3000,
              intent: Intent.DANGER,
            });
          });
      } else {
        AppToaster.show({
          message: 'Ingrese por favor el precio de la prueba',
          timeout: 3000,
          intent: Intent.DANGER,
        });
      }
    },
    saveReference: reference_test => {
      dispatch(SaveSubtest(reference_test));
    },
    deleteTest: (category_id, test_id) => {
      DeleteTest(category_id, test_id);
    },
  };
};

const ReferenceContainer = connect(mapStateToProps, mapDispatchToProps)(Reference);

export default ReferenceContainer;
