import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';

class TypeRanges extends PureComponent {
  render() {
    const { ...data } = this.props;

    let refs_with_year = data.references.filter(ref => ref.years);
    let refs_without_year = data.references.filter(ref => !ref.years);

    let ref_html = null;
    let showed = false;

    if (refs_with_year.length > 0) {
      for (let ref of refs_with_year) {
        if (ref.sex === data.sex || ref.sex === 'unisex') {
          if (data.years_old >= ref.years[0] && data.years_old <= ref.years[1]) {
            ref_html = (
              <Grid>
                <Grid>
                  {ref.init != null
                    ? ref.init.text + ' ' + ref.init.value + ' ' + data.unity
                    : ''}
                </Grid>
                {ref.ranges.map((range, i) => {
                  return (
                    <Grid key={i}>
                      {range.text +
                        ' ' +
                        range.values[0] +
                        ' - ' +
                        range.values[1] +
                        ' ' +
                        data.unity}{' '}
                    </Grid>
                  );
                })}
                <Grid>
                  {ref.end != null
                    ? ref.end.text + ' ' + ref.end.value + ' ' + data.unity
                    : ''}
                </Grid>
              </Grid>
            );
            showed = true;
          }
        }
      }
    }

    if (!showed) {
      for (let ref of refs_without_year) {
        if (ref.sex === data.sex || ref.sex === 'unisex') {
          ref_html = (
            <Grid>
              <Grid>
                {ref.init != null
                  ? ref.init.text + ' ' + ref.init.value + ' ' + data.unity
                  : ''}
              </Grid>
              {ref.ranges.map((range, i) => {
                return (
                  <Grid key={i}>
                    {range.text +
                      ' ' +
                      range.values[0] +
                      ' - ' +
                      range.values[1] +
                      ' ' +
                      data.unity}{' '}
                  </Grid>
                );
              })}
              <Grid>
                {ref.end != null
                  ? ref.end.text + ' ' + ref.end.value + ' ' + data.unity
                  : ''}
              </Grid>
            </Grid>
          );
          showed = true;
        }
      }
    }

    return (
      <Grid container xs={12} className="boder_bottom_text">
        <Grid item xs={4}>
          {data.name}
        </Grid>
        <Grid item xs={3}>
          {data.value}
        </Grid>
        <Grid item xs={5}>
          {ref_html}
        </Grid>
      </Grid>
    );
  }
}

export default TypeRanges;
