import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { List, ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import config from '../config.json';
import './Menu.css';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const url_storage = config.storage_service;

const Menu = ({ lab }) => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState(location.pathname);

  useEffect(() => {
    setCurrentUrl(location.pathname);
  }, [location.pathname]);

  return (
    <List>
      {lab && lab.picture && (
        <img
          className="logo"
          src={url_storage + '/containers/logos/download/' + lab.picture}
          alt="Logo"
        />
      )}
      {!(lab && lab.version && lab.version == 2) && (
        <Link to="/homeLegacy">
          <ListItem
            button
            className={currentUrl === '/homeLegacy' ? 'currentUrl' : ''}
          >
            <ListItemIcon>
              <i className="fas fa-plus"></i>
            </ListItemIcon>
            <ListItemText primary="Cotizar Pruebas Antiguo" />
          </ListItem>
        </Link>
      )}
      <Link to="/newHome">
        <ListItem button className={currentUrl === '/newHome' ? 'currentUrl' : ''}>
          <ListItemIcon>
            <i className="fas fa-plus"></i>
          </ListItemIcon>
          <ListItemText primary="Cotizar Pruebas" />
        </ListItem>
      </Link>

      {!(lab && lab.version && lab.version == 2) && (
        <Link to="/processingLegacy">
          <ListItem
            button
            className={currentUrl === '/processingLegacy' ? 'currentUrl' : ''}
          >
            <ListItemIcon>
              <i className="fas fa-edit"></i>
            </ListItemIcon>
            <ListItemText primary="Informar Resultados Antiguo" />
          </ListItem>
        </Link>
      )}
      <Link to="/newProcessing">
        <ListItem
          button
          className={currentUrl === '/newProcessing' ? 'currentUrl' : ''}
        >
          <ListItemIcon>
            <i className="fas fa-edit"></i>
          </ListItemIcon>
          <ListItemText primary="Informar Resultados" />
        </ListItem>
      </Link>
      {/* <Link to="/listLegacy">
        <ListItem
          button
          className={currentUrl === '/listLegacy' ? 'currentUrl' : ''}
        >
          <ListItemIcon>
            <i className="fas fa-list"></i>
          </ListItemIcon>
          <ListItemText primary="Listados de Pacientes Antiguo" />
        </ListItem>
      </Link> */}
      <Link to="/newlist">
        <ListItem button className={currentUrl === '/newList' ? 'currentUrl' : ''}>
          <ListItemIcon>
            <i className="fas fa-list"></i>
          </ListItemIcon>
          <ListItemText primary="Listados de Pacientes" />
        </ListItem>
      </Link>
      {lab && lab.finance && (
        <>
          <Link to="/paymentInfo">
            <ListItem
              button
              className={currentUrl === '/paymentInfo' ? 'currentUrl' : ''}
            >
              <ListItemIcon>
                <i className="fa fa-wallet" aria-hidden="true"></i>
              </ListItemIcon>
              <ListItemText primary="Pagos Recibidos" />
            </ListItem>
          </Link>
          <Link to="/cashflow">
            <ListItem
              button
              className={currentUrl === '/cashflow' ? 'currentUrl' : ''}
            >
              <ListItemIcon>
                <i className="fa fa-money-bill" aria-hidden="true"></i>
              </ListItemIcon>
              <ListItemText primary="Caja" />
            </ListItem>
          </Link>
          <Link to="/reportCashflow">
            <ListItem
              button
              className={currentUrl === '/reportCashflow' ? 'currentUrl' : ''}
            >
              <ListItemIcon>
                <i className="fa fa-book" aria-hidden="true"></i>
              </ListItemIcon>
              <ListItemText primary="Reporte Caja" />
            </ListItem>
          </Link>
          <Link to="/registerBills">
            <ListItem
              button
              className={currentUrl === '/registerBills' ? 'currentUrl' : ''}
            >
              <ListItemIcon>
                <i className="fa fa-book" aria-hidden="true"></i>
              </ListItemIcon>
              <ListItemText primary="Registro de Gastos General" />
            </ListItem>
          </Link>
          <Link to="/doctor-report">
            <ListItem
              button
              className={currentUrl === 'doctor-report' ? 'currentUrl' : ''}
            >
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary="Doctor Reportes" />
            </ListItem>
          </Link>
        </>
      )}
      <Link to="/createDoctor">
        <ListItem
          button
          className={currentUrl === '/createDoctor' ? 'currentUrl' : ''}
        >
          <ListItemIcon>
            <i className="fa fa-user-md" aria-hidden="true"></i>
          </ListItemIcon>
          <ListItemText primary=" Crear Doctor" />
        </ListItem>
      </Link>
      <Link to="/profileLab">
        <ListItem
          button
          className={currentUrl === '/profileLab' ? 'currentUrl' : ''}
        >
          <ListItemIcon>
            <i className="fas fa-flask"></i>
          </ListItemIcon>
          <ListItemText primary="Perfil Laboratorio" />
        </ListItem>
      </Link>
      <Link to="/logout">
        <ListItem button className={currentUrl === '/logout' ? 'currentUrl' : ''}>
          <ListItemIcon>
            <i className="fas fa-sign-in-alt"></i>
          </ListItemIcon>
          <ListItemText primary="Salir" />
        </ListItem>
      </Link>
    </List>
  );
};

export default Menu;
