const INITIAL_STATE = {
    newPatient: { patient:null, error: null},
}
const patients = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_PATIENT":
            return {
                ...state,
                newPatient: { patient: action.patient, error:null}
            }
        case "ADD_PATIENT_ERROR":
            return {
                ...state,
                newPatient: { patient:null, error: action.error}
            }
        default:
            return []
    }
}

export default patients
