import React from "react";

const RangesEdit = ({
  init,
  end,
  ranges,
  refe_id,
  subtest_id,
  refe_index,
  handleInputChange
}) => {
  let init_input = null;
  let end_input = null;
  let ranges_input = null;

  if (init) {
    let color_init = false;

    if (init.color) {
      if (init.color === "red") {
        color_init = true;
      }
    }

    init_input = (
      <div className="cell medium-12 grid-x grid-padding-x">
        <div className="cell medium-12 grid-x grid-padding-x">
          <span className="cell medium-5 text-left">Texto:</span>
          <span className="cell medium-2 text-left">Valor:</span>
          <span className="cell medium-2 text-left">color:</span>
        </div>
        <div className="cell medium-12 grid-x grid-margin-x">
          <input
            className="cell medium-5"
            type="text"
            placeholder="text"
            data-type="ranges"
            data-key="text"
            name="init"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            value={init.text}
            onChange={handleInputChange}
          />
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Inicial"
            data-type="ranges"
            data-key="value"
            name="init"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            value={init.value}
            onChange={handleInputChange}
          />
          <select
            className="cell medium-2"
            data-type="ranges"
            data-key="color"
            name="init"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          >
            <option defaultValue="none">Ninguno</option>
            <option value="red" selected={init.color}>
              Rojo
            </option>
          </select>
        </div>
      </div>
    );
  } else {
    init_input = (
      <div className="cell medium-12 grid-x grid-padding-x">
        <div className="cell medium-12 grid-x grid-padding-x">
          <span className="cell medium-5 text-left">Texto:</span>
          <span className="cell medium-2 text-left">Valor:</span>
          <span className="cell medium-2 text-left">color:</span>
        </div>
        <div className="cell medium-12 grid-x grid-margin-x">
          <input
            className="cell medium-5"
            type="text"
            placeholder="text"
            data-type="ranges"
            data-key="text"
            name="init"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          />
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Inicial"
            data-type="ranges"
            data-key="value"
            name="init"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          />
          <select
            className="cell medium-2"
            data-type="ranges"
            data-key="color"
            name="init"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          >
            <option defaultValue="none">Ninguno</option>
            <option value="red">Rojo</option>
          </select>
        </div>
      </div>
    );
  }

  if (end) {
    let color_end = null;
    if (end.color) {
      if (end.color === "red") {
        color_end = true;
      }
    }

    end_input = (
      <div className="cell medium-12 grid-x grid-padding-x">
        <div className="cell medium-12 grid-x grid-margin-x">
          <span className="cell medium-5 text-left">Texto:</span>
          <span className="cell medium-2 text-left">Valor:</span>
          <span className="cell medium-2 text-left">color:</span>
        </div>
        <div className="cell medium-12 grid-x grid-margin-x">
          <input
            className="cell medium-5"
            type="text"
            placeholder="text"
            data-type="ranges"
            data-key="text"
            name="end"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            value={end.text}
            onChange={handleInputChange}
          />
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Inicial"
            data-type="ranges"
            data-key="value"
            name="end"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            value={end.value}
            onChange={handleInputChange}
          />
          <select
            className="cell medium-2"
            data-type="ranges"
            data-key="color"
            name="end"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          >
            <option defaultValue="none">Ninguno</option>
            <option value="red" selected={color_end}>
              Rojo
            </option>
          </select>
        </div>
      </div>
    );
  } else {
    end_input = (
      <div className="cell medium-12 grid-x grid-padding-x">
        <div className="cell medium-12 grid-x grid-margin-x">
          <span className="cell medium-5 text-left">Texto:</span>
          <span className="cell medium-2 text-left">Valor:</span>
          <span className="cell medium-2 text-left">color:</span>
        </div>
        <div className="cell medium-12 grid-x grid-margin-x">
          <input
            className="cell medium-5"
            type="text"
            placeholder="text"
            data-type="ranges"
            data-key="text"
            name="end"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          />
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Inicial"
            data-type="ranges"
            data-key="value"
            name="end"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          />
          <select
            className="cell medium-2"
            data-type="ranges"
            data-key="color"
            name="end"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            onChange={handleInputChange}
          >
            <option defaultValue="none">Ninguno</option>
            <option value="red">Rojo</option>
          </select>
        </div>
      </div>
    );
  }

  if (ranges) {
    ranges_input = ranges.map((item_x, index) => {
      let color_input = null;
      if (item_x.color) {
        if (item_x.color === "red") {
          color_input = true;
        }
      }

      let input_text = null;
      if (item_x.text) {
        input_text = (
          <input
            className="cell medium-5"
            type="text"
            placeholder="text"
            data-type="ranges"
            data-key="text"
            name="0"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            data-range_index={index}
            value={item_x.text}
            onChange={handleInputChange}
          />
        );
      } else {
        input_text = (
          <input
            className="cell medium-5"
            type="text"
            placeholder="text"
            data-type="ranges"
            data-key="text"
            name="0"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            data-range_index={index}
            onChange={handleInputChange}
          />
        );
      }

      let input_firts = null;
      let input_end = null;

      if (item_x.values) {
        input_firts = (
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Inicial"
            data-type="ranges"
            data-key="values"
            name="0"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            data-range_index={index}
            value={item_x.values[0]}
            onChange={handleInputChange}
          />
        );
        input_end = (
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Final"
            data-type="ranges"
            data-key="values"
            name="1"
            id={"end_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            data-range_index={index}
            value={item_x.values[1]}
            onChange={handleInputChange}
          />
        );
      } else {
        input_firts = (
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Inicial"
            data-type="ranges"
            data-key="values"
            name="0"
            id={"init_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            data-range_index={index}
            onChange={handleInputChange}
          />
        );
        input_end = (
          <input
            className="cell medium-2"
            type="number"
            placeholder="Rango Final"
            data-type="ranges"
            data-key="values"
            name="1"
            id={"end_" + refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            data-range_index={index}
            onChange={handleInputChange}
          />
        );
      }

      return (
        <div className="cell medium-12 grid-x" key={subtest_id + index}>
          <div className="cell medium-12 grid-x grid-padding-x">
            <span className="cell medium-5 text-left">Texto:</span>
            <span className="cell medium-2 text-left">Rango Inicial:</span>
            <span className="cell medium-2 text-left">Rango Final:</span>
            <span className="cell medium-3 text-left">color:</span>
          </div>
          <div className="cell medium-12 grid-x grid-margin-x">
            {input_text}
            {input_firts}
            {input_end}
            <select
              className="cell medium-3"
              data-type="ranges"
              data-key="color"
              name="ranges"
              id={"init_" + refe_id}
              data-subtest_id={subtest_id}
              data-refe_index={refe_index}
              data-range_index={index}
              onChange={handleInputChange}
            >
              <option defaultValue="none">Ninguno</option>
              <option value="red" selected={color_input}>
                Rojo
              </option>
            </select>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="cell large-12 grid-x">
      {init_input}
      <div className="cell medium-12 grid-x">
        {ranges_input}
        <a
          data-type="add_item_ranges"
          data-subtest_id={subtest_id}
          data-refe_index={refe_index}
          onClick={handleInputChange}
          className="cell medium-3 float-left"
        >
          Agregar Rango Intermedio
        </a>
        <br />
      </div>
      {end_input}
    </div>
  );
};

export default RangesEdit;
