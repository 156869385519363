import React, { PropTypes } from 'react'

const TestPriceEdit = ({handleEditPrice, test, handleChangePrice, savePrice, getTest}) => (
    <div className="cell medium-12 grid-x grid-padding-x">
        <div className="cell medium-2">
            Nombre
        </div>
        <div className="cell medium-2">
            <input type="text" placeholder="Nombre" name="name" value={test.tests[0].name} onChange={handleChangePrice} />
        </div>
        <div className="cell medium-2">Editar Precio:</div>
        <div className="cell medium-2">
        <input type="number" placeholder="Editar Precio" name="price" value={test.tests[0].price} onChange={handleChangePrice} />
        </div>
        <div className="cell medium-4 grid-x grid-margin-x">
            <a onClick={(e) =>{
                e.preventDefault()
                savePrice(test.id, test.tests[0].id, test.tests[0])
                handleEditPrice(e)
            }} className="cell medium-6 button"> Guardar</a>
            <a onClick={ (e) => {
                getTest(test.tests[0].id)
                handleEditPrice(e)
            }} className="cell medium-6 button secondary"> Cancelar</a>
        </div>
    </div>
)


export default TestPriceEdit
