import { LoadTestsResultsService } from '../Services/RequestTestService';
import { SearchByName } from '../Services/TestsResultsService';
import { GetPatient, getPerson } from '../Services/PatientService';

export const ADD_TESTS_RESULTS = 'ADD_TESTS_RESULTS';
export const ADD_TESTS_RESULTS_ERROR = 'ADD_TESTS_RESULTS_ERROR';
export const ADD_PATIENT_RESULT = 'ADD_PATIENT_RESULT';
export const ADD_ZERO_RESULTS = 'ADD_ZERO_RESULTS';

export const AddTestsResultsAction = (tests_results, meta) => {
  return {
    type: ADD_TESTS_RESULTS,
    payload: {
      data: tests_results,
      meta,
    },
  };
};

export const AddTestsResultsErrorAction = error => {
  return {
    type: ADD_TESTS_RESULTS_ERROR,
    error,
  };
};

export const AddPatientResultAction = patient => {
  return {
    type: ADD_PATIENT_RESULT,
    patient,
  };
};

export const addZeroResults = () => {
  return {
    type: ADD_ZERO_RESULTS,
  };
};

export const GetTestsResultsLegacy = page => {
  return dispatch => {
    LoadTestsResultsService(page)
      .then(res => {
        dispatch(AddTestsResultsAction(res.data.data, res.data.meta));
        res.data.data.map(result => {
          GetPatient(result.request_test.patient_id).then(res =>
            dispatch(AddPatientResultAction(res.data)),
          );
        });
      })
      .catch(err => {
        dispatch(AddTestsResultsErrorAction(err));
      });
  };
};

export const GetTestsResults = page => {
  return dispatch => {
    LoadTestsResultsService(page)
      .then(res => {
        dispatch(AddTestsResultsAction(res.data.data, res.data.meta));
        res.data.data.map(result => {
          getPerson(result.request_test.patient_id).then(res =>
            dispatch(AddPatientResultAction(res.data)),
          );
        });
      })
      .catch(err => {
        dispatch(AddTestsResultsErrorAction(err));
      });
  };
};

export const SearchPatient = full_name => {
  return dispatch => {
    if (full_name != '') {
      SearchByName(full_name)
        .then(res => {
          console.log(res);
          console.log(res.data.data.length);
          if (res.data.data.length > 0) {
            dispatch(AddTestsResultsAction(res.data.data, res.data.meta));
            console.log(res.data.data);

            res.data.data.map(result => {
              console.log('patient_id', result.request_test.patient_id);
              GetPatient(result.request_test.patient_id).then(res =>
                dispatch(AddPatientResultAction(res.data)),
              );
            });
          } else {
            dispatch(addZeroResults());
          }
        })
        .catch(err => {
          dispatch(AddTestsResultsErrorAction(err));
        });
    } else {
      LoadTestsResultsService(1)
        .then(res => {
          dispatch(AddTestsResultsAction(res.data.data, res.data.meta));
          res.data.data.map(result => {
            GetPatient(result.request_test.patient_id).then(res =>
              dispatch(AddPatientResultAction(res.data)),
            );
          });
        })
        .catch(err => {
          dispatch(AddTestsResultsErrorAction(err));
        });
    }
  };
};
