export const AddPatientAction = patient => {
  return {
    type: 'ADD_PATIENT',
    patient,
  };
};

export const AddPatientErrorAction = error => {
  return {
    type: 'ADD_PATIENT_ERROR',
    error,
  };
};

export const AddDoctorAction = doctor => {
  return {
    type: 'ADD_DOCTOR',
    doctor,
  };
};

export const AddDoctorErrorAction = error => {
  return {
    type: 'ADD_DOCTOR_ERROR',
    error,
  };
};

export const AddRequestTestAction = request_test => {
  return {
    type: 'ADD_REQUEST_TEST',
    request_test,
  };
};

export const AddRequestTestErrorAction = error => {
  return {
    type: 'ADD_REQUEST_TEST_ERROR',
    error,
  };
};

export const loadTestReferencesAction = test_references => {
  return {
    type: 'LOAD_TEST_REFERENCES',
    test_references,
  };
};

export const AddTestReferencesAction = tests_references => {
  return {
    type: 'ADD_TEST_REFERENCES',
    tests_references,
  };
};

export const AddTestReferenceAction = test_reference => {
  return {
    type: 'ADD_TEST_REFERENCE',
    test_reference,
  };
};

export const CleanTestReferenceAction = () => {
  return {
    type: 'CLEAN_TEST_REFERENCE',
  };
};

export const AddCategoryAction = categories => {
  return {
    type: 'ADD_CATEGORY',
    categories,
  };
};

export const AddTestValueAction = (test_id, subtest_id, value) => {
  return {
    type: 'ADD_TEST_VALUE',
    test_id,
    subtest_id,
    value,
  };
};

export const DeleteTestValueAction = (test_id, subtest_id, value) => {
  return {
    type: 'DELETE_TEST_VALUE',
    test_id,
    subtest_id,
    value,
  };
};

export const AddTestValuesAction = (test_id, subtest_id, value) => {
  return {
    type: 'ADD_TEST_VALUES',
    test_id,
    subtest_id,
    value,
  };
};

export const AddPricesAction = prices => {
  return {
    type: 'ADD_PRICES',
    prices,
  };
};

export const AddPricesErrorAction = error => {
  return {
    type: 'ADD_PRICES_ERROR',
    error,
  };
};

export const UncheckTest = id => {
  return {
    type: 'UNCHECK_TEST',
    id,
  };
};

export const CheckTest = id => {
  return {
    type: 'CHECK_TEST',
    id,
  };
};

export const ToggleTest = id => {
  return {
    type: 'CHECK_TEST',
    id,
  };
};

export const CheckTests = tests => {
  return {
    type: 'CHECK_TESTS',
    tests,
  };
};

export const ClearTest = () => {
  return {
    type: 'CLEAR',
  };
};

export const AddProcessingTestsAction = tests_processing => {
  return {
    type: 'ADD_PROCESSING_TESTS',
    tests_processing,
  };
};

export const AddProcessingTestsErrorAction = error => {
  return {
    type: 'ADD_PROCESSING_TESTS_ERROR',
    error,
  };
};

export const AddPatientTestProcessingAction = patient => {
  return {
    type: 'ADD_PATIENT_PROCESSING',
    patient,
  };
};

export const AddPatientRequestAction = patient => {
  return {
    type: 'ADD_PATIENT_REQUEST',
    patient,
  };
};

export const AddDoctorRequestAction = doctor => {
  return {
    type: 'ADD_DOCTOR_REQUEST',
    doctor,
  };
};

export const AddDoctorsAction = doctors => {
  return {
    type: 'ADD_DOCTORS',
    doctors,
  };
};

export const AddPatientTestCompleteAction = patient => {
  return {
    type: 'ADD_PATIENT_COMPLETE',
    patient,
  };
};

export const LoginSuccessAction = () => {
  return {
    type: 'AUTHENTICATED_USER',
  };
};

export const UnauthenticatedAction = () => {
  return {
    type: 'UNAUTHENTICATED',
  };
};

export const LoginTestErrorAction = err => {
  return {
    type: 'AUTHENTICATION_ERROR',
    payload: 'Invalid email or password',
  };
};

export const AddGeneralObsAction = obs => {
  return {
    type: 'ADD_GENERAL_OBS',
    general_obs: obs,
  };
};

export const CleanProcessingTests = () => {
  return {
    type: 'CLEAN_PROCESSING_TESTS',
  };
};
