import axios from 'axios';
import config from '../config.json';

const url = config.user_service;

export const loginService = (login, password) => {
  let data = {
    username: login,
    password: password,
  };

  return axios.post(url + '/users/login', data);
};

export const getMyLabToken = user_id => {
  let params = {
    params: {
      filter: {
        where: {
          user_id: user_id,
        },
        fields: { laboratory_id: true },
      },
    },
  };

  return axios.get(url + '/chemistry_laboratories', params);
};

export const ValidateToken = () => {
  const enterprise_token = localStorage.getItem('enterprise_token');
  axios.defaults.headers.common['X-Access-Token'] = localStorage.getItem('token');

  return axios.get(url + '/laboratories/' + enterprise_token);
};

//function to send full text search to user service

export const searchPatient = data => {
  let params = {
    params: {
      search: data,
    },
  };

  return axios.get(url + '/people/search', params);
};

//add function to add person
export const addPerson = data => {
  return axios.post(url + '/people', data);
};

export const updatePerson = (id, data) => {
  return axios.patch(url + '/people/' + id, data);
};

//add phone to person id
export const addPhone = (person_id, phone) => {
  let data = {
    person_id,
    number: phone,
  };

  return axios.post(url + '/phones/addPhone', data);
};

export const addPatient = async data => {
  try {
    const aux = {
      names: data.names,
      last_names: data.last_names,
      dni: data.dni,
      sex: data.gender,
      birthdate: data.birthdate,
    };
    const person = await addPerson(aux);
    const phone = await addPhone(person.data.id, data.phone);
    return person;
  } catch (error) {
    console.log(error);
  }
};

export const updatePatient = async (id, data) => {
  try {
    const aux = {
      names: data.names,
      last_names: data.last_names,
      dni: data.dni,
      sex: data.gender,
      birthdate: data.birthdate,
    };
    const person = await updatePerson(id, aux);
    const phone = await addPhone(id, data.phone);
    return person;
  } catch (error) {
    console.log(error);
  }
};

export const getPatientPhoneNumber = async patient_id => {
  try {
    const res = await getPatient(patient_id);
    const phone = res.data._phones.filter(
      phone => phone.number && phone.is_active,
    )[0].number;
    return phone;
  } catch (error) {
    console.log(error);
  }
};

export const getPatient = patient_id => {
  return axios.get(url + '/people/' + patient_id);
};
