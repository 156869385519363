const INITIAL_STATE = {
    newDoctor: { doctor:null, error: null},
    doctors: []
}
const doctors = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_DOCTOR":
            return {
                ...state,
                newDoctor: { doctor: action.doctor, error:null}
            }
        case "ADD_DOCTOR_ERROR":
            return {
                ...state,
                newDoctor: { doctor:null, error: action.error}
            }
        case "ADD_DOCTORS":
            return {
                ...state,
                doctors: [...action.doctors]
            }
        // default:
        //     return INITIAL_STATE
    }
    return state
}

export default doctors
