import { getMyLabToken } from "../../Services/UserService";

const saveSessionStorage = async (userId, token) => {

  try {
    const res = await getMyLabToken(userId);

    localStorage.setItem("enterprise_token", res.data[0].laboratory_id);
    localStorage.setItem("token", token);
    localStorage.setItem("user_id", window.btoa(userId));
  } catch (error) {
    console.error(error);
  }
}

export {
  saveSessionStorage
};