import React, { useState, Fragment } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Material components
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select as SelectUI,
  MenuItem as MenuItemUI,
} from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from '../../../../Components';

// Component styles
import styles from './style';

const CreateExpense = ({ classes, className, handleSave }) => {
  const [totalExpense, setTotalExpense] = useState(null);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [payment_method, setPaymentMethod] = useState('cash');
  const [disabled, setDisabled] = useState(false);

  const optionsOut = [
    {
      value: '#Alquiler',
      label: '#alquiler',
    },
    {
      value: '#Impuestos',
      label: '#impuestos',
    },
    {
      value: '#Contabilidad',
      label: '#contabilidad',
    },
    {
      value: '#Seguros',
      label: '#seguros',
    },
    {
      value: '#Pago Importadoras',
      label: '#pago_importadoras',
    },
    {
      value: '#Sueldos',
      label: '#sueldos',
    },
    {
      value: '#Comisiones',
      label: '#comisiones',
    },
    {
      value: '#Inversion',
      label: '#inversion',
    },
    {
      value: '#Expensas',
      label: '#expensas',
    },
    {
      value: '#Servicios',
      label: '#servicios',
    },
    {
      value: '#otros',
      label: '#otros',
    },
  ];

  const rootClassName = classNames(classes.root, className);

  const cleanInputs = () => {
    setTotalExpense(0);
    setDescription('');
    setCategory('');
  };

  const handleCreateSave = async () => {
    setDisabled(true);
    await handleSave(category.value, totalExpense, description, payment_method);
    setDisabled(false);
    cleanInputs();
  };

  const handleChange = name => event => {
    switch (name) {
      case 'description':
        setDescription(event.target.value);
        break;
      case 'totalExpense':
        setTotalExpense(event.target.value);
        break;
      default:
        console.error('unhandle change', name);
        break;
    }
  };

  const handlePaymentMethod = ev => {
    ev.preventDefault();
    const { value } = ev.target;
    setPaymentMethod(value);
  };

  const renderExpenses = () => {
    return (
      <Portlet className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            subtitle="Especifica el detalle del egreso"
            title="Registro de Gastos Generales"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form autoComplete="off" noValidate>
            <div className={classes.field}>
              <Autocomplete
                id="combo-box-doctor"
                options={optionsOut}
                value={category ? category : null}
                getOptionLabel={option => option.label}
                onChange={(event, value) => {
                  if (value && value) {
                    setCategory(value);
                  }
                }}
                getOptionSelected={(option, value) => {
                  return option.value === value.value;
                }}
                fullWidth
                noOptionsText={'por favor selecciona la categoria  del gasto'}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Selecciona Categoria"
                    variant="outlined"
                  />
                )}
              />
              <TextField
                id="outlined-number"
                label="Monto Total"
                value={totalExpense}
                onChange={handleChange('totalExpense')}
                type="number"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                inputProps={{
                  style: {
                    '-moz-appearance': 'textfield',
                  },
                }}
              />
            </div>
            <div className={classes.field}>
              <TextField
                id="outlined-notes"
                label="Descripcion"
                multiline
                rows="4"
                value={description}
                onChange={handleChange('description')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="payment_method">Metodo de Pago</InputLabel>
                <SelectUI
                  labelId="payment_method"
                  id="payment-select"
                  value={payment_method}
                  label="Payment Method"
                  name="payment_method"
                  onChange={handlePaymentMethod}
                >
                  <MenuItemUI key="cash" value="cash">
                    Efectivo
                  </MenuItemUI>
                  <MenuItemUI key="qr" value="qr">
                    QR
                  </MenuItemUI>
                  <MenuItemUI key="bank_transfer" value="bank_transfer">
                    Transferencia Bancaria
                  </MenuItemUI>
                </SelectUI>
              </FormControl>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            fullWidth
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={handleCreateSave}
          >
            Registrar Gasto
          </Button>
        </PortletFooter>
      </Portlet>
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.content}>{renderExpenses()}</div>
      </div>
    </Fragment>
  );
};

CreateExpense.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleSave: PropTypes.func,
};

export default withStyles(styles)(CreateExpense);
