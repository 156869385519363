import React from 'react';
import moment from 'moment';

import { Typography } from '@material-ui/core';

const Patient = ({ names, last_names, sex, birthdate, dni }) => {
  const birthdatex = moment(birthdate);
  const now = moment();

  const diffYears = now.diff(birthdatex, 'years');
  const diffMonths = now.diff(birthdatex, 'months');
  const diffDays = now.diff(birthdatex, 'days');

  let age;

  if (diffYears > 0) {
    age = `${diffYears} Años`;
  } else if (diffMonths > 0) {
    age = `${diffMonths} Meses`;
  } else {
    age = `${diffDays} Dias`;
  }

  return (
    <>
      <Typography variant="h5" gutterBottom display="inline" color="secondary">
        Paciente:
      </Typography>
      <Typography variant="h3" gutterBottom display="inline">
        {` ${names} ${last_names} `}
      </Typography>

      <Typography variant="h5" gutterBottom display="inline" color="secondary">
        Sexo:
      </Typography>
      <Typography variant="h3" gutterBottom display="inline">
        {` ${sex == 'male' ? 'Masculino' : 'Femenino'} `}
      </Typography>
      <Typography variant="h4" gutterBottom display="inline" color="secondary">
        Edad:
      </Typography>
      <Typography variant="h3" gutterBottom display="inline">
        {age}
      </Typography>
      <Typography variant="h4" gutterBottom display="inline" color="secondary">
        Documento de Identidad:
      </Typography>
      <Typography variant="h3" gutterBottom display="inline">
        {` ${dni} `}
      </Typography>
    </>
  );
};

export default Patient;
