import React, { PropTypes, Component, useState } from "react";
import { Link, useParams } from "react-router-dom";

const AddTest = ({ saveTest }) => {
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);

  const handleInputs = (e) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "price") {
      setPrice(value);
    }
  };

  let { category_id } = useParams();

  return (
    <div className="cell medium-12 grid-x grid-padding-x">
      <div className="cell medium-6">Nombre de la Prueba</div>
      <div className="cell medium-6">Precio</div>
      <div className="cell medium-6">
        <input
          type="text"
          placeholder="Nombre"
          name="name"
          onChange={handleInputs}
        />
      </div>
      <div className="cell medium-6">
        <input
          type="text"
          placeholder="Precio"
          name="price"
          onChange={handleInputs}
        />
      </div>

      <div className="cell medium-6">
        <a
          data-category_id={category_id}
          data-name={name}
          data-price={price}
          onClick={saveTest}
          className="cell medium-4 button float-left"
        >
          <i className="far fa-save fa-lg"></i>
          &nbsp; Guardar
        </a>
      </div>
      <div className="cell medium-6">
        <Link className="cell medium-4 button secondary" to="/">
          Cancelar
        </Link>
      </div>
    </div>
  );
};

export default AddTest;
