import React, { PropTypes } from 'react'

const RangeEdit = ({ init, end, refe_id, subtest_id, refe_index, handleInputChange}) => (
        <div className="cell medium-12 grid-x grid-padding-x">
            <span className="cell medium-3 text-right">
                Inicial:
            </span>
            <input type="number" className="cell medium-3" placeholder="Inicial" data-type="range" name="init"  id={'init_'+refe_id} data-subtest_id={subtest_id} data-refe_index={refe_index}  value={init} onChange={handleInputChange}  />
            <span className="cell medium-3 text-right">
                Final:
            </span>
            <input type="number" className="cell medium-3" placeholder="Final" data-type="range" name="end" id={'end_'+refe_id} data-subtest_id={subtest_id} data-refe_index={refe_index} value={end} onChange={handleInputChange} />
    </div>
)


export default RangeEdit
