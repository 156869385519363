import React, { useState } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';

const TypeTextPosNeg = React.memo(({ ...data }) => {
  const [value, setValue] = useState(data.value);
  const handleInput = ev => {
    ev.preventDefault();
    const newValue = ev.target.value;
    setValue(newValue);
    data.setTestData(data.test_id, data.id, newValue);
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
      item
      xs={12}
    >
      <Grid item xs={3}>
        <Typography align="right">{data.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <TextField
          id={data.test_id + '_text_post_neg'}
          type="text"
          value={value}
          onChange={handleInput}
          variant="outlined"
          autoComplete="on"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  );
});

export default TypeTextPosNeg;
