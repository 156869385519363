//generate react component with material ui search by patient name, last name, phone, dni, sex, birthday

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Typography,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import ListPatient from '../listPatient';
import {
  searchPatient,
  addPatient,
  updatePatient,
} from '../../../Services/UserService';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: '10px',
  },
  search: {
    width: '100%',
    marginTop: '10px',
  },
  button: {
    marginTop: '10px',
  },
}));

let timeoutToCancel = null;

const SearchPatient = ({ patient, setPatient, setOpen }) => {
  const classes = useStyles();
  const [patientList, setPatientList] = useState([]);
  const [editPatient, setEditPatient] = useState(null);

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    validate,
    setValue,
  } = useForm({
    defaultValues: {
      names: '',
      last_names: '',
      birthdate: '',
      phone: '',
      dni: '',
    },
  });

  const names = watch('names');
  const last_names = watch('last_names');
  const phone = watch('phone');
  const dni = watch('dni');

  useEffect(() => {
    if (editPatient == null) {
      if (timeoutToCancel) {
        clearTimeout(timeoutToCancel);
      }
      timeoutToCancel = setTimeout(async () => {
        try {
          const search_text = `${phone} ${dni}`;
          const res = await searchPatient(search_text);
          setPatientList(res.data.data);
        } catch (error) {}
      }, 400);
    }
  }, [names, last_names, phone, dni]);

  const onSubmit = async data => {
    if (editPatient == null) {
      const res = await addPatient(data);
      setPatient(res.data);
      reset();
    } else {
      const res = await updatePatient(editPatient.id, data);
      setPatient(res.data);
      reset();
    }
  };

  const handleCancel = ev => {
    ev.preventDefault();
    reset();
    setOpen(false);
  };

  useEffect(() => {
    if (editPatient) {
      setValue('names', editPatient.names);
      setValue('last_names', editPatient.last_names);
      if (editPatient._phones.length > 0 && editPatient._phones[0].number) {
        setValue('phone', editPatient._phones[0].number);
      }
      setValue('dni', editPatient.dni);
      setValue('gender', editPatient.sex);
      setValue('birthdate', editPatient.birthdate);
    }
  }, [editPatient]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            Buscar Paciente o Crear Nuevo
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errors.phone && <span>Es requerido el numero de Telefono</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="dni"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Documento de Identidad"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errors.dni && <span>Es requerido el documento de Identidad</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="names"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nombres"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              {errors.names && <span>Es requerido los nombres del paciente</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="last_names"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Apellidos"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errors.last_names && (
                <span>Es requerido los apellidos del paciente</span>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Mujer"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Varon"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gender && <span>Es requerido el Genero del paciente</span>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="birthdate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="input-with-icon-textfield"
                    label="Fecha de Nacimiento"
                    name="birthdate"
                    type="date"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
              {errors.birthdate && (
                <span>Es requerido la fecha nacimiento del paciente</span>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ListPatient
            patients={patientList}
            setEditPatient={setEditPatient}
            setPatient={setPatient}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          >
            {editPatient == null ? 'Crear Nuevo Paciente' : 'Actualizar Paciente'}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchPatient;
