import React, { useState } from 'react';
import './PatientData.css';
import { Grid, Typography, IconButton } from '@material-ui/core';

import { Edit } from '@material-ui/icons';
import { Patient, PatientEdit } from '../../shared/components';

const PatientData = ({ show_pencil, ...patientData }) => {
  const [edit_patient, setEditPatient] = useState(false);

  const handleEditPatient = () => {
    setEditPatient(!edit_patient);
  };

  return (
    <Grid item xs={12} container spacing={3}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography variant="h4">
            Datos del Paciente{' '}
            {show_pencil ? (
              <IconButton onClick={handleEditPatient} aria-label="delete">
                <Edit />
              </IconButton>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {edit_patient ? (
            <PatientEdit {...patientData} cancel={handleEditPatient} />
          ) : (
            <Patient {...patientData} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientData;
