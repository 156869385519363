import React, { useState } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';

const TypeRanges = React.memo(({ ...data }) => {
  const [value, setValue] = useState(data.value);
  const handleInput = ev => {
    ev.preventDefault();
    const newValue = ev.target.value;
    setValue(newValue);
    data.setTestData(data.test_id, data.id, newValue);
  };

  let refs_with_year = data.references.filter(ref => ref.years);
  let refs_without_year = data.references.filter(ref => !ref.years);

  let ref_html = null;
  let showed = false;

  if (refs_with_year.length > 0) {
    for (let ref of refs_with_year) {
      if (ref.sex === data.sex || ref.sex === 'unisex') {
        if (data.years_old >= ref.years[0] && data.years_old <= ref.years[1]) {
          let unity = '';

          if (data.unity) {
            unity = data.unity;
          }

          ref_html = (
            <div>
              <div>
                {ref.init != null
                  ? ref.init.text + ' ' + ref.init.value + ' ' + unity
                  : ''}
              </div>
              {ref.ranges.map((range, i) => {
                return (
                  <div key={i}>
                    {range.text +
                      ' ' +
                      range.values[0] +
                      ' - ' +
                      range.values[1] +
                      ' ' +
                      unity}{' '}
                  </div>
                );
              })}
              <div>
                {ref.end != null
                  ? ref.end.text + ' ' + ref.end.value + ' ' + unity
                  : ''}
              </div>
            </div>
          );
          showed = true;
        }
      }
    }
  }

  if (!showed) {
    for (let ref of refs_without_year) {
      if (ref.sex === data.sex || ref.sex === 'unisex') {
        let unity = '';

        if (data.unity) {
          unity = data.unity;
        }

        ref_html = (
          <div>
            <div>
              {ref.init != null
                ? ref.init.text + ' ' + ref.init.value + ' ' + unity
                : ''}
            </div>
            {ref.ranges.map((range, i) => {
              return (
                <div key={i}>
                  {range.text +
                    ' ' +
                    range.values[0] +
                    ' - ' +
                    range.values[1] +
                    ' ' +
                    unity}{' '}
                </div>
              );
            })}
            <div>
              {ref.end != null
                ? ref.end.text + ' ' + ref.end.value + ' ' + unity
                : ''}
            </div>
          </div>
        );
        showed = true;
      }
    }

    // console.log(ref.sex == data.sex  || ref.sex == 'unisex');
  }

  // console.log(data.value)

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={3}
      item
      xs={12}
    >
      <Grid item xs={3}>
        <Typography align="right">{data.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <TextField
          id={data.test_id + '_ranges'}
          type="text"
          value={value}
          onChange={handleInput}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        {ref_html}
      </Grid>
    </Grid>
  );
});

export default TypeRanges;
