import { connect } from "react-redux";
import Login from "./index";
import { LoginAction } from "../../Actions/user";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginClick: (username, password) => {
      dispatch(LoginAction(username, password));
    },
  };
};

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
