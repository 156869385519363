import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';

class TypeTextAdd extends PureComponent {
  render() {
    const { ...data } = this.props;

    let vals = '';
    if (data.values) {
      vals = data.values.map(x => (
        <Grid key={x} className="large-12">
          {x}
        </Grid>
      ));
    }

    return (
      <Grid container xs={12} item className="boder_bottom_text">
        <Grid item xs={4}>
          {data.name}
        </Grid>
        <Grid item xs={3}>
          {vals}
        </Grid>
        <Grid item xs={5}>
          {''}
        </Grid>
      </Grid>
    );
  }
}

export default TypeTextAdd;
