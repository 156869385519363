import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Lab from "./Reducers";
import AppContainer from "./container/AppContainer";
import { ValidateToken } from "./Services/UserService";

import registerServiceWorker from "./registerServiceWorker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);

// const store = createStore(Lab, applyMiddleware(thunk))
const store = createStore(Lab, enhancer);

const token = localStorage.getItem("token") || null;
const enterprise_token = localStorage.getItem("enterprise_token") || null;

if (token && enterprise_token) {
  store.dispatch({
    type: "AUTHENTICATED_USER",
  });

  ValidateToken()
    .then((res) => {
      console.log("token valid");
    })
    .catch((err) => {
      store.dispatch({
        type: "UNAUTHENTICATED",
      });

      localStorage.clear();
    });
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AppContainer />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
registerServiceWorker();
