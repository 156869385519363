import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import Test from '../../Components/TestReferencesView/Test';
import PatientData from '../fillRequestTestLegacy/PatientData';
import SelectPhysician from '../../shared/components/Physician';
import styles from './style';
import { Divider, Grid, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);

const TestRequest = ({
  getRequestTest,
  request_test,
  lab,
  tests_references,
  categories,
  handleInputChange,
  handleInputSave,
  handleSaveGeneratePdfButton,
  general_obs,
}) => {
  const classes = useStyles();

  let { test_request_id } = useParams();

  useEffect(() => {
    getRequestTest(test_request_id);
  }, [test_request_id]);

  let patient_data = null;
  let doctor_data = null;
  let sex = null;
  let years_old = null;

  if (request_test.request_test) {
    if (typeof request_test.request_test.patient !== 'undefined') {
      let patient_x = request_test.request_test.patient;

      patient_data = <PatientData show_pencil={false} {...patient_x} />;
    }

    sex = request_test.request_test.sex;
    years_old = request_test.request_test.years_old;
  }

  if (request_test.request_test) {
    if (typeof request_test.request_test.doctor !== 'undefined') {
      let doctor_x = request_test.request_test.doctor;

      doctor_data = <SelectPhysician {...doctor_x} />;
    }
  }

  let html_obs = null;

  if (general_obs) {
    html_obs = (
      <div className="grid-x grid-padding-x">
        <div className="cell small-3">Observaciones Generales:</div>
        <div className="cell small-9">{general_obs}</div>
      </div>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {patient_data}
        {doctor_data}
      </Grid>
      <Divider />

      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Typography className={classes.headerTitle} variant="h4">
            Pruebas del Paciente
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {tests_references.map((tests_reference, index) => {
            if (tests_reference) {
              return (
                <Grid
                  item
                  xs={12}
                  container
                  key={tests_reference.test_id}
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.title} variant="h4">
                      {tests_reference.name}
                    </Typography>
                  </Grid>

                  <Test
                    {...tests_reference}
                    sex={sex}
                    years_old={years_old}
                    onInputChange={handleInputChange}
                    handleInputSave={handleInputSave}
                  />
                </Grid>
              );
            } else {
              return (
                <div className="cell medium-12 boder_bottom" Key={index + 'x'}>
                  <div> falta datos de referencia para una prueba</div>
                </div>
              );
            }
          })}
        </Grid>

        <Grid item xs={12}>
          {html_obs}
        </Grid>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={4}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<i className="far fa-file-pdf fa-lg"></i>}
              onClick={() =>
                handleSaveGeneratePdfButton(
                  request_test,
                  tests_references,
                  categories,
                  general_obs,
                  lab,
                  'pdf',
                )
              }
            >
              Descargar PDF
            </Button>
          </Grid>
          <Grid item xs={4}>
            {' '}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<i className="far fa-file-pdf fa-lg"></i>}
              onClick={() =>
                handleSaveGeneratePdfButton(
                  request_test,
                  tests_references,
                  categories,
                  general_obs,
                  lab,
                  'both',
                )
              }
            >
              Descargar PDF logo
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TestRequest;
