import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import {
  FormControlLabel,
  Checkbox,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const Test = ({ onClick, id, price, name, modified_at }) => {
  const handleChange = ev => {
    ev.preventDefault();
    onClick(id);
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={8}>
        <div className="padding_test">
          <input type="checkbox" onChange={handleChange} checked={modified_at} />
          <a onClick={handleChange} className="test_name">
            {name}
          </a>
        </div>
      </Grid>
      <Grid item xs={2}>
        <Typography className="price"> {price}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Link to={`/reference/${id}`}>
          <IconButton size="medium" variant="contained" color="primary">
            <EditIcon />
          </IconButton>
        </Link>
      </Grid>
    </Grid>
  );
};

export default memo(Test);
