import React from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Badge,
  Typography,
  IconButton,
  Link,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';

import classNames from 'classnames';

import styles from './style';

const BillsTable = ({
  classes,
  className,
  outgoings,
  showActions,
  handleDeleteOutgoing,
  openModal,
}) => {
  const rootClassName = classNames(classes.root, className);

  const EditPaymentMethod = ev => {
    const { id, method } = ev.target.dataset;
    openModal(id, method);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Categoria</TableCell>
          <TableCell align="left">Descripcion</TableCell>
          <TableCell align="left">Monto</TableCell>
          <TableCell align="left">Metodo de Pago</TableCell>
          {showActions ? <TableCell align="left">Acciones</TableCell> : ''}
          <TableCell align="left">Fecha y Hora</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {outgoings.map(outgoing => (
          <TableRow
            className={
              outgoing.deleted && outgoing.deleted === true
                ? classes.tableRowDeleted
                : ''
            }
            key={outgoing.id}
          >
            <TableCell className={classes.tableCell}>{outgoing.category}</TableCell>

            <TableCell className={classes.tableCell}>
              {outgoing.deleted && outgoing.deleted == true ? (
                <Typography className={classes.Line} variant="body1">
                  {outgoing.description}{' '}
                </Typography>
              ) : (
                outgoing.description
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {outgoing.total_amount + ' Bs'}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {outgoing.payment_method == 'cash'
                ? 'Efectivo'
                : outgoing.payment_method}

              <Link
                component="button"
                variant="body2"
                data-id={outgoing.id}
                data-method={outgoing.payment_method}
                onClick={EditPaymentMethod}
              >
                &nbsp; Editar metodo de pago
              </Link>
            </TableCell>

            {showActions ? (
              <TableCell className={classes.tableCell}>
                {!outgoing.deleted ? (
                  <IconButton
                    onClick={handleDeleteOutgoing(outgoing.id)}
                    aria-label="delete"
                    color="primary"
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </TableCell>
            ) : (
              ''
            )}

            <TableCell className={classes.tableCell}>
              {moment(outgoing.created_at).calendar(null, {
                sameElse: 'MMMM Do YYYY, h:mm:ss a',
              })}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(BillsTable);
