import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import { SearchInput } from '../../../../Components';

// Component styles
import styles from './styles';

const Search = ({ classes, className, handleSearchByName }) => {
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Buscar Paciente ..."
          onChange={handleSearchByName}
        />
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleSearchByName: PropTypes.func,
};

export default withStyles(styles)(Search);
