import { connect } from 'react-redux';
import { CleanProcessingTests } from '../../Actions';
import {
  DeleteRequestAction,
  LoadProcessingRequestLegacy,
} from '../../Actions/request_test';
import Processing from './processing';

const mapStateToProps = state => {
  return {
    tests_processing: state.request_tests.tests_processing,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadProcessingTests: () => {
      dispatch(CleanProcessingTests());
      dispatch(LoadProcessingRequestLegacy());
    },
    deleteRequest: ev => {
      ev.preventDefault();
      const { request_id } = ev.target.dataset;

      dispatch(DeleteRequestAction(request_id));
      dispatch(CleanProcessingTests());
      dispatch(LoadProcessingRequestLegacy());
    },
  };
};

const ProcessingContainerLegacy = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Processing);

export default ProcessingContainerLegacy;
