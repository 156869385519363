import { connect } from 'react-redux';
import TestRequest from '.';
import {
  AddRequestTestAction,
  AddRequestTestErrorAction,
  AddTestReferenceAction,
  AddCategoryAction,
  CleanTestReferenceAction,
  AddPatientRequestAction,
  AddDoctorRequestAction,
  AddTestReferencesAction,
  AddGeneralObsAction,
} from '../../Actions';
import {
  GetRequestTestService,
  GetTestReference,
  GeneratePdf,
  GeneratePdfLogo,
  GetCategories,
  GetTestsValues,
} from '../../Services/RequestTestService';
import { GetPatient, GetDoctor, getPerson } from '../../Services/PatientService';
import { AppToaster } from '../../Components/Toaster';
import { Intent } from '@blueprintjs/core';

const mapStateToProps = state => {
  return {
    request_test: state.request_tests.request_test,
    tests_references: state.request_tests.test_list.tests_references,
    categories: state.request_tests.categories,
    general_obs: state.request_tests.general_obs,
    lab: state.profile_lab.lab,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRequestTest: id => {
      GetCategories().then(res_categories => {
        dispatch(AddCategoryAction(res_categories.data));
      });

      GetTestsValues(id).then(res => {
        let data = res.data.data;
        if (data[0]) {
          dispatch(CleanTestReferenceAction());
          GetRequestTestService(id).then(res => {
            dispatch(AddRequestTestAction(res.data));
            getPerson(res.data.patient_id).then(res => {
              dispatch(AddPatientRequestAction(res.data));
            });

            if (res.data.doctor_id !== 'A Quien corresponda') {
              GetDoctor(data.doctor_id).then(res => {
                dispatch(AddDoctorRequestAction(res.data.user));
              });
            }
          });

          //Here add results values

          dispatch(AddTestReferencesAction(data[0].test_list_values));
          dispatch(AddGeneralObsAction(data[0].general_obs));
        } else {
          // when there is not data saved
          GetRequestTestService(id)
            .then(res => {
              dispatch(AddRequestTestAction(res.data));
              dispatch(CleanTestReferenceAction());

              res.data.tests.map(t => {
                GetTestReference(t).then(res => {
                  dispatch(AddTestReferenceAction(res.data[0]));
                });
                return 0;
              });

              getPerson(res.data.patient_id).then(res => {
                dispatch(AddPatientRequestAction(res.data));
              });

              if (res.data.doctor_id !== 'A Quien corresponda') {
                GetDoctor(res.data.doctor_id).then(res => {
                  dispatch(AddDoctorRequestAction(res.data.user));
                });
              }
            })
            .catch(err => {
              dispatch(AddRequestTestErrorAction(err));
            });
        }
      });
    },
    handleSaveGeneratePdfButton: (
      request_test,
      test_value_references,
      categories,
      general_obs,
      lab,
      print,
    ) => {
      GetTestsValues(request_test.request_test.id)
        .then(res => {
          let data = {
            request_test: request_test.request_test,
            test_values_by_category: res.data.data[0].test_values_by_categories,
            created_at: res.data.data[0].created_at,
            categories,
            general_obs,
            logo: lab.picture,
            signature: lab.signature,
            lab: lab,
            short_url: '',
          };

          if (request_test.request_test && request_test.request_test.shortid) {
            data['short_url'] =
              'https://www.laztin.com/verify/' + request_test.request_test.shortid;
          }

          AppToaster.show({
            message: 'Se esta descargando el pdf por favor espere unos segundos ...',
            timeout: 3000,
            intent: Intent.PRIMARY,
          });

          if (print === 'pdf_logo') {
            GeneratePdfLogo(data);
          } else if (print === 'pdf') {
            GeneratePdf(data);
          } else if (print === 'both') {
            GeneratePdfLogo(data);
            GeneratePdf(data);
          }
        })
        .catch(err => console.log(err));
    },
  };
};

const TestRequestContainerLegacy = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestRequest);

export default TestRequestContainerLegacy;
