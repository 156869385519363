import React, { useState, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, IconButton, Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const Test = ({ onClick, id, price, name, modified_at }) => {
  const [checked, setChecked] = useState(modified_at || false);
  const [isHovered, setIsHovered] = useState(false);
  const handleChange = checkedx => {
    onClick(id, checkedx);
  };

  const handleCheck = ev => {
    ev.preventDefault();
    setChecked(!checked);
    handleChange(!checked);
  };

  const handleLink = () => {
    setChecked(!checked);
    handleChange(!checked);
  };

  useEffect(() => {
    setChecked(modified_at);
  }, [modified_at]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      spacing={1}
    >
      <Grid item xs={1}>
        <Checkbox onChange={handleCheck} checked={checked} />
      </Grid>
      <Grid item xs={7}>
        <div className="padding_test">
          <a onClick={handleLink} className="test_name">
            {name}
          </a>
        </div>
      </Grid>
      <Grid item xs={2}>
        <Typography className="price"> {price} Bs</Typography>
      </Grid>
      <Grid item xs={2}>
        {isHovered && (
          <Link to={`/reference/${id}`}>
            <IconButton size="medium" variant="contained" color="primary">
              <EditIcon />
            </IconButton>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(Test);
