import React from 'react'

const Generic = ({generic, refe_id, subtest_id, refe_index, handleInputChange}) => (
    <div className="cell medium-12 grid-x grid-padding-x">
        <span className="cell medium-3 text-right">
            Referencia:
        </span>
        <input type="text" className="cell medium-9" placeholder="Generico"
            data-type="generic"
            name="generic" id={'generic_'+refe_id}
            data-subtest_id={subtest_id}
            data-refe_index={refe_index}
            value={generic}
            onChange={handleInputChange}  />
    </div>
)

export default Generic
