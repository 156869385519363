import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import SessionContext from './shared/contexts/session';

const PrivateRoute = ({ children, lab, ...rest }) => {
  const { isAuthenticated } = useContext(SessionContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/newHome',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  ...state,
});

export default connect(mapStateToProps)(PrivateRoute);
