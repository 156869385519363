import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';

import './ListPatientResultLegacy.css';

const buildTableData = results => {
  const res = results.map(test => {
    let fullName = '';
    if (test.patient) {
      fullName = test.patient.full_name + ' ' + test.patient.last_names;
    }

    return {
      id: test.id,
      fullName,
      tests: test.test_list_values.length,
      createdAt: test.created_at,
      requestId: test.request_id,
    };
  });

  return res;
};

const ListPatientResultLegacy = ({
  loadCompleteTests,
  tests_results,
  handleEditButton,
  handleSeeResults,
  goNextPage,
  searchPatient,
}) => {
  useEffect(() => {
    loadCompleteTests();
  }, []);

  const handleSearch = search => {
    searchPatient(search);
  };

  return (
    <MaterialTable
      title="Pacientes"
      columns={[
        { title: 'Paciente Nombre Completo', field: 'fullName' },
        { title: 'Pruebas', field: 'tests' },
        {
          title: 'Fecha de Prueba',
          field: 'createdAt',
          type: 'datetime',
          dateSetting: { locale: 'es-BO' },
        },
      ]}
      data={buildTableData(tests_results.results)}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Editar Resuldatos',
          onClick: (event, rowData) => {
            handleEditButton(rowData.requestId);
          },
        },
        {
          icon: 'visibility',
          tooltip: 'Ver Resuldatos',
          onClick: (event, rowData) => {
            handleSeeResults(rowData.requestId);
          },
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        pageSize: 20,
        search: true,
      }}
      onSearchChange={handleSearch}
    />
  );
};

export default ListPatientResultLegacy;
